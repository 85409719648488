<template>
  <div class="product-single product-function" style="background: #F0F3F9;">
    <div class="main-wrap">
      <div class="pr">
        <div class="title_center">业主APP</div>
        <div class="title_intro">业主端享受移动社区生活</div>
      </div>
      <div class="con-box flex-row">
        <div class="tab-cont left-content">
          <span>
            <ul
              :class="liLen <= 5 ? 'ul-listCon' : ''"
              class="ul-list clearfloat"
            >
              <li
                v-for="(productFun, index) in functionPro"
                :key="index"
                style="visibility: visible;width:240px !important;"
              >
                <a href="javascript:;">
                  <div class="marking_img">
                    <img
                      :src="productFun.src"
                      style="width:88px;height:88px;"
                    />
                  </div>
                  <dl class="dl_marking">
                    <dd>{{ productFun.tit }}</dd>
                    <dt>
                      {{ productFun.con }}
                      <p>{{ productFun.con1 }}</p>
                    </dt>
                  </dl>
                </a>
              </li>
              <div class="clear"></div>
            </ul>
          </span>
        </div>
        <div class="right-content">
          <img
            src="../../assets/img/proAsset/proAsset_icon41.png"
            class="img-con"
            style="margin-bottom: 50px;width: 280px;height: 415px;"
          />
          <router-link :to="{ path: '/pc/contact' }">
            <div class="trial-btn">免费试用</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "OwnerApp",
  props: {
    functionPro: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      liLen: 0
    };
  },
  computed: {},
  mounted() {
    this.liLen = this.functionPro.length;
  },
  methods: {}
};
</script>

<style lang="scss">
.title_center {
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #00041b;
  line-height: 50px;
}
.title_intro {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #585c6a;
  line-height: 20px;
  margin-top: 10px;
}
.flex-row {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  .right-content {
    width: 360px;
    margin-left: 58px;
    .img-con {
      width: 100%;
      height: 410px;
      margin-bottom: 68px;
    }
    .trial-btn {
      width: 140px;
      height: 44px;
      background: linear-gradient(144deg, #8ca4f8 0%, #4c71f6 100%);
      box-shadow: 0px 1px 6px 0px rgba(0, 25, 168, 0.4);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      margin: 0 auto;
      cursor: pointer;
    }
  }
  .left-content {
    width: calc(100% - 418px);
    .ul-list li {
      border-right: 1px solid #dee5ea;
    }
    .ul-list li:nth-child(3n) {
      border-right: none;
    }
    .ul-list li:nth-child(1),
    .ul-list li:nth-child(2),
    .ul-list li:nth-child(3) {
      border-bottom: 1px solid #dee5ea;
    }
  }
}
</style>
