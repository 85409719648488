import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import "./assets/css/index.scss";
import "./assets/css/products.scss";
import "./assets/css/news.scss";
import "./assets/css/reset.css";
import "./assets/css/base.scss";
import "./assets/css/contact.scss";
import "./utils/fnResize";
import MetaInfo from "vue-meta-info";
//import VueLazyLoad from "vue-lazyload";
import "animate.css";

Vue.use(MetaInfo);
Vue.config.productionTip = false;
// Vue.use(VueLazyLoad, {
//   error: require("./assets/img/section_cooperation_picN.png"), //加载失败的图
//   loading: require("./assets/img/section_cooperation_picN.png") //加载中的默认图
// });
(function() {
  var lastTime = 0;
  var vendors = ["ms", "moz", "webkit", "o"];
  for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[vendors[x] + "RequestAnimationFrame"];
    window.cancelAnimationFrame =
      window[vendors[x] + "CancelAnimationFrame"] ||
      window[vendors[x] + "CancelRequestAnimationFrame"];
  }

  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = function(callback) {
      var currTime = new Date().getTime();
      var timeToCall = Math.max(0, 16 - (currTime - lastTime));
      var id = window.setTimeout(function() {
        callback(currTime + timeToCall);
      }, timeToCall);
      lastTime = currTime + timeToCall;
      return id;
    };
  }

  if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = function(id) {
      clearTimeout(id);
    };
  }
})();

router.afterEach(() => {
  setTimeout(() => {
    (function(b, a, e, h, f, c, g, s) {
      b[h] =
        b[h] ||
        function() {
          (b[h].c = b[h].c || []).push(arguments);
        };
      b[h].s = !!c;
      g = a.getElementsByTagName(e)[0];
      s = a.createElement(e);
      s.src = "//s.union.360.cn/" + f + ".js";
      s.defer = !0;
      s.async = !0;
      g.parentNode.insertBefore(s, g);
    })(window, document, "script", "_qha", 535566, false);
  }, 0);
});

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  }
}).$mount("#app");
