<template>
  <div :class="topDistance > 50 ? 's-mask' : ''" class="m-header-bar">
    <div
      :class="showTag == true ? 'open' : ''"
      class="main-wrap main-cont pr flex"
    >
      <a href="#" class="logo">
        <router-link :to="{ path: '/wap' }">
          <img src="../assets/img/logo.png" class="abs-lm" />
        </router-link>
      </a>
      <span
        @click="showNav()"
        :class="showTag == true ? 'close-btn' : 'menu-btn'"
        class="menu-btn"
      ></span>
    </div>
    <div :class="showTag == true ? 'open' : ''" class="menuList-wrap">
      <my-tree
        :data="theData"
        :name="menuName"
        @getClickData="getClickDataFunc"
      ></my-tree>
      <router-link :to="{ path: '/wap/contact' }">
        <div class="btn-wrap">免费试用</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import myTree from "@/components/treeMenu"; // menu
const myData = [
  {
    id: "1",
    menuName: "产品与服务",
    menuCode: "10",
    children: [
      {
        menuName: "企业数字化",
        menuCode: "11",
        menuGroup: false,
        children: [
          {
            menuName: "统一门户",
            menuCode: "112",
            menuPath: "/wap/proUnifiedPortal"
          },
          {
            menuName: "企业官网",
            menuCode: "113",
            menuPath: "/wap/proWebsite"
          },
          {
            menuName: "流程中心",
            menuCode: "114",
            menuPath: "/wap/proProcessCenter"
          },
          {
            menuName: "EHR",
            menuCode: "115",
            menuPath: "/wap/proEhr"
          },
          {
            menuName: "钉钉集成平台",
            menuCode: "116",
            menuPath: "/wap/proPlatform"
          },
          {
            menuName: "业财一体化",
            menuCode: "117",
            menuPath: "/wap/proIntegrated"
          }
        ]
      },
      {
        id: "12",
        menuName: "智慧商业",
        menuCode: "12",
        menuGroup: false,
        children: [
          {
            menuName: "悦商圈",
            menuCode: "121",
            menuPath: "/wap/proBusinessDistrict"
          },
          // {
          //   menuName: "悦中心",
          //   menuCode: "122",
          //   menuPath: "/wap/proCentrerYue"
          // },
          {
            menuName: "悦商惠",
            menuCode: "123",
            menuPath: "/wap/proCommercialBenefits"
          },
          {
            menuName: "悦荷包",
            menuCode: "124",
            menuPath: "/wap/proPouch"
          },
          {
            menuName: "悦云商业资管平台",
            menuCode: "125",
            menuPath: "/wap/proBusPlatform"
          }
        ]
      },
      {
        menuName: "智慧物业",
        menuCode: "13",
        menuGroup: false,
        children: [
          {
            menuName: "悦邻家",
            menuCode: "131",
            menuPath: "/wap/proNeighbor"
          },
          {
            menuName: "悦云管",
            menuCode: "132",
            menuPath: "/wap/proAdminYue"
          },
          {
            menuName: "悦易付",
            menuCode: "133",
            menuPath: "/wap/proEasyPay"
          },
          {
            menuName: "悦云物业资管平台",
            menuCode: "134",
            menuPath: "/wap/proPropertyPlatform"
          }
        ]
      }
    ]
  },
  {
    id: "2",
    menuName: "解决方案",
    menuCode: "20",
    menuGroup: false,
    children: [
      {
        menuName: "企业数字化",
        menuCode: "21",
        menuPath: "/wap/solutions"
      },
      {
        menuName: "智慧商业",
        menuCode: "22",
        menuPath: "/wap/solutionsBusiness"
      },
      {
        menuName: "智慧物业",
        menuCode: "23",
        menuPath: "/wap/solutionsProperty"
      }
    ]
  },
  {
    id: "3",
    menuName: "合作案例",
    menuCode: "30"
  },
  {
    id: "4",
    menuName: "关于悦云",
    menuCode: "40",
    menuGroup: false,
    children: [
      {
        menuName: "关于我们",
        menuCode: "41",
        menuPath: "/wap/about"
      },
      {
        menuName: "悦云动态",
        menuCode: "42",
        menuPath: "/wap/news"
      },
      {
        menuName: "联系我们",
        menuCode: "43",
        menuPath: "/wap/contact"
      }
    ]
  }
];

export default {
  components: {
    myTree
  },
  name: "HeaderCon",
  props: {},
  data() {
    return {
      theData: myData,
      menuName: "menuName", // 显示菜单名称的属性
      scope: {},
      topDistance: 0,
      showTag: false,
      showTagFirst: false,
      showTagSolu: false,
      showTagAbout: false
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    getClickDataFunc(data) {
      this.$emit("getClickData", data);
      this.showTag = !this.showTag;
    },
    // 导航内容显示&隐藏
    showNav() {
      this.showTag = !this.showTag;
    },
    handleScroll() {
      // 实现当滚动到指定位置，导航加背景
      this.topDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    }
  }
};
</script>
<style scoped lang="scss">
.menuList-wrap {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transform: translateX(-300px);
  opacity: 1;
  position: absolute;
  width: 80%;
  right: 0;
  background: #1f1f32;
  font-size: 0.24rem;
  color: rgba(0, 0, 0, 0.85);
  z-index: 100;
  height: calc(100vh - 1rem);
  overflow: scroll;
  display: none;
  .btn-wrap {
    font-size: 0.3rem;
    color: #fff;
    width: 2.2rem;
    height: 0.88rem;
    line-height: 0.88rem;
    box-shadow: 0px 1px 6px 0px rgba(0, 25, 168, 0.4);
    border-radius: 4px;
    border: 1px solid #ffffff;
    margin: 0.79rem 0 0 0.6rem;
  }
}
</style>
