import request from "@/utils/request";

export function apply(params) {
  return request({
    url: "/apply",
    method: "post",
    params
  });
}

export function registerSave(data) {
  return request({
    url: "/register/save",
    method: "post",
    data
  });
}

// 校验手机号是否存在
export function checkMobile(data) {
  return request({
    url: "/register/checkMobile",
    method: "post",
    data
  });
}

// 校验公司是否存在
export function checkCompany(data) {
  return request({
    url: "/register/checkCompany",
    method: "post",
    data
  });
}

// 获取验证码
export function registerVcode(data) {
  return request({
    url: "/register/vcode",
    method: "post",
    data
  });
}
