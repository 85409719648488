<template>
  <div class="con-wrapper">
    <div class="pc">
      <div class="contact s-page" style="height: 937px;">
        <div class="main-cont abs-mm clearfloat" style="">
          <div class="left-box">
            <!-- <a>
              <img src="../../../assets/img/index_common_slogan.png" class="logo"/>
            </a> -->
            <router-link :to="{ path: '/' }">
              <img
                src="../../../assets/img/contact_logo-new.png"
                class="slogan"
              />
            </router-link>
            <div class="desc">
              <p>
                <img
                  src="../../../assets/img/funIcon/contact_icon1.png"
                  alt=""
                />
                <span>合同管理</span>
              </p>
              <p>
                <img
                  src="../../../assets/img/funIcon/contact_icon2.png"
                  alt=""
                />
                <span>租金收缴</span>
              </p>
              <p>
                <img
                  src="../../../assets/img/funIcon/contact_icon3.png"
                  alt=""
                />
                <span>会员体系</span>
              </p>
              <p>
                <img
                  src="../../../assets/img/funIcon/contact_icon4.png"
                  alt=""
                />
                <span>营销体系</span>
              </p>
              <p>
                <img
                  src="../../../assets/img/funIcon/contact_icon5.png"
                  alt=""
                />
                <span>智慧物联</span>
              </p>
              <p>
                <img
                  src="../../../assets/img/funIcon/contact_icon6.png"
                  alt=""
                />
                <span>移动APP</span>
              </p>
            </div>
            <div class="info">
              <p>
                <img src="../../../assets/img/foo_tel.png" />
                <span>客服热线：4000-022-812</span>
              </p>
              <!-- <p>
                <img src="../../../assets/img/foo_mobile.png" />
                <span>0731-8850 8356</span>
              </p> -->
              <p>
                <img src="../../../assets/img/foo_add1.png" />
                <span>湖南省长沙市望城区普瑞西路金桥国际未来城3栋3楼</span>
              </p>
            </div>
          </div>
          <div class="right-box">
            <div class="right-box-title">
              <div class="tips2">
                <span>已有账号，</span>
                <a :href="saasUrl" class="tips2-item" target="_blank">
                  登录
                </a>
              </div>
              <div class="tips">欢迎，悦云体验官</div>
            </div>
            <form :model="contactData">
              <ul class="form-list">
                <li class="required">
                  <input
                    :class="orgNameMsg != '' ? 'err' : ''"
                    type="text"
                    v-model.trim="contactData.companyName"
                    initial="off"
                    placeholder="企业名称"
                    maxlength="50"
                    @change="check(0)"
                    @blur.capture="check(0)"
                  />
                  <input
                    v-model="orgNameMsg"
                    class="err-msg"
                    readonly="readonly"
                  />
                </li>
                <li class="required">
                  <input
                    :class="phoneMsg != '' ? 'err' : ''"
                    type="text"
                    v-model.trim="contactData.mobile"
                    placeholder="手机号码"
                    maxlength="11"
                    @change="check(1)"
                    @blur.capture="check(1)"
                  />
                  <input
                    v-model="phoneMsg"
                    class="err-msg"
                    readonly="readonly"
                  />
                </li>
                <li class="required">
                  <input
                    :class="vcodeMsg != '' ? 'err' : ''"
                    type="text"
                    v-model.trim="contactData.vcode"
                    placeholder="验证码"
                    maxlength="6"
                    @change="check(4)"
                    @blur.capture="check(4)"
                  />
                  <button
                    :loading="sendLoading1"
                    type="button"
                    class="verificationCode"
                    @click="doSend1()"
                  >
                    {{ sendText1 }}
                  </button>
                  <input
                    v-model="vcodeMsg"
                    class="err-msg"
                    readonly="readonly"
                  />
                </li>
                <li class="required">
                  <input
                    :class="nameMsg != '' ? 'err' : ''"
                    type="text"
                    v-model.trim="contactData.contactName"
                    placeholder="联系人"
                    maxlength="20"
                    @change="check(2)"
                    @blur.capture="check(2)"
                  />
                  <input
                    v-model="nameMsg"
                    class="err-msg"
                    readonly="readonly"
                  />
                </li>
                <li class="required">
                  <select
                    :class="intentionMsg != '' ? 'err' : ''"
                    v-model.trim="contactData.intention"
                    @change="check(3)"
                    @blur.capture="check(3)"
                  >
                    <option
                      value=""
                      disabled
                      selected
                      style="display:none;color:#eee;"
                    >
                      您需要的意向
                    </option>
                    <option
                      v-for="item in statusOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </select>
                  <input
                    v-model="intentionMsg"
                    class="err-msg"
                    readonly="readonly"
                  />
                </li>
                <li>
                  <textarea
                    rows="4"
                    type="text"
                    v-model.trim="contactData.remark"
                    placeholder="备注"
                    maxlength="100"
                  ></textarea>
                </li>
              </ul>
            </form>

            <button
              class="btn-submit ta-c"
              :disabled="isDisable"
              @click="submit()"
            >
              提交试用申请
            </button>
          </div>
        </div>
        <div
          class="alsrtInfo"
          :style="{ display: displayStsates }"
          ref="alertMsg"
        >
          <div class="profPrompt_test">
            <img src="../../../assets/img/success_icon.png" />
            <h3>提交成功</h3>
            <p>我是小悦，您的专属顾问将及时联系您，请保持电话畅通！</p>
            <a :href="saasUrl" class="tips2-item" target="_blank">
              <span class="login">登录系统</span>
            </a>
            <router-link :to="{ path: '/' }"><span>返回首页</span></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
// import { apply } from "@/api/api";
import { registerSave, checkMobile, registerVcode } from "@/api/api";

export default {
  name: "index",
  components: {},
  metaInfo: getMetaInfo(),
  data() {
    return {
      isSend1: false,
      sendLoading1: false,
      sendTime1: 60,
      sendText1: "获取动态码",
      aletMsg: "", // 弹出框中的提示语
      displayStsates: "none",
      contactData: {
        companyName: "",
        contactName: "",
        intention: "",
        mobile: "",
        vcode: "",
        remark: ""
      },
      statusOptions: [
        { id: "0", name: "物业收费管理、账单管理" },
        { id: "1", name: "物业品质精细化运营，如保洁、绿化、秩序等" },
        { id: "2", name: "智慧社区、智慧园区、智慧门禁、停车等" },
        { id: "3", name: "商业会员管理" },
        { id: "4", name: "商业资产管理" }
      ],
      orgNameMsg: "",
      phoneMsg: "",
      vcodeMsg: "",
      nameMsg: "",
      intentionMsg: "",
      saasUrl: "",
      formFlag: false,
      isDisable: false,
      errFlag: false
    };
  },
  mounted() {},
  watch: {
    companyName: function() {
      this.check(0);
    },
    mobile: function() {
      this.check(1);
    },
    contactName: function() {
      this.check(2);
    },
    intention: function() {
      this.check(3);
    },
    vcode: function() {
      this.check(4);
    }
  },
  created() {
    if (process.env.BASE_API === "https://manager-open-hw.oojoyoo.com") {
      this.saasUrl = "https://asm.oojoyoo.com/#/login";
    } else if (process.env.BASE_API === "https://joycloud-rd.top/") {
      this.saasUrl = "https://asm.oojoyoo.com/#/login";
    } else if (process.env.BASE_API === "https://sgy-lms-pre.oojoyoo.com") {
      this.saasUrl = "https://asm.oojoyoo.com/#/login";
    }
  },
  methods: {
    // 登录发送验证码
    doSend1() {
      this.isSend1 = true;
      var reg = /^[1][1-9][0-9]{9}$/;
      if (this.contactData.mobile && reg.test(this.contactData.mobile)) {
        this.sendLoading1 = true;

        this.sendText1 = "重新获取 " + this.sendTime1 + "S";
        var clock = window.setInterval(() => {
          this.sendTime1--;
          this.sendText1 = "重新获取 " + this.sendTime1 + "S";
          if (this.sendTime1 < 0) {
            window.clearInterval(clock);
            this.sendText1 = "获取动态码";
            this.sendTime1 = 60;
            this.sendLoading1 = false;
          }
        }, 1000);

        registerVcode({ mobile: this.contactData.mobile }).then(res => {
          if (res.data.code === "0") {
            alert("发送成功！");
          }
        });
      } else {
        alert("请输入正确的手机号码");
        return false;
      }
    },
    // 提交
    submit() {
      // if (!this.isSend1) {
      //   this.tipsMsg = "请获取动态码，再进行操作";
      //   return false;
      // }
      if (this.contactData.vcode.length !== 6) {
        alert("请输入六位数的动态码");
        return false;
      }
      if (
        this.check(0) &&
        this.check(1) &&
        this.check(2) &&
        this.check(3) &&
        this.check(4)
      ) {
        this.isDisable = true;
        var data = this.contactData;
        registerSave(data)
          .then(res => {
            setTimeout(() => {
              this.isDisable = false;
            }, 3000);
            res = res.data;
            if (res.code === "0") {
              this.alertDia();
              this.contactData = {};
              this.isDisable = false;
            }
          })
          .catch(() => {
            this.isDisable = false;
          });
      }
    },
    check(i) {
      var flag = true;
      switch (i) {
        case 0:
          if (this.contactData.companyName === "") {
            flag = false;
            this.orgNameMsg = "请输入企业名称";
          } else {
            this.orgNameMsg = "";
          }
          break;
        case 1:
          var regx = /^1(3|4|5|6|7|8|9)\d{9}$/;
          if (this.contactData.mobile === "") {
            flag = false;
            this.phoneMsg = "请输入手机号码";
          } else if (!regx.test(this.contactData.mobile)) {
            flag = false;
            this.phoneMsg = "请输入正确的手机号码";
          } else if (this.contactData.mobile) {
            checkMobile({ mobile: this.contactData.mobile }).then(res => {
              res = res.data;
              if (res.code === "0") {
                if (res.message) {
                  flag = false;
                  this.phoneMsg = res.message;
                } else {
                  this.phoneMsg = "";
                }
              }
            });
          } else {
            this.phoneMsg = "";
          }
          break;
        case 2:
          if (this.contactData.contactName === "") {
            flag = false;
            this.nameMsg = "请输入联系人";
          } else {
            this.nameMsg = "";
          }
          break;
        case 3:
          if (this.contactData.intention === "") {
            flag = false;
            this.intentionMsg = "请选择您需要的意向";
          } else {
            this.intentionMsg = "";
          }
          break;
        case 4:
          var regx2 = /^\d{6}$/;
          if (this.contactData.vcode === "") {
            flag = false;
            this.vcodeMsg = "请输入验证码";
          } else if (!regx2.test(this.contactData.vcode)) {
            flag = false;
            this.vcodeMsg = "请输入正确的验证码";
          } else {
            this.vcodeMsg = "";
          }
          // else if (this.contactData.vcode) {
          //   checkMobile({ vcode: this.contactData.vcode }).then(res => {
          //     res = res.data;
          //     if (res.code === "0") {
          //       if (res.message) {
          //         flag = false;
          //         this.vcodeMsg = res.message;
          //       } else {
          //         this.vcodeMsg = "";
          //       }
          //     }
          //   });
          // } else {
          //   this.vcodeMsg = "";
          // }
          break;
      }
      this.formFlag = flag;
      return flag;
    },
    // 提示框
    alertDia() {
      this.displayStsates = "block";
      // this.aletMsg = msg;
      // 延迟2秒后消失 自己可以更改时间
      // window.setTimeout(() => {
      //   this.displayStsates = "none";
      // }, 3000);
    }
  }
};
</script>
