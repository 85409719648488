<template>
  <div class="product-single about-honor">
    <div class="main-wrap">
      <div class="tit-box">
        <h2>公司荣誉</h2>
      </div>
      <div class="con-box">
        <div class="tab-cont">
          <div class="honorCon">
            <div class="honorCon_c clearfloat">
              <ul
                v-for="(item, index) in honorData"
                :id="'ul_' + index"
                :key="index"
                class="clearfloat uls"
                :style="{ left: item.left + 'px' }"
              >
                <li
                  v-for="lisList in item.list"
                  :key="lisList.id"
                  class="about-li"
                >
                  <div class="about-li-con">
                    <img :src="lisList.src" />
                    <h3>{{ lisList.text }}</h3>
                  </div>
                </li>
              </ul>
              <div class="carousel-items">
                <span
                  v-for="(item, index) in honorData"
                  :key="index"
                  v-show="!item.hidden"
                  :class="{ active: index === currentIndex }"
                  @click="change(index)"
                >
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import "swiper/swiper-bundle.css";
export default {
  name: "AboutHonor",
  components: {},
  props: {},
  data() {
    return {
      honorData: [
        {
          index: 1,
          left: 0,
          hidden: false,
          list: [
            {
              text: "湖南省数字经济促进会会员单位",
              src: require("../../assets/img/honorA_img1.jpg")
            },
            {
              text: "高新技术企业证",
              src: require("../../assets/img/honorA_img2.jpg")
            },
            {
              text: "悦商圈商户IOS软著证",
              src: require("../../assets/img/honor_img1.jpg")
            },
            {
              text: "悦商圈CRM软著证",
              src: require("../../assets/img/honor_img2.jpg")
            },
            {
              text: "悦商圈客户安卓软著证",
              src: require("../../assets/img/honor_img3.jpg")
            },
            {
              text: "悦商圈客户IOS软著证",
              src: require("../../assets/img/honor_img4.jpg")
            },
            {
              text: "悦商圈商户安卓软著证",
              src: require("../../assets/img/honor_img5.jpg")
            },
            {
              text: "悦云管IOS软著证",
              src: require("../../assets/img/honor_img6.jpg")
            }
          ]
        },
        {
          index: 2,
          left: 1200,
          hidden: false,
          list: [
            {
              text: "悦商惠小程序软著证",
              src: require("../../assets/img/honor_img7.jpg")
            },
            {
              text: "悦邻家安卓软著证",
              src: require("../../assets/img/honor_img8.jpg")
            },
            {
              text: "悦商管小程序软著证",
              src: require("../../assets/img/honor_img9.jpg")
            },
            {
              text: "悦商管员工小程序软著证",
              src: require("../../assets/img/honor_img10.jpg")
            },
            {
              text: "悦荷包小程序软著证",
              src: require("../../assets/img/honor_img11.jpg")
            },
            {
              text: "悦购小程序软著证",
              src: require("../../assets/img/honor_img12.jpg")
            },
            {
              text: "统一认证平台软著证",
              src: require("../../assets/img/honor_img13.jpg")
            },
            {
              text: "大数据分析平台软著证",
              src: require("../../assets/img/honor_img14.jpg")
            }
          ]
        },
        {
          index: 3,
          left: 2400,
          hidden: false,
          list: [
            {
              text: "数据中心平台软著证",
              src: require("../../assets/img/honor_img15.jpg")
            },
            {
              text: "用户画像营销系统软著证",
              src: require("../../assets/img/honor_img16.jpg")
            }
          ]
        },
        {
          hidden: true
        }
      ],
      currentIndex: 0,
      n: 0,
      timer: ""
    };
  },
  computed: {},
  mounted() {
    this.$nextTick(function() {
      this.honorData[this.honorData.length - 1] = JSON.parse(
        JSON.stringify(this.honorData[0])
      );
      this.honorData[this.honorData.length - 1].hidden = true;
      this.swiperAnimation();
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  }, // 生命周期 - 销毁之前
  methods: {
    swiperAnimation() {
      var _this = this;
      var flag = true;
      function next() {
        _this.timer = setInterval(() => {
          if (flag && _this.currentIndex < _this.honorData.length - 1) {
            _this.currentIndex++;
            _this.animateNext();
          }
        }, 4000);
      }
      next();
    },
    animatePrev() {
      var _this = this;
      var interval = 100;
      var speed = 400;
      var ul0Left = (0 - _this.currentIndex) * 1200;
      function go() {
        if (parseInt(document.getElementById("ul_0").style.left) < ul0Left) {
          for (var i = 0; i < _this.honorData.length; i++) {
            _this.honorData[i].left += speed;
          }
          setTimeout(go, interval);
        } else {
          for (var j = 0; j < _this.honorData.length; j++) {
            _this.honorData[j].left = (j - _this.currentIndex) * 1200;
          }
        }
      }
      go();
    },
    animateNext() {
      var _this = this;
      var interval = 100;
      var speed = -400;
      var ul0Left = (0 - _this.currentIndex) * 1200;
      function go() {
        if (parseInt(document.getElementById("ul_0").style.left) > ul0Left) {
          for (var i = 0; i < _this.honorData.length; i++) {
            _this.honorData[i].left += speed;
          }
          setTimeout(go, interval);
        } else {
          if (_this.currentIndex == _this.honorData.length - 1)
            _this.currentIndex = 0;
          for (var j = 0; j < _this.honorData.length; j++) {
            _this.honorData[j].left = (j - _this.currentIndex) * 1200;
          }
        }
      }
      go();
    },
    stop() {
      clearInterval(this.timer);
      this.timer = null;
    },
    change(index) {
      this.stop();
      if (this.currentIndex < index) {
        this.currentIndex = index;
        this.animateNext();
      } else {
        this.currentIndex = index;
        this.animatePrev();
      }
      this.swiperAnimation();
    },
    autoPlay() {
      this.currentIndex++;
      if (this.currentIndex > this.honorData.length - 1) {
        this.currentIndex = 0;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.honorCon {
  width: 1200px;
  overflow: hidden;
  .honorCon_c {
    position: relative;
    width: 100%;
    max-width: 1200px;
    overflow: hidden;
    height: 700px;
  }
}
.uls {
  width: 1200px;
  height: 700px;
  position: absolute;
}
.carousel-items {
  position: absolute;
  z-index: 10;
  bottom: 50px;
  width: 100%;
  text-align: center;
  font-size: 0;
}

.carousel-items span {
  width: 22px;
  height: 4px;
  display: inline-block;
  background: #b8cdff;
  margin-right: 8px;
  background-clip: content-box;
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -ms-transition: all 1s ease-out;
  transition: all 1s ease-out;
  border-radius: 3px;
  opacity: 1;
  cursor: pointer;
}

.carousel-items .active {
  background-color: #2c68ff;
}
</style>
