<template>
  <div class="con-wrapper">
    <div class="pc">
      <header-con />
      <div class="news">
        <div class="news-box">
          <div class="main-wrap">
            <ul class="news-list">
              <li class="pr">
                <div class="pr full flex flex-middle li-box">
                  <div class="img-box abs-news"></div>
                  <div class="text-box">
                    <h3 class="ellips">
                      从“开发商售后”到国家“软基建”，大物业对数字化管理需求日渐紧迫
                    </h3>
                    <p class="ellips2">
                      2020年，物业板块经历了一波显著大涨，估值迅速上升到六七十倍，虽近期有所回落，但仍保持四十倍左右。物业成为资本市场的宠儿，除了疫情期间社区服务的重要性凸显......
                    </p>
                    <div class="clearfloat info-box">
                      <span>悦云数字科技</span>
                      <span class="pr news-time">| 2020-11-29</span>
                    </div>

                    <router-link :to="{ path: '/pc/newsDetailsSec' }">
                      <a class="abs-lt full"></a>
                    </router-link>
                  </div>
                </div>
              </li>
              <li class="pr">
                <div class="pr full flex flex-middle li-box">
                  <div class="img-box img-box1 abs-news"></div>
                  <div class="text-box">
                    <h3 class="ellips">
                      传统商业地产靠什么赢得未来？商圈大运营模式提前到来！
                    </h3>
                    <p class="ellips2">
                      中国消费者的需求正在不断变化。麦肯锡2017年全球消费者信心调查显示，近六成中国消费者的消费行为在2016—2017年发生了显著变化......
                    </p>
                    <div class="clearfloat info-box">
                      <span>悦云数字科技</span>
                      <span class="pr news-time">| 2019-03-16</span>
                    </div>
                    <router-link :to="{ path: '/pc/newsDetails' }">
                      <a class="abs-lt full"></a>
                    </router-link>
                  </div>
                </div>
              </li>
              <li class="pr">
                <div class="pr full flex flex-middle li-box">
                  <div class="img-box img-box2 abs-news"></div>
                  <div class="text-box">
                    <h3 class="ellips">
                      日曝光量高达130000+！这个商场如何将线上流量转化成线下客流的？
                    </h3>
                    <p class="ellips2">
                      悦商圈在株洲·大汉悦中心实施以来，首月单日曝光量峰值高达130000+，新注册会员数已突破10000+，为线下新增订单更是逼近5000单，为消费者带来便利的同时.......
                    </p>
                    <div class="clearfloat info-box">
                      <span>悦云数字科技</span>
                      <span class="pr news-time">| 2018-09-07</span>
                    </div>
                    <router-link :to="{ path: '/pc/newsDetailsThi' }">
                      <a class="abs-lt full"></a>
                    </router-link>
                  </div>
                </div>
              </li>
            </ul>
            <div class="page-box pr">
              <!-- <div class="com-page-nav flex abs-rt">
                <div class="el-pagination is-background">
                  <button type="button" disabled="disabled" class="btn-prev">
                    <i class="el-icon el-icon-arrow-left"></i>
                  </button>
                  <ul class="el-pager">
                    <li class="number active">1</li>
                    <li class="number">2</li>
                    <li class="number">3</li>
                    <li class="number">4</li>
                    <li class="number">5</li>
                    <li class="number">6</li>
                    <li class="el-icon more btn-quicknext el-icon-more">...</li>
                    <li class="number">17</li>
                  </ul>
                  <button type="button" class="btn-next">
                    <i class="el-icon el-icon-arrow-right">></i>
                  </button>
                </div>
                <div class="cpn-total">共<span>164</span>条</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="fooCon">
        <footer-conTop />
        <footer-con />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerCon"; // 头部
import FooterConTop from "@/components/footerConTop"; // 底部免费试用
import FooterCon from "@/components/footerCon"; // 底部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
export default {
  name: "news",
  components: {
    HeaderCon,
    FooterCon,
    FooterConTop
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      topNavDistance: 0,
      active: 0,
      allMenuList: [
        {
          value: "方案构架",
          id: "1"
        },
        {
          value: "方案优势",
          id: "2"
        }
      ]
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleNavScroll);
    window.addEventListener("scroll", this.onScroll, false);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.handleNavScroll);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    handleNavScroll() {
      // 二级导航滚动到指定位置固定
      this.topNavDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    // 二级导航+内容联动滚动监听
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(
        ".product-scroll .scroll-con"
      );
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });
      // 获取当前文档流的 scrollTop
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop += 150;
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n;
        }
      }
      this.active = navIndex;
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      var targetOffsetTop = document.querySelector(
        `.product-scroll .scroll-con:nth-child(${index + 1})`
      ).offsetTop;
      targetOffsetTop -= 150;
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
      const STEP = 50;
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    }
  }
};
</script>
