<template>
  <div class="con-wrapper">
    <div class="wap">
      <header-con />
      <div class="contact-main">
        <div class="contact-con">
          <h2>获取方案</h2>
          <p>填写信息后我们会第一时间联系你</p>
          <form :model="contactData" class="contact-form">
            <div class="form-item m-flex-center red-s">
              <input
                type="text"
                v-model.trim="contactData.companyName"
                placeholder="企业名称"
                maxlength="20"
                class="wid_input"
              />
            </div>
            <div class="form-item m-flex-center red-s">
              <input
                type="text"
                v-model.trim="contactData.mobile"
                placeholder="手机号码"
                maxlength="11"
                class="wid_input"
              />
            </div>
            <div class="form-item m-flex-center red-s">
              <input
                type="text"
                v-model="contactData.userName"
                placeholder="联系人"
                maxlength="10"
                class="wid_input"
              />
            </div>
            <div class="form-item m-flex-center">
              <textarea
                rows="4"
                type="text"
                v-model.trim="contactData.remarks"
                placeholder="备注"
                maxlength="30"
              ></textarea>
            </div>
            <button :disabled="isDisable" class="submit" @click="check">
              提交试用申请
            </button>
          </form>
          <div v-if="formFlag === false" class="dialog">
            <div class="dialog-cont">
              <p>
                <textarea
                  v-model.trim="errMsg"
                  class="err-msg"
                  readonly="readonly"
                />
              </p>
            </div>
          </div>
          <div v-if="succForm === true" class="dialogSecc">
            <div class="dialog-cont">
              <img src="../../../assets/img/success_icon.png" />
              <h3>提交成功</h3>
              <p>我是小悦，您的专属顾问将及时联系您，请保持电话畅通！</p>
              <router-link :to="{ path: '/wap' }">
                <span>返回首页</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="fooConWap">
        <footer-conW />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerConWap"; // 头部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
import FooterConW from "@/components/footerConWap"; // 底部
import { apply } from "@/api/api"; //接口
export default {
  name: "about",
  components: {
    HeaderCon,
    FooterConW
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      contactData: {
        companyName: "",
        userName: "",
        mobile: "",
        remarks: ""
      },
      errMsg: "",
      formFlag: true,
      isDisable: false,
      succForm: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    // 提交验证
    check(e) {
      e.preventDefault();
      if (this.contactData.companyName === "") {
        setTimeout(() => {
          this.formFlag = true;
        }, 2000);
        this.errMsg = "请输入企业名称";
        this.formFlag = false;
        return;
      } else {
        this.errMsg = "";
      }
      var regx = /0?(13|14|15|17|18|19)[0-9]{9}/;
      if (this.contactData.mobile === "") {
        this.formFlag = false;
        this.errMsg = "请输入手机号码";
        setTimeout(() => {
          this.formFlag = true;
        }, 2000);
        return;
      } else if (!regx.test(this.contactData.mobile)) {
        this.formFlag = false;
        this.errMsg = "请输入正确的手机号码";
        setTimeout(() => {
          this.formFlag = true;
        }, 2000);
        return;
      } else {
        this.errMsg = "";
      }
      if (this.contactData.userName === "") {
        this.formFlag = false;
        this.errMsg = "请输入联系人";
        setTimeout(() => {
          this.formFlag = true;
        }, 2000);
        return;
      } else {
        this.errMsg = "";
      }
      this.isDisable = true;
      var data = this.contactData;
      apply(data).then(res => {
        setTimeout(() => {
          this.isDisable = false;
        }, 3000);
        res = res.data;
        if (res.code === "0") {
          this.succForm = true;
        }
      });
    }
  }
};
</script>
