<template>
  <div class="con-wrapper">
    <div class="wap">
      <header-con />
      <div class="m-main">
        <div class="banner-single">
          <div class="banner"></div>
          <div class="banner-con">
            <h1>悦商圈</h1>
            <span></span>
            <p class="tit-desc">
              悦商圈的智慧商圈解决方案是基于移动互联网，通过大数据共享、多平台联动、智能设备、物联网等技术手段，实现商管全链条信息化，为运营方、商家、消费者搭建一个线上的、全流程的、多方位的智慧商圈大平台，重构传统实体商业人货场关系，重塑商业价值链。
            </p>
            <div class="banner-btn">
              <router-link :to="{ path: '/wap/contact' }" class="btn-ask"
                >免费试用</router-link
              >
            </div>
          </div>
        </div>
        <div :class="topNavDistance > 390 ? 'fixed' : ''" class="scroll-nav">
          <div class="scroll-navCon">
            <ul class="main-wrap flex flex-middle">
              <li
                v-for="(item, index) in allMenuList"
                :key="item.id"
                :class="{ active: active == index }"
                @click="scrollTo(index)"
              >
                {{ item.value }}
                <span></span>
              </li>
            </ul>
          </div>
        </div>
        <div class="product-scroll">
          <product-advantageWap :advantage="advantageList" class="scroll-con" />
          <product-function :functionPro="functionList" class="scroll-con" />
          <!-- <div class="main-single wap-business scroll-con">
            <div>
              <div class="tit-box">
                <h2>业务场景</h2>
              </div>
              <div class="con-box">
                <div class="tab-cont">
                  <div class="w100">
                    <div class="business-con">
                      <div class="business-tit">
                        <h2>线上商城，平台流量多样转化</h2>
                        <span></span>
                      </div>
                      <div class="business-tabCon clearfloat">
                        <img
                          src="../../../assets/img/wap/product_bus_pic1.png"
                          class="fl"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="w100">
                    <div class="business-con">
                      <div class="business-tit">
                        <h2>商户核销，用户可以到线下快速消费，快速核销</h2>
                        <span></span>
                      </div>
                      <div class="business-tabCon clearfloat">
                        <img
                          src="../../../assets/img/wap/product_bus_pic2.png"
                          class="fr"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="w100">
                    <div class="business-con">
                      <div class="business-tit">
                        <h2>停车支付，绑定车牌号可以无感快速支付</h2>
                        <span></span>
                      </div>
                      <div class="business-tabCon clearfloat">
                        <img
                          src="../../../assets/img/wap/product_bus_pic1.png"
                          class="fl"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <product-operateWapBus class="scroll-con" />
        </div>
      </div>
      <div class="fooConWap">
        <footer-conTopW />
        <footer-conW />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerConWap"; // 头部
import ProductAdvantageWap from "@/components/products/productAdvantageWap"; // 产品优势
import ProductFunction from "@/components/products/productFunctionWap"; // 产品功能
import productOperateWapBus from "@/components/products/productOperateWapBus"; // 商业合作案例
import FooterConTopW from "@/components/footerConTopW"; // 底部免费试用
import FooterConW from "@/components/footerConWap"; // 底部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
export default {
  name: "proBusinessDistrict",
  components: {
    HeaderCon,
    FooterConTopW,
    FooterConW,
    ProductAdvantageWap,
    ProductFunction,
    productOperateWapBus
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      topNavDistance: 0,
      active: 0,
      allMenuList: [
        {
          value: "产品优势",
          id: "1"
        },
        {
          value: "产品功能",
          id: "2"
        },
        // {
        //   value: "业务场景",
        //   id: "3"
        // },
        {
          value: "合作案例",
          id: "3"
        }
      ],
      //产品优势内容
      advantageList: [
        {
          id: "1",
          tit: "系统更全面",
          titCon: "涵盖CRM、物业、支付、客流、停车等一系列智能系统及硬件",
          src: require("../../../assets/img/funIcon/proFun_icon19.png")
        },
        {
          id: "2",
          tit: "管理更高效",
          titCon:
            "采集软硬件数据，提供数据分析和建议，实现实体商业信息化管理方法升级",
          src: require("../../../assets/img/funIcon/proFun_icon20.png")
        },
        {
          id: "3",
          tit: "产品更灵活",
          titCon: "支持SAAS化定制，同时具备标准API接口，实现各类系统对接",
          src: require("../../../assets/img/funIcon/proFun_icon21.png")
        },
        {
          id: "4",
          tit: "创收有方法",
          titCon:
            "提供悦卡产品，可开发属于自己的福利卡或商场预付卡模式，增加创收能力",
          src: require("../../../assets/img/funIcon/proFun_icon22.png")
        },
        {
          id: "5",
          tit: "运营更到位",
          titCon: "带来本地化的从会员到产品的大运营服务实现从模式到运营的成功",
          src: require("../../../assets/img/funIcon/proFun_icon23.png")
        },
        {
          id: "6",
          tit: "更具性价比",
          titCon: "打通了上下游的供应商，提供SAAS化服务和一站式服务",
          src: require("../../../assets/img/funIcon/proFun_icon24.png")
        }
      ],
      // 产品功能内容
      functionList: [
        {
          id: "1",
          tit: "会员体系",
          con:
            "从传统实体卡会员到电子会员，从粉丝会员、到访会员到支付会员，完整覆盖线上线下场景进行泛会员管理，建立商业标准泛会员体系。",
          src: require("../../../assets/img/funIcon1/proFunT_icon25.png")
        },
        {
          id: "2",
          tit: "营销管理",
          con:
            "统一商户营销，针对潜客、会员进行多样化营销，用户实现积分获取，积分兑换，线上消费，线上抽奖、拼团、砍价等多种营销功能，多种手段实现拉新、促活、留存、促进消费",
          src: require("../../../assets/img/funIcon1/proFunT_icon26.png")
        },
        {
          id: "3",
          tit: "商户中心",
          con:
            "支持商户移动办公，帮助商户通过手机随时管理店务、快速管理门店营销、查询门店经营数据",
          src: require("../../../assets/img/funIcon1/proFunT_icon27.png")
        },
        {
          id: "4",
          tit: "到家服务",
          con:
            "悦到家是一种线上订餐平台，用户可以足不出户就能享受到家送餐服务，提供简单订餐、在线支付、品牌外卖等服务；",
          src: require("../../../assets/img/funIcon1/proFunT_icon28.png")
        },
        {
          id: "5",
          tit: "客流分析",
          con:
            "提供多种认证联网服务，通过分层分时分区域的客流统计分析，指导商场优化业态结构。",
          src: require("../../../assets/img/funIcon1/proFunT_icon29.png")
        },
        {
          id: "6",
          tit: "智慧停车",
          con:
            "提供线上查费缴费、无感停车、停车电子发票等，帮助商场减少车场人力开支，提升顾客停车体验",
          src: require("../../../assets/img/funIcon1/proFunT_icon30.png")
        },
        {
          id: "7",
          tit: "智慧支付",
          con:
            "提供一种电子卡，用户可以通过商圈发放的电子储值卡实现线上线下购物结算",
          src: require("../../../assets/img/funIcon1/proFunT_icon31.png")
        },
        {
          id: "8",
          tit: "经营分析",
          con:
            "快速采集商业运营与管理等数据源，利用大数据平台梳理业务维度指标，通过可视化图表清晰地了解数据，从而辅助决策，以数据驱动运营",
          src: require("../../../assets/img/funIcon1/proFunT_icon32.png")
        }
      ],
      loading: true,
      noScroll: false
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleNavScroll);
    window.addEventListener("scroll", this.onScroll, false);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.handleNavScroll);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    handleNavScroll() {
      // 二级导航滚动到指定位置固定
      this.topNavDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    // 二级导航+内容联动滚动监听
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(".scroll-con");
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });
      // 获取当前文档流的 scrollTop
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop += 52 * window.devicePixelRatio;
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (
          scrollTop >=
          (this.noScroll ? (offsetTopArr[n] -= 60) : offsetTopArr[n])
        ) {
          navIndex = n;
        }
      }
      if (this.active == 3) {
        this.active = 3;
      } else {
        this.active = navIndex;
      }
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      if (index == 3) {
        this.active = 3;
      } else {
        this.active = index;
      }
      var targetOffsetTop = document.querySelector(
        `.product-scroll .scroll-con:nth-child(${index + 1})`
      ).offsetTop;
      targetOffsetTop -= 52 * window.devicePixelRatio;
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
      const STEP = 50;
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        if (this.topNavDistance < 390) {
          targetOffsetTop -= 60;
          this.noScroll = true;
        } else {
          this.noScroll = false;
        }
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    }
  }
};
</script>
