<template style="background:#F5F6F7">
  <div class="con-wrapper" style="background:#F5F6F7">
    <div class="wap wapBg">
      <header-con style="background:#fff" />
      <div class="m-main">
        <div class="news-wrap">
          <router-link :to="{ path: '/wap/newsDetails' }">
            <div class="news-con">
              <div class="news-img">
                <img src="../../../assets/img/wap/newsPic.png" />
              </div>
              <div class="news-tit">
                <h3>
                  从“开发商售后”到国家“软基建”，大物业对数字化管理需求日渐紧迫
                </h3>
                <div class="new-sourse clearfloat">
                  <span>悦云数字科技 / 2020-11-29</span>
                  <img src="../../../assets/img/wap/left_icon.png" />
                </div>
              </div>
            </div>
          </router-link>
          <router-link :to="{ path: '/wap/newsDetailsSec' }">
            <div class="news-con">
              <div class="news-img">
                <img src="../../../assets/img/wap/newsPic1.png" />
              </div>
              <div class="news-tit">
                <h3>
                  传统商业地产靠什么赢得未来？商圈大运营模式提前到来！
                </h3>
                <div class="new-sourse clearfloat">
                  <span>悦云数字科技 / 2019-03-16</span>
                  <img src="../../../assets/img/wap/left_icon.png" />
                </div>
              </div>
            </div>
          </router-link>
          <router-link :to="{ path: '/wap/newsDetailsThi' }">
            <div class="news-con">
              <div class="news-img">
                <img src="../../../assets/img/wap/newsPic2.png" />
              </div>
              <div class="news-tit">
                <h3>
                  日曝光量高达130000+！这个商场如何将线上流量转化成线下客流的？
                </h3>
                <div class="new-sourse clearfloat">
                  <span>悦云数字科技 / 2018-09-07</span>
                  <img src="../../../assets/img/wap/left_icon.png" />
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="fooConWap" style="background:#F5F6F7">
        <div class="news-foo">
          <p>构筑数据价值基线</p>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerConWap.vue"; // 头部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
export default {
  name: "news",
  components: {
    HeaderCon
  },
  metaInfo: getMetaInfo(),
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="scss" scope>
.wapBg {
  background: #f5f6f7;
}
.m-main {
  .news-wrap {
    padding: 1.4rem 0.15rem 0;
    .news-con {
      background: #fff;
      border-radius: 8px;
      padding-bottom: 0.4rem;
      margin-bottom: 0.4rem;
      .news-img {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        img {
          height: 3.92rem;
          width: 100%;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
      }
      .news-tit {
        padding: 0.32rem 0.3rem 0;
        h3 {
          font-size: 0.3rem;
          color: #00041b;
          text-align: left;
        }
        .new-sourse {
          margin: 0.2rem 0 0;
          span {
            float: left;
            font-size: 0.22rem;
            color: #00041b;
          }
          img {
            width: 0.58rem;
            float: right;
            margin-top: 0.1rem;
          }
        }
      }
    }
  }
}
.news-foo {
  height: 1.2rem;
  line-height: 1.2rem;
  bottom: 0;
  left: 0;
  right: 0;
  p {
    font-size: 0.28rem;
    color: #d8d8d8;
    text-align: center;
    background: #f5f6f7;
    position: relative;
    z-index: 99;
    width: 34%;
    margin: 0 auto;
    &:after {
      position: absolute;
      left: 50%;
      right: 0;
      bottom: 0;
      width: 70px;
      height: 1px;
      margin-left: -35px;
      background-color: #2c68ff;
    }
  }
  span {
    border-bottom: 1px solid #d8d8d8;
    width: 60%;
    display: block;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    margin-left: -30%;
    bottom: 0.6rem;
    z-index: 98;
  }
}
</style>
