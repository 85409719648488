<template>
  <div class="con-wrapper">
    <div class="wap wapDel">
      <header-con style="background:#fff" />
      <div class="m-main">
        <div class="news-wrapDel">
          <div class="news-del">
            <h2>传统商业地产靠什么赢得未来？商圈大运营模式提前到来！</h2>
            <p>悦云数字科技<span> / 2019-03-16</span></p>
          </div>
          <div class="news-delCon">
            <img src="../../../assets/img/newImg1.gif" />
            <p>
              中国消费者的需求正在不断变化。麦肯锡2017年全球消费者信心调查显示，近六成中国消费者的消费行为在2016—2017年发生了显著变化。
              90后的崛起对存量商业地产的持续提升也提出了新要求，根据麦肯锡2017年中国消费者调查，近四成90后消费者希望获得更好的购物环境、服务和产品，并不介意为此付出更高的价格。
            </p>
            <p>
              面对增量稀少，存量却备受消费者多变需求挑战的情况，不少商业地产运营商依然遵循行业惯性，希望通过在标准开发运营模式上“打补丁”的方式跑赢市场，结果往往不及预期。
            </p>
            <p>
              既然商业地产的本质是为了连接商户和消费者，满足消费者需求，我们就以终为始，从消费者和商户的痛点出发找出标准商业地产模式中的问题，并探索未来开发运营模式的新趋势。
            </p>
            <img src="../../../assets/img/newImg.jpg" />
            <p>
              统一品牌、统一业态组合、统一设计的标准化复制模式在商业地产行业大行其道。商业地产运营商以产品线的形式覆盖体量从1万平方米到10万平方米以上的所有商业地产项目，基于类似的主力店、自营业态、模块化设计。这种模式支撑了批量复制时期的商业地产增长。
            </p>
            <p>
              但随着城市发展，核心商圈和区域商圈趋于饱和，消费者往往面对多个商圈和雷同的购物中心，患上“选择困难症”。
            </p>
            <p style="text-align:center;font-weight: bold;">未来新趋势：大会员</p>
            <p>
              <img src="../../../assets/img/newImg1.jpg" />
              未来，大型购物中心需要通过打造、更新“大IP”，吸引各类人群成为自己的会员，建立起以商场为中心辐射周边3-5公里的生活圈，推动人流量增长。可以运用不同的“IP”主题组合，不断产品重新设计，如亲子卡通、艺术设计、选秀明星、地方文化等，实现辐射区域内的影响力最大化。
            </p>
            <p>
              <img src="../../../assets/img/newImg3.jpg" />
              在电商挤占线下零售发展空间的今天，购物中心纷纷购买线上媒体、本地化自媒体或联合电商网站，在“双十一”等线上购物的主要窗口大量投放广告，希望重新将消费者从线上吸引到线下。
            </p>
            <p>
              然而，在标准品类中，由于线上渠道价格优惠透明、服务精准高效、产品海量丰富，习惯了线上购物的消费者往往不愿意回到线下。基于促销活动从线上向线下引流十分困难。
            </p>
            <p style="text-align:center;font-weight: bold;">未来新趋势：大数据</p>
            <p>
              <img src="../../../assets/img/newImg4.jpg" />
              我们这里所说的大数据区别于互联网大数据，以商场为中心辐射周边生活圈的大数据是基于地理位置为基础，建立起属于购物中心自己的大数据平台，是具有独一性的，是别人抢不走的数字资产，为实现自己购物中心的线上线下全渠道营销成为必不可少的条件。
            </p>
            <p>
              <img src="../../../assets/img/newImg5.jpg" />
              商业地产运营商同质化程度高，项目延期开业、回报率低甚至倒闭等诸多现象屡见不鲜。2016年，超过50%的新购物中心项目延期开业超过半年，这其中大半与招商无法满足开业要求有关。招商与市场运营脱节，不能满足市场现状。
            </p>
            <p>
              然而实际情况是大部分也开业的商场，运营部门没有接触到消费者需求，也无法调整产品结构，无法反馈给招商，招商定位及招商调整，与市场需求实际脱节。销售的好坏、消费者的需求，没有直接反馈到招商调整方案中来。
            </p>
            <p style="text-align:center;font-weight: bold;">未来新趋势：大运营</p>
            <p>
              <img src="../../../assets/img/newImg6.jpg" />
              未来，将商业综合体所在3-5公里范围内，所有参与商业活动的对象，都视为大运营系统的有机组成部分。通过大运营系统，为参与在其中的各方成员，最大程度的创造效益。
            </p>
            <p>
              对象包括但不限于：消费者、商管公司全体成员、商家、供应商、竞争对手、相关职能部门等等。
            </p>
            <p>
              目前，购物中心的传统租金模式受到了明显挑战。商业资产的估值持续走低，主要表现在租金下跌和运营成本高企。究其根本原因，是仅有
              “场地”已经无法提供足够的价值。
            </p>
            <p>
              因此，未来消费者诉求的价值将会成为商业地产的新价值点，满足消费者多样化需求，开辟与新价值所匹配的创新盈利模式，才是突围的根本要义。
            </p>
            <p style="text-align:center;font-weight: bold;">破局之路</p>
            <p>
              <img src="../../../assets/img/newImg7.jpg" />
              建设属于自己商场的数字信息平台系统
            </p>
            <p>
              大部分商业地产客户缺少数字管理基础设施，IT系统等落后，数据收集和整理能力有限。全系统支撑商管、商户、顾客的工作数字化，打破各个部门之间数据断裂，促进相互沟通并形成全面的客户画像。
            </p>
            <p>
              <img src="../../../assets/img/newImg7.jpg" />
              “悦商圈”的智慧商圈解决方案是基于移动互联网，通过大数据共享、多平台联动、智能设备、物联网等技术手段，为商管公司实现从管理到运营全链信息化，让商管工作有迹可寻，提高工作效率，更为运营方、商家、消费者搭建一个线上的、全流程、多方位的智慧商圈大平台，重构传统实体商业人-货-场的关系，完成以实体商业为中心的一整套智慧商圈管理系统及解决方案。
            </p>
          </div>
          <div class="news-delBtn clearfloat">
            <router-link :to="{ path: '/wap/newsDetails' }">
              <span class="detBtn-r fl">上一篇</span>
            </router-link>
            <router-link :to="{ path: '/wap/newsDetailsThi' }">
              <span class="detBtn-r fr">下一篇</span>
            </router-link>
          </div>
        </div>
        <div class="fooConWap">
          <footer-conTopW />
          <footer-conW />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerConWap.vue"; // 头部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
import FooterConTopW from "@/components/footerConTopW"; // 底部免费试用
import FooterConW from "@/components/footerConWap"; // 底部
export default {
  name: "newsDetailsSec",
  components: {
    HeaderCon,
    FooterConTopW,
    FooterConW
  },
  metaInfo: getMetaInfo(),
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    toReturn() {
      this.$router.go(-1); // 返回上一层
    }
  }
};
</script>
<style lang="scss" scope>
.m-main {
  height: 100%;
  .news-wrapDel {
    background: #fff;
    height: 100%;
    padding: 1.4rem 0.44rem 0 0.34rem;
    .news-del {
      h2 {
        font-size: 0.44rem;
        color: #00041b;
        text-align: left;
      }
      p {
        margin-top: 0.36rem;
        text-align: left;
        font-size: 0.24rem;
        color: #4b4f5e;
        span {
          color: #a6a7af;
          margin-left: 0.1rem;
        }
      }
    }
    .news-delCon {
      margin: 0.8rem 0 0;
      p {
        font-size: 0.28rem;
        color: #00041b;
        padding-bottom: 0.4rem;
        text-indent: 20px;
        text-align: left;
        line-height: 0.5rem;
      }
      img {
        width: 100%;
        display: block;
        margin: 0 auto 0.4rem;
      }
    }
    .news-delBtn {
      padding: 0.2rem 0 1rem;
      span {
        width: 3rem;
        height: 0.98rem;
        line-height: 0.98rem;
        display: inline-block;
        text-align: center;
        font-size: 0.32rem;
        color: #00041b;
        border: 1px solid #c9c9cb;
      }
    }
  }
}
</style>
