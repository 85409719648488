<template>
  <div class="con-wrapper">
    <div class="pc">
      <header-con :index="0" class="header-barBg" />
      <div class="product-detail">
        <div class="product-single">
          <div class="f-banner floor">
            <div class="main-wrap ban-box">
              <h1 class="ban-box-h1">EHR</h1>
              <p class="tit-desc">
                提供以EHR为核心的一站式人力资源SAAS云平台,覆盖人力资源全模块,减少HR60%工作量。
              </p>
              <div class="banner-btn pr">
                <router-link :to="{ path: '/pc/contact' }" class="btn-ask">
                  免费试用
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="product-nav abs-lt w100">
          <div
            :class="topNavDistance > 490 ? 'fixed' : ''"
            class="anchor-box abs-lt w100"
          >
            <ul class="main-wrap clearfloat">
              <li
                v-for="(item, index) in allMenuList"
                :key="item.id"
                :class="{ active: active == index }"
                @click="scrollTo(index)"
              >
                <span class="abs-mt">{{ item.value }}</span>
                <p class="vh">{{ item.value }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="product-scroll">
          <product-advantage :advantage="advantageList" class="scroll-con" />
          <product-function :functionPro="functionList" class="scroll-con" />
        </div>
      </div>
      <div class="fooCon">
        <footer-conTop />
        <footer-con />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerCon"; // 头部
import FooterConTop from "@/components/footerConTop"; // 底部免费试用
import FooterCon from "@/components/footerCon"; // 底部
import ProductAdvantage from "@/components/products/productAdvantage"; // 产品优势
import ProductFunction from "@/components/products/productFunction"; // 产品功能
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字

export default {
  name: "proEhr",
  components: {
    HeaderCon,
    FooterCon,
    FooterConTop,
    ProductAdvantage,
    ProductFunction
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      // 二级导航
      allMenuList: [
        {
          value: "产品优势",
          id: "1"
        },
        {
          value: "产品功能",
          id: "2"
        }
      ],
      //产品优势内容
      advantageList: [
        {
          id: "1",
          tit: "C端用户产品体验",
          titCon: "深度打磨C端产品体验，让员工体验从“被管”到“被服务”.",
          src: require("../../../assets/img/funIcon/proFun_icon10.png")
        },
        {
          id: "2",
          tit: "高效在线管理",
          titCon: "流程、功能、业务在线管理，实现员工旅程数据全景化高效管理",
          src: require("../../../assets/img/funIcon/proFun_icon11.png")
        },
        {
          id: "3",
          tit: "集成化工作平台",
          titCon: "打通移动办公平台，实现主数据同步、单点登录、待办集成",
          src: require("../../../assets/img/funIcon/proFun_icon12.png")
        }
      ],
      // 产品功能内容
      functionList: [
        {
          id: "1",
          tit: "组织人事",
          con: "支持灵活的组织结构管理、员工全生命周期在线管理和规范的岗职位体系管理",
          src: require("../../../assets/img/funIcon1/proFunT_icon14.png")
        },
        {
          id: "2",
          tit: "假勤管理",
          con: "灵活落地企业各类考勤制度，员工考勤全面线上管理",
          src: require("../../../assets/img/funIcon1/proFunT_icon15.png")
        },
        {
          id: "3",
          tit: "薪酬福利",
          con: "多种薪资项目均可灵活组合，精细化核算员工薪酬",
          src: require("../../../assets/img/funIcon1/proFunT_icon16.png")
        },
        {
          id: "4",
          tit: "员工服务",
          con: "移动化员工服务，支持C端体验的档案、事项管理",
          src: require("../../../assets/img/funIcon1/proFunT_icon17.png")
        },
        {
          id: "5",
          tit: "智慧分析",
          con: "跨模块数据分析，可输出多维度的业务报表分析",
          src: require("../../../assets/img/funIcon1/proFunT_icon18.png")
        }
      ],
      topNavDistance: 0,
      active: 0
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleNavScroll);
    window.addEventListener("scroll", this.onScroll, false);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.handleNavScroll);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    handleNavScroll() {
      // 二级导航滚动到指定位置固定
      this.topNavDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    // 二级导航+内容联动滚动监听
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(
        ".product-scroll .scroll-con"
      );
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });
      // 获取当前文档流的 scrollTop
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop += 150;
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n;
        }
      }
      this.active = navIndex;
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      var targetOffsetTop = document.querySelector(
        `.product-scroll .scroll-con:nth-child(${index + 1})`
      ).offsetTop;
      targetOffsetTop -= 150;
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
      const STEP = 50;
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    }
  }
};
</script>
