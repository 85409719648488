<template>
  <div class="product-single product-advantage">
    <div class="main-wrap">
      <div class="tit-box pr">
        <h2>产品优势</h2>
      </div>
      <div class="con-box">
        <div class="tab-cont">
          <span>
            <ul class="clearfloat">
              <li v-for="(products, index) in advantage" :key="index">
                <div class="normal-box">
                  <img :src="products.src" />
                  <h4>{{ products.tit }}</h4>
                  <p>
                    {{ products.titCon }}
                  </p>
                </div>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "ProductAdvantage",
  props: {
    advantage: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
