<template>
  <ul class="tree-menu">
    <li v-for="(item, index) in data" :key="index">
      <span @click="toggle(item, index)">
        {{ item[name] || item.menuName }}
        <i
          :class="[
            'icon',
            item.children && item.children.length
              ? folderIconList[index]
              : 'file-text'
          ]"
        ></i>
      </span>

      <div v-if="item.menuGroup" class="tree-menuChild clearfloat">
        <span
          v-for="child in item.children"
          :key="child.menuCode"
          @click="toggle(child, index)"
        >
          {{ child.menuName }}
        </span>
      </div>

      <tree-menu
        v-if="!item.menuGroup && scope[index]"
        :data="item.children"
        class="tree-menuB"
      ></tree-menu>
    </li>
  </ul>
</template>

<script>
export default {
  name: "treeMenu",
  props: {
    data: Array,
    name: String
  },
  data() {
    return {
      folderIconList: [],
      scope: {}
    };
  },
  created() {
    this.data.forEach((item, index) => {
      if (item.children && item.children.length) {
        this.folderIconList[index] = "folder";
      }
    });
  },
  beforeDestroy() {
    this.data.forEach(item => {
      if (item.menuGroup !== undefined) {
        item.menuGroup = false;
      }
    });
  },
  methods: {
    doTask(index) {
      this.$set(this.scope, index, !this.scope[index]);
      this.folderIconList[index] = this.scope[index] ? "folder-open" : "folder";
    },
    toggle(item, index) {
      if (item.menuCode == "30") {
        if ("/wap" == this.$route.path) {
          this.$emit("getClickData", "cooperation");
        } else {
          this.$router.push({ path: "/wap", query: { flag: "cooperation" } });
        }
        return;
      }
      if (item.menuPath) {
        this.$router.push(item.menuPath);
      } else {
        if (item.children && item.children.length) {
          this.doTask(index);
          if (item.menuGroup !== undefined) {
            item.menuGroup = !item.menuGroup;
          }
        } else {
          this.$emit("getSubMenu", item, subMenuList => {
            if (subMenuList && subMenuList.length) {
              this.doTask(index);
            }
          });
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.tree-menuChild {
  margin: 0 -0.4rem;
  height: 3.6rem;
  span {
    flex: 1;
    width: 42% !important;
    float: left;
    margin-left: 8%;
    color: #8f8f98 !important;
    font-size: 0.26rem !important;
    font-weight: 600;
    text-align: left;
  }
}
.tree-menu {
  list-style: none;
  z-index: 100;
  height: auto;
  background: #1f1f32;
  font-size: 0.24rem;
  color: rgba(0, 0, 0, 0.85);
}
.tree-menu li {
  padding: 0 0.6rem;
  border-bottom: 1px solid #404050;
  span {
    position: relative;
  }
}
.tree-menu li span {
  cursor: default;
  display: block;
  font-size: 0.28rem;
  font-weight: 600;
  line-height: 1.2rem;
  height: 1.2rem;
  width: 100%;
  text-align: left;
  position: relative;
  color: #fff;
}
.tree-menuB {
  left: 0;
  top: 1.2rem;
  right: 0;
  li {
    padding: 0 0 0 0.4rem;
    border-bottom: 0;
    span {
      // color: #8F8F98;
    }
  }
}
.icon {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  right: 0rem;
  top: 0.46rem;
}
.icon.folder {
  background-image: url(../assets/img/wap/jiantou.png);
}
.icon.folder-open {
  background-image: url(../assets/img/wap/jiantou_up.png);
}
.icon.file-text {
  // background-image: url(../assets/img/wap/jiantou.png);
}
</style>
