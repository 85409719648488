<template>
  <div class="con-wrapper">
    <div class="pc">
      <header-con :type="0" :index="1" class="header-barBg" />
      <div class="product-banner">
        <div class="banner animate__animated">
          <img
            src="@/assets/img/banner.png"
            alt="商业管理数字化全流程解决方案"
            style="width:100%"
          />
        </div>
      </div>
      <div class="product-bg1 product-bg">
        <div class="bg1 message">
          <h3 class="banner animate__animated">
            商业管理困境层出不穷
          </h3>
          <ul class="clearfix">
            <li class="banner animate__animated">
              商业不动产库存过剩
            </li>
            <li class="banner animate__animated">
              品牌同质化竞争激烈
            </li>
            <li class="banner animate__animated">
              电商冲击有增无减
            </li>
            <li class="banner animate__animated">
              疫情发展常态化，<br />黑天鹅不断出现
            </li>
          </ul>
        </div>
      </div>
      <div class="product-bg2 product-bg">
        <div class="bg2 message">
          <h3 class="animate__animated">
            智慧商业布局应势破壳出圈 <br />商业管理数字化升级迫在眉睫
          </h3>
          <div class="animate__animated">
            <h4>悦资管&悦商圈，迈出数字化升级第一步</h4>
            <h4>用数据链接商业运营全流程，经营全要素实时互通</h4>
          </div>
        </div>
      </div>
      <div class="product-bg3 product-bg">
        <div class="bg3 message">
          <h3 class="animate__animated">
            — 悦系列商业主题产品，践行私域经济内循环 —
          </h3>
          <ul class="clearfix">
            <li class=" animate__animated">
              <h5>悦资管</h5>
              <div>购物中心商业资产管理平台</div>
              <router-link :to="{ path: '/pc/proBusPlatform' }">
                <button>
                  了解更多
                </button>
              </router-link>
            </li>
            <li class=" animate__animated">
              <h5>悦商圈</h5>
              <div>购物中心智慧商业会员管理平台</div>
              <router-link :to="{ path: '/pc/proBusinessDistrict' }">
                <button>
                  了解更多
                </button>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="product-bg4 product-bg">
        <div class="bg4 message">
          <!-- :class="animate__flipInX4" -->
          <h3 class="animate__animated">
            — 商业管理数字化升级五部曲 —
          </h3>
          <div class="content">
            <h4>设计数据结构</h4>
            <div style="margin-bottom: 20px !important;">
              拒绝数据孤岛，建立主数据平台
            </div>
            <div>统一账号，统一身份，统一授权，统一应用管理，统一审计</div>
            <img
              src="@/assets/img/content.png"
              class="animate__animated"
              alt=""
              style="width:100%"
            />
            <!-- :class="animate__bounceIn" -->
          </div>
        </div>
      </div>
      <div class="product-bg5 product-bg">
        <div class="bg5 message">
          <h3 class="animate__animated">
            悦资管规范业务流程，盘清资产
          </h3>
          <router-link :to="{ path: '/pc/proBusPlatform' }">
            <button>
              了解更多
            </button>
          </router-link>
          <div class="content">
            <ul class="clearfix">
              <li class=" animate__animated">
                <h5>基础信息线上化</h5>
                <div>
                  项目、楼栋、店铺 多层信息纵向管理 一铺一档， 单层信息横向管理
                  单点数据多维分析
                </div>
              </li>
              <li class=" animate__animated">
                <h5>业务流程标准化</h5>
                <div>
                  招商先行，企划造势。运营为王，物业保障。商业管理四大板块，全流程系统管控，真正做到业财一体
                </div>
              </li>
              <li class=" animate__animated">
                <h5>品牌资源集中化</h5>
                <div>
                  管理招商进度，实现品牌客户CRM全生命周期管理。
                  品牌库查看及指派权限，保护品牌资源不外流
                </div>
              </li>
              <li class=" animate__animated">
                <h5>合同管理自动化</h5>
                <div>
                  合同到期，账单欠缴，数字系统自动预警，线上缴费，自动匹配，财务账单，收付制和权责制双轨并行
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="product-bg6 product-bg">
        <div class="bg6 message">
          <h3 class="animate__animated">
            悦商圈沉淀会员数据，1+N系统多维评估消费行为
          </h3>
          <router-link :to="{ path: '/pc/proBusinessDistrict' }">
            <button>
              了解更多
            </button>
          </router-link>
          <div class="content">
            <img
              src="@/assets/img/system.png"
              alt="悦商圈会员数据"
              class="animate__animated"
              style="width:1060px"
            />
          </div>
        </div>
      </div>
      <div class="product-bg7 product-bg">
        <div class="bg7 message">
          <h3 class="animate__animated">
            组织主数据 & 业务主数据 <br />构建统一数据分析平台
          </h3>
          <div class="animate__animated">
            <h4>商业数据看板，实时查看场内运营数据</h4>
            <h4>客流，车流，销售动态展现</h4>
            <h4>定制数据报表，精准指导决策</h4>
          </div>
        </div>
      </div>
      <div class="product-bg8 product-bg">
        <div class="bg8 message">
          <h4>数据应用</h4>
          <h5>用数据链接智慧商业运营全流程</h5>
          <div>一键获取<strong>智慧商业全流程</strong> 定制方案</div>
          <router-link :to="{ path: '/pc/contact' }">
            <button>
              联系我们
            </button>
          </router-link>
        </div>
      </div>
      <div class="fooCon">
        <footer-con />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerCon.vue"; // 头部
import FooterCon from "@/components/footerCon"; // 底部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
export default {
  name: "solutionsBusiness",
  components: {
    HeaderCon,
    FooterCon
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      animate__flipInX: "",
      animate__lightSpeedInRight: "",
      animate__lightSpeedInLeft: "",
      animate__flipInX2: "",
      animate__flipInX3: "",
      animate__lightSpeedInLeft2: "",
      animate__lightSpeedInRight2: "",
      animate__flipInX4: "",
      animate__bounceIn: "",
      animate__flipInX5: "",
      animate__lightSpeedInLeft3: "",
      animate__lightSpeedInRight3: "",
      animate__flipInX6: "",
      animate__bounceIn2: "",
      animate__flipInX7: ""
    };
  },
  computed: {},
  created() {
    window.addEventListener("scroll", this.businessScroll); // 监听页面滚动
  },
  mounted() {
    window.addEventListener("scroll", this.businessScroll);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    // window.removeEventListener("scroll", this.businessScroll);
  },
  methods: {
    businessScroll() {
      let scrollTop = window.pageYOffset;
      if (scrollTop > 400) {
        this.animate__flipInX = "animate__flipInX";
        this.animate__lightSpeedInRight = "animate__lightSpeedInRight";
        this.animate__lightSpeedInLeft = "animate__lightSpeedInLeft";
      } else {
        this.animate__flipInX = "";
        this.animate__lightSpeedInRight = "";
        this.animate__lightSpeedInLeft = "";
      }
      if (scrollTop > 1100) {
        this.animate__flipInX2 = "animate__flipInX";
      } else {
        this.animate__flipInX2 = "";
      }
      if (scrollTop > 1600) {
        this.animate__flipInX3 = "animate__flipInX";
        this.animate__lightSpeedInRight2 = "animate__lightSpeedInRight";
        this.animate__lightSpeedInLeft2 = "animate__lightSpeedInLeft";
      } else {
        this.animate__flipInX3 = "";
        this.animate__lightSpeedInRight2 = "";
        this.animate__lightSpeedInLeft2 = "";
      }
      if (scrollTop > 2600) {
        this.animate__flipInX4 = "animate__flipInX";
      } else {
        this.animate__flipInX4 = "";
      }

      if (scrollTop > 3000) {
        this.animate__bounceIn = "animate__pulse";
      } else {
        this.animate__bounceIn = "";
      }

      if (scrollTop > 3400) {
        this.animate__flipInX5 = "animate__flipInX";
      } else {
        this.animate__flipInX5 = "";
      }

      if (scrollTop > 3800) {
        this.animate__lightSpeedInLeft3 = "animate__lightSpeedInLeft";
        this.animate__lightSpeedInRight3 = "animate__lightSpeedInRight";
      } else {
        this.animate__lightSpeedInLeft3 = "";
        this.animate__lightSpeedInRight3 = "";
      }

      if (scrollTop > 4200) {
        this.animate__flipInX6 = "animate__flipInX";
      } else {
        this.animate__flipInX6 = "";
      }

      if (scrollTop > 4800) {
        this.animate__bounceIn2 = "animate__bounceIn";
      } else {
        this.animate__bounceIn2 = "";
      }

      if (scrollTop > 5400) {
        this.animate__flipInX7 = "animate__flipInX";
      } else {
        this.animate__flipInX7 = "";
      }
    }
  }
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.clearfix {
  *zoom: 1;
}
.product-banner {
  background-image: linear-gradient(0deg, #fff 15%, #1d60ff 15%);
}
.banner {
  width: 1900px;
  margin: auto;
}
.product-bg {
  .message {
    width: 1900px;
    margin: auto;
  }
}
.bg1 {
  background: url("../../../assets/img/bg1.png") #fff;
  margin: -110px auto 0;
  padding-top: 230px;
  padding-bottom: 160px;
}
.product-bg1 {
  .message {
    h3 {
      font-size: 44px;
      font-weight: 600;
      color: #00041b;
      font-family: PingFangSC-Semibold, PingFang SC;
      margin-bottom: 58px;
    }
    ul {
      background: url("../../../assets/img/wenti.png") center no-repeat;
      background-size: 317px 368px;
      padding: 0 5%;
      li {
        font-size: 30px;
        line-height: 40px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        /* color: #6287ff; */
        color: #044eff;
        width: 280px;
        float: left;
        background: url("../../../assets/img/messageBg.png") top no-repeat;
        background-size: 100%;
        margin: 0 10% 40px 15%;
        height: 80px;
        padding: 27px 17px 59px 87px;
        text-align: left;
        transition: transform 0.1s !important;
        &:hover {
          -webkit-transform: translateY(-10px) !important;
          -moz-transform: translateY(-10px) !important;
          -ms-transform: translateY(-10px) !important;
          transform: translateY(-10px) !important;
        }
      }
    }
  }
}
.bg2 {
  background: url("../../../assets/img/bg2.png") #fff;
  background-size: 100%;
  height: 600px;
}
.product-bg2 {
  background-image: linear-gradient(
    to right,
    #dde7ff,
    #dde7ff,
    #044eff,
    #044eff
  );
  h3 {
    font-size: 44px;
    font-family: "PingFangSC-Semibold, PingFang SC";
    font-weight: 600;
    color: #ffffff;
    line-height: 48px;
    width: 630px;
    text-align: left;
    padding: 187px 0 0 820px;
    margin-bottom: 66px;
  }
  h4 {
    font-size: 24px;
    font-family: "PingFangSC-Regular, PingFang SC";
    margin-left: 820px;
    font-weight: 400;
    color: #a9c2ff;
    text-align: left;
    line-height: 32px;
    letter-spacing: 2px;
  }
}
.product-bg3 {
  margin: 120px auto 120px;
  h3 {
    font-size: 44px;
    font-family: "PingFangSC-Semibold, PingFang SC";
    font-weight: 600;
    color: #00041b;
    line-height: 48px;
    margin-bottom: 60px;
    letter-spacing: 4px;
  }
  ul {
    margin: 0 13.8%;
    position: relative;
    &::before {
      content: "+";
      position: absolute;
      left: 47.5%;
      top: 32%;
      font-size: 88px;
      font-family: "SourceHanSansCN-ExtraLight, SourceHanSansCN";
      font-weight: 200;
      color: #194fff;
    }
    li {
      padding: 70px 0;
      box-shadow: 0px 4px 26px 2px rgba(0, 0, 0, 0.06);
      border-radius: 16px;
      border: 1px solid #044eff;
      width: 520px;
      margin: 0 6%;
      float: left;
      transition: transform 0.1s !important;
      &:hover {
        -webkit-transform: translateY(-10px) !important;
        -moz-transform: translateY(-10px) !important;
        -ms-transform: translateY(-10px) !important;
        transform: translateY(-10px) !important;
      }
      div {
        font-size: 24px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #585c6a;
        line-height: 36px;
        margin-bottom: 40px;
      }
      button {
        cursor: pointer;
        width: 185px;
        height: 54px;
        background: #044eff;
        border-radius: 27px;
        font-size: 20px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
        line-height: 30px;
        border: 0;
      }
    }
    h5 {
      font-family: "PingFangSC-Semibold, PingFang SC";
      font-weight: 600;
      color: #044eff;
      line-height: 67px;
      font-size: 48px;
      line-height: 67px;
      margin-bottom: 20px;
    }
  }
}
.bg4 {
  background: url("../../../assets/img/bg4.png") #fff top no-repeat;
  background-size: 100%;
}
.product-bg4 {
  background-image: linear-gradient(
    0deg,
    #fff 13.5%,
    #1d60ff 13.5%,
    #1d60ff 90.5%,
    #fff 90.5%
  );
  h3 {
    font-size: 44px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    padding-top: 180px;
  }
  .content {
    position: relative;
    z-index: 99;
    width: 1019px;
    margin: 60px auto;
    background: #ffffff;
    box-shadow: 0px 20px 30px 6px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 80px 92px 100px;
    transition: transform 0.1s !important;
    h4 {
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #00041b;
      margin-bottom: 20px;
    }
    div {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 33px;
      letter-spacing: 2px;
      width: 720px;
      margin: auto;
      margin-bottom: 60px;
    }
    img {
      &:hover {
        -webkit-transform: translateY(-10px) !important;
        -moz-transform: translateY(-10px) !important;
        -ms-transform: translateY(-10px) !important;
        transform: translateY(-10px) !important;
      }
    }
  }
}
.bg5 {
  background: url("../../../assets/img/bg5.png") #f1f3f4 top no-repeat;
  background-size: 100%;
}
.product-bg5 {
  background: #f1f3f4;
  margin-top: -215px;
  h3 {
    font-size: 44px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #00041b;
    padding-top: 280px;
    line-height: 62px;
  }
  button {
    cursor: pointer;
    /* width: 185px; */
    width: 160px;
    /* height: 54px; */
    height: 45px;
    /* background: #000000; */
    background: #fff;
    border-radius: 27px;
    /* font-size: 20px; */
    font-size: 18px;
    font-family: "SourceHanSansCN-Regular, SourceHanSansCN";
    font-weight: 400;
    /* color: #ffffff; */
    color: #000000;
    line-height: 30px;
    margin: 35px 0 60px;
    transition: transform 0.1s !important;
    border: 1px solid #000000;
    &:hover {
      /* -webkit-transform: translateY(-10px) !important;
      -moz-transform: translateY(-10px) !important;
      -ms-transform: translateY(-10px) !important;
      transform: translateY(-10px) !important; */
      background: #000000 !important;
      border: 1px solid #fff;
      color: #ffffff !important;
    }
  }
  .content {
    width: 1210px;
    margin: auto;
  }
  ul {
    margin: 0 -15px;
    padding-bottom: 120px;
    li {
      width: 279px;
      float: left;
      margin: 0 15px;
      -webkit-transition: transform 0.2s ease !important;
      -moz-transition: transform 0.2s ease !important;
      -ms-transition: transform 0.2s ease !important;
      transition: transform 0.2s ease !important;
      &:hover {
        /* border: 1px solid #044eff; */
        -webkit-box-shadow: 0px 4px 26px 2px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0px 4px 26px 2px rgba(0, 0, 0, 0.16);
        -ms-box-shadow: 0px 4px 26px 2px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 4px 26px 2px rgba(0, 0, 0, 0.16);
        -webkit-transform: translateY(-10px) !important;
        -moz-transform: translateY(-10px) !important;
        -ms-transform: translateY(-10px) !important;
        transform: translateY(-10px) !important;
      }
      div {
        padding: 40px 30px;
        background: url("../../../assets/img/bg6.png") #ffffff bottom right
          no-repeat;
        background-size: 172px 219px;
        font-size: 24px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        line-height: 36px;
        letter-spacing: 2px;
        text-align: left;
        height: 240px;
      }
    }
    h5 {
      height: 91px;
      background: #454e6c;
      font-size: 28px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      line-height: 91px;
      letter-spacing: 2px;
    }
  }
}
.product-bg6 {
  padding-bottom: 160px;
  h3 {
    font-size: 44px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #00041b;
    padding-top: 120px;
    line-height: 62px;
  }
  button {
    cursor: pointer;
    /* width: 185px;
    height: 54px; */
    width: 160px;
    height: 45px;
    /* background: #000000; */
    background: #fff;
    border-radius: 27px;
    /* font-size: 20px; */
    font-size: 18px;
    font-family: "SourceHanSansCN-Regular, SourceHanSansCN";
    font-weight: 400;
    /* color: #ffffff; */
    color: #000000;
    line-height: 30px;
    margin: 35px 0 60px;
    transition: transform 0.1s !important;
    border: 1px solid #000000;
    &:hover {
      /* -webkit-transform: translateY(-10px) !important;
      -moz-transform: translateY(-10px) !important;
      -ms-transform: translateY(-10px) !important;
      transform: translateY(-10px) !important; */
      background: #000000 !important;
      color: #ffffff !important;
    }
  }
}
.bg7 {
  background: url("../../../assets/img/bg7.png") #fff;
  background-size: 100%;
  height: 600px;
}
.product-bg7 {
  background-image: linear-gradient(
    to right,
    #dde7ff,
    #dde7ff,
    #044eff,
    #044eff
  );
  h3 {
    font-size: 44px;
    font-family: "PingFangSC-Semibold, PingFang SC";
    font-weight: 600;
    color: #ffffff;
    line-height: 48px;
    width: 630px;
    text-align: left;
    padding: 187px 0 0 820px;
    margin-bottom: 66px;
  }
  h4 {
    font-size: 24px;
    font-family: "PingFangSC-Regular, PingFang SC";
    margin-left: 820px;
    font-weight: 400;
    color: #a9c2ff;
    text-align: left;
    line-height: 32px;
    letter-spacing: 2px;
  }
}
.bg8 {
  background: url("../../../assets/img/bg8.png") #fff;
  background-size: 100%;
}
.product-bg8 {
  padding: 100px 0;
  h4 {
    font-size: 44px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #00041b;
    line-height: 62px;
    letter-spacing: 4px;
    margin-bottom: 10px;
  }
  h5 {
    font-size: 28px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #585c6a;
    line-height: 42px;
    margin-bottom: 40px;
  }
  div {
    font-size: 28px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #044eff;
    line-height: 50px;
    strong {
      font-weight: 800;
      font-size: 44px;
    }
    margin-bottom: 10px;
  }
  button {
    /* width: 170px;
    height: 46px; */
    width: 160px;
    height: 45px;
    /* background: #044eff; */
    background: #fff;
    border-radius: 27px;
    /* font-size: 20px; */
    font-size: 18px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    /* color: #ffffff; */
    color: #044eff;
    line-height: 30px;
    letter-spacing: 2px;
    border: 0;
    cursor: pointer;
    transition: transform 0.1s !important;
    border: 1px solid #044eff;
    &:hover {
      /* -webkit-transform: translateY(10px) !important;
      -moz-transform: translateY(10px) !important;
      -ms-transform: translateY(10px) !important;
      transform: translateY(10px) !important; */
      background: #044eff !important;
      color: #fff !important;
    }
  }
}
</style>
