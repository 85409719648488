<template>
  <div class="con-wrapper">
    <div class="pc">
      <header-con :index="0" class="header-barBg" />
      <div class="product-detail">
        <div class="product-single roduct-single-yzg">
          <div class="f-banner floor">
            <div class="main-wrap ban-box">
              <h1 class="ban-box-h1">悦资管</h1>
              <p class="tit-desc">
                悦云物业资管平台是物业企业管理资产与服务的专业平台，让物业资产不再难管理，让服务品质不再难监管，让数据为企业赋新能！
              </p>
              <div class="banner-btn pr">
                <router-link
                  :to="{ path: '/pc/contact' }"
                  class="btn-ask btn-ask-yzg"
                >
                  免费试用
                </router-link>
                <!-- <router-link
                  :to="{ path: '/pc/proPricePackage' }"
                  class="btn-ask btn-price"
                >
                  查看价格
                </router-link> -->
              </div>
            </div>
          </div>
        </div>
        <div class="product-nav abs-lt w100">
          <div
            :class="topNavDistance > 490 ? 'fixed' : ''"
            class="anchor-box abs-lt w100"
          >
            <ul class="main-wrap clearfloat">
              <li
                v-for="(item, index) in allMenuList"
                :key="item.id"
                :class="{ active: active == index }"
                @click="scrollTo(index)"
              >
                <span class="abs-mt">{{ item.value }}</span>
                <p class="vh">{{ item.value }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="product-scroll">
          <property-charges
            :functionPro="functionList"
            class="scroll-con funBusP funBusP-yzg"
          />
          <quality-online
            :functionPro="functionList1"
            class="scroll-con funBusP funBusP-yzg"
          />
          <community-business
            :functionPro="functionList2"
            class="scroll-con funBusP funBusP-yzg"
          />
          <intelligent-iot
            :functionPro="functionList3"
            class="scroll-con funBusP funBusP-yzg"
          />
          <staff-app
            :functionPro="functionList4"
            class="scroll-con funBusP funBusP-yzg"
          />
          <owner-app
            :functionPro="functionList5"
            class="scroll-con funBusP funBusP-yzg"
          />
          <!-- <product-operate class="scroll-con" /> -->
        </div>
      </div>
      <div class="fooCon">
        <footer-conTop />
        <footer-con />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerCon"; // 头部
import FooterConTop from "@/components/footerConTop"; // 底部免费试用
import FooterCon from "@/components/footerCon"; // 底部
import PropertyCharges from "@/components/products/propertyCharges"; // 物业收费
import QualityOnline from "@/components/products/qualityOnline"; // 品质在线
import CommunityBusiness from "@/components/products/communityBusiness"; // 社区商业
import IntelligentIot from "@/components/products/intelligentIot"; // 智慧物联
import StaffApp from "@/components/products/staffApp"; // 员工app
import OwnerApp from "@/components/products/ownerApp"; // 业主app
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字

export default {
  name: "proAssetManagement",
  components: {
    HeaderCon,
    FooterCon,
    FooterConTop,
    PropertyCharges,
    QualityOnline,
    CommunityBusiness,
    IntelligentIot,
    StaffApp,
    OwnerApp
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      // 二级导航
      allMenuList: [
        {
          value: "物业收费",
          id: "1"
        },
        {
          value: "品质在线",
          id: "2"
        },
        // {
        //   value: "业务场景",
        //   id: "3"
        // },
        {
          value: "社区商业",
          id: "3"
        },
        {
          value: "智慧物联",
          id: "4"
        },
        {
          value: "员工APP",
          id: "5"
        },
        {
          value: "业主APP",
          id: "6"
        }
      ],
      // 物业收费内容
      functionList: [
        {
          id: "1",
          tit: "收费管理",
          con:
            "费项按需设置，自动生成账单，支持优惠、减免、退款、撤销收费、账单拆分等常用操作",
          src: require("../../../assets/img/proAsset/proAsset_icon01.png")
        },
        {
          id: "2",
          tit: "收付实现制和权责发生制双轨并行",
          con:
            "收付实现制帮助运营人员管理台，权责发生制帮助财务人员计算每月收入账单",
          src: require("../../../assets/img/proAsset/proAsset_icon02.png")
        },
        {
          id: "3",
          tit: "收缴率统计",
          con: "账单收缴落实到月，责任到人，多维提升物业",
          src: require("../../../assets/img/proAsset/proAsset_icon03.png")
        },
        {
          id: "4",
          tit: "多渠道收费",
          con:
            "可使用APP、支付宝、微信支付、智能移动POS、B端收费等多种渠道缴费，减少人工录入",
          src: require("../../../assets/img/proAsset/proAsset_icon04.png")
        },
        {
          id: "5",
          tit: "装修管理",
          con: "装修申请，图纸审批，工程验收一键申请，在线审批",
          src: require("../../../assets/img/proAsset/proAsset_icon05.png")
        },
        {
          id: "6",
          tit: "消息中心高效触达",
          con:
            "灵活配置具体事件参数，定义触发周期、触发具体条件，意见分析消息触达情况",
          src: require("../../../assets/img/proAsset/proAsset_icon06.png")
        }
      ],
      // 品质在线内容
      functionList1: [
        {
          id: "1",
          tit: "数字化绿化保洁",
          con: "助力物业管理降本增效，真实数据呈现，避免过程失控",
          src: require("../../../assets/img/proAsset/proAsset_icon07.png")
        },
        {
          id: "2",
          tit: "精细化品质管理",
          con: "扫码留痕，拒绝工作无迹可循",
          src: require("../../../assets/img/proAsset/proAsset_icon08.png")
        },
        {
          id: "3",
          tit: "秩序管理品质在线",
          con: "调度中心派遣工单，线上接单，在线评价",
          src: require("../../../assets/img/proAsset/proAsset_icon09.png")
        },
        {
          id: "4",
          tit: "设备在线",
          con: "在线审批，确保交付，科学管理，提升服务品质",
          src: require("../../../assets/img/proAsset/proAsset_icon10.png")
        },
        {
          id: "5",
          tit: "报事报修",
          con: "线上接单，实时派单，在线评价，为业主提供极致的物业服务体验",
          src: require("../../../assets/img/proAsset/proAsset_icon11.png")
        },
        {
          id: "6",
          tit: "调度中心",
          con: "在线派单接单，远程督办，工单报表实时分析工单处理情况",
          src: require("../../../assets/img/proAsset/proAsset_icon12.png")
        }
      ],
      // 品质在线内容
      functionList2: [
        {
          id: "1",
          tit: "社区云铺",
          con: "挖掘社区流量价值，让生意主足不出户经营买卖",
          src: require("../../../assets/img/proAsset/proAsset_icon13.png")
        },
        {
          id: "2",
          tit: "社区团购",
          con: "业主流量变现，整合资源，为多经收入另辟蹊径",
          src: require("../../../assets/img/proAsset/proAsset_icon14.png")
        },
        {
          id: "3",
          tit: "O&O大运营",
          con: "行业一流团队，打造专业的社区商业运营解决方案",
          src: require("../../../assets/img/proAsset/proAsset_icon15.png")
        },
        {
          id: "4",
          tit: "营销玩法",
          con: "砍价，抽奖，积分商城，多维度玩转社区私域",
          src: require("../../../assets/img/proAsset/proAsset_icon16.png")
        }
      ],
      // 智慧物联内容
      functionList3: [
        {
          id: "1",
          tit: "智能水表",
          con: "",
          src: require("../../../assets/img/proAsset/proAsset_icon17.png")
        },
        {
          id: "2",
          tit: "智能监控",
          con: "",
          src: require("../../../assets/img/proAsset/proAsset_icon18.png")
        },
        {
          id: "3",
          tit: "智能门禁",
          con: "",
          src: require("../../../assets/img/proAsset/proAsset_icon19.png")
        },
        {
          id: "4",
          tit: "智能停车",
          con: "",
          src: require("../../../assets/img/proAsset/proAsset_icon20.png")
        },
        {
          id: "5",
          tit: "智能梯栋",
          con: "",
          src: require("../../../assets/img/proAsset/proAsset_icon21.png")
        },
        {
          id: "6",
          tit: "智能充电桩",
          con: "",
          src: require("../../../assets/img/proAsset/proAsset_icon22.png")
        },
        {
          id: "7",
          tit: "智能家居",
          con: "",
          src: require("../../../assets/img/proAsset/proAsset_icon23.png")
        },
        {
          id: "8",
          tit: "智能消防",
          con: "",
          src: require("../../../assets/img/proAsset/proAsset_icon24.png")
        }
      ],
      // 员工APP
      functionList4: [
        {
          id: "1",
          tit: "报修管理",
          con: "在线派单抢单 移动完成整个报修处理流程",
          src: require("../../../assets/img/proAsset/proAsset_icon25.png")
        },
        {
          id: "2",
          tit: "移动收费",
          con: "物业人员移动收费 支持多种收费方式",
          src: require("../../../assets/img/proAsset/proAsset_icon26.png")
        },
        {
          id: "3",
          tit: "巡更巡检",
          con: "支持社区日常巡查、设备巡检。自动生成任务，记录巡查结果",
          src: require("../../../assets/img/proAsset/proAsset_icon27.png")
        },
        {
          id: "4",
          tit: "代办事项",
          con:
            "根据任务计划生成代办事项清单，任务开始前消息提醒，辅助员工日常工作的有序开展",
          src: require("../../../assets/img/proAsset/proAsset_icon28.png")
        },
        {
          id: "5",
          tit: "离线抄表",
          con: "支付在线抄表、离线抄表，抄表记录的查看及修改",
          src: require("../../../assets/img/proAsset/proAsset_icon29.png")
        },
        {
          id: "6",
          tit: "消息提醒",
          con: "员工代办任务、任务处理结果在线消息提醒",
          src: require("../../../assets/img/proAsset/proAsset_icon30.png")
        }
      ],
      // 业主APP
      functionList5: [
        {
          id: "1",
          tit: "一键报修",
          con: "在线预约报修，物业在您方便的时间上门处理",
          src: require("../../../assets/img/proAsset/proAsset_icon31.png")
        },
        {
          id: "2",
          tit: "移动收费",
          con: "在线缴纳物业管理费，水电费",
          src: require("../../../assets/img/proAsset/proAsset_icon32.png")
        },
        {
          id: "3",
          tit: "社区安防",
          con: "门禁设备、门禁权限、人脸数据在线管理，保存人员出入门禁记录",
          src: require("../../../assets/img/proAsset/proAsset_icon33.png")
        },
        {
          id: "4",
          tit: "社区生活",
          con: "社区通知、活动线上查看 停水停电再也不会手忙脚乱了",
          src: require("../../../assets/img/proAsset/proAsset_icon34.png")
        },
        {
          id: "5",
          tit: "社区商城",
          con: "接驳周边商家，提供优质的商城服务，足不出户解决生活所需",
          src: require("../../../assets/img/proAsset/proAsset_icon35.png")
        },
        {
          id: "6",
          tit: "邻里关系",
          con: "发布邻里活动，家居闲置",
          src: require("../../../assets/img/proAsset/proAsset_icon36.png")
        }
      ],
      topNavDistance: 0,
      active: 0,
      from: this.$route.query.from
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleNavScroll);
    window.addEventListener("scroll", this.onScroll, false);
    if (this.from === "homeIndex1") {
      this.scrollTo(0);
    } else if (this.from === "homeIndex2") {
      this.scrollTo(1);
    } else if (this.from === "homeIndex3") {
      this.scrollTo(2);
    } else if (this.from === "homeIndex4") {
      this.scrollTo(3);
    } else if (this.from === "homeIndex5") {
      this.scrollTo(4);
    } else if (this.from === "homeIndex6") {
      this.scrollTo(5);
    }
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.handleNavScroll);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    handleNavScroll() {
      // 二级导航滚动到指定位置固定
      this.topNavDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    // 二级导航+内容联动滚动监听
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(
        ".product-scroll .scroll-con"
      );
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });
      // 获取当前文档流的 scrollTop
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop += 150;
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n;
        }
      }
      this.active = navIndex;
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      var targetOffsetTop = document.querySelector(
        `.product-scroll .scroll-con:nth-child(${index + 1})`
      ).offsetTop;
      targetOffsetTop -= 150;
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
      const STEP = 50;
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.funBusP .ul-list li {
  // height: 260px !important;
}
</style>
