<template>
  <div class="con-wrapper">
    <div class="wap">
      <header-con />
      <div class="m-main">
        <div class="banner-single">
          <div class="banner"></div>
          <div class="banner-con">
            <h1>智慧物业</h1>
            <span></span>
            <p class="tit-desc">
              建立在精细化管理中的智能服务，实现物业坪效增值，打造智慧小区
            </p>
            <div class="banner-btn">
              <router-link :to="{ path: '/wap/contact' }" class="btn-ask"
                >免费试用</router-link
              >
            </div>
          </div>
        </div>
        <div :class="topNavDistance > 390 ? 'fixed' : ''" class="scroll-nav">
          <div class="scroll-navCon">
            <ul class="main-wrap flex flex-middle">
              <li
                v-for="(item, index) in allMenuList"
                :key="item.id"
                :class="{ active: active == index }"
                @click="scrollTo(index)"
              >
                {{ item.value }}
                <span></span>
              </li>
            </ul>
          </div>
        </div>
        <div class="product-scroll">
          <div class="main-single scroll-con">
            <div>
              <div class="con-box">
                <div class="tab-cont">
                  <div class="tab-tit">
                    <h2>一站式线上服务，打造数字化空间和云上服务</h2>
                    <p>
                      智慧物业解决方案将移动互联网及物联网统一结合，探索服务体系在互联网和社区服务之间的深度融合，实现网络化、精细化、场景化的新模式
                    </p>
                  </div>
                  <div class="tab-main">
                    <img
                      src="../../../assets/img/wap/section_solve_pic1.png"
                      class="img-solutionA"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="main-single scroll-con" style="padding-bottom:1.2rem">
            <div>
              <div class="tit-box">
                <h2>方案优势</h2>
              </div>
              <div class="con-box">
                <div class="tab-cont">
                  <ul class="ulList">
                    <li>
                      <div class="normal-box flex">
                        <img src="../../../assets/img/proA_icon7.png" />
                        <div class="normal-boxR">
                          <h4 title="快速部署，系统上线快">
                            快速部署，系统上线快
                          </h4>
                          <p>
                            SaaS服务模式，无需硬件网络设备、无需专业IT人员，系统上线快，极大地降低物业企业向数字化转型的投入
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="normal-box flex">
                        <img src="../../../assets/img/proA_icon8.png" />
                        <div class="normal-boxR">
                          <h4 title="量身定制，支持定制开发">
                            量身定制，支持定制开发
                          </h4>
                          <p>
                            支持标准化产品功能外的特殊需求定制开发，可独立部署为企业量身定制解决方案，助力物业企业成功完成数字化转型
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="normal-box flex">
                        <img src="../../../assets/img/proA_icon9.png" />
                        <div class="normal-boxR">
                          <h4 title="全新生态，引领增值新风潮">
                            全新生态，引领增值新风潮
                          </h4>
                          <p>
                            整合线上线下资源，进行商业模式创新，以存量带动增量，通过发展新型增值服务，为企业带来新的收入爆点
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fooConWap">
        <footer-conTopW />
        <footer-conW />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerConWap"; // 头部
import FooterConTopW from "@/components/footerConTopW"; // 底部免费试用
import FooterConW from "@/components/footerConWap"; // 底部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
export default {
  name: "solutions",
  components: {
    HeaderCon,
    FooterConTopW,
    FooterConW
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      topNavDistance: 0,
      active: 0,
      allMenuList: [
        {
          value: "方案构架",
          id: "1"
        },
        {
          value: "方案优势",
          id: "2"
        }
      ],
      noScroll: false
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleNavScroll);
    window.addEventListener("scroll", this.onScroll, false);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.handleNavScroll);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    handleNavScroll() {
      // 二级导航滚动到指定位置固定
      this.topNavDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    // 二级导航+内容联动滚动监听
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(".scroll-con");
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });
      // 获取当前文档流的 scrollTop
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop += 52 * window.devicePixelRatio;
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (
          scrollTop >=
          (this.noScroll ? (offsetTopArr[n] -= 60) : offsetTopArr[n])
        ) {
          navIndex = n;
        }
      }
      this.active = navIndex;
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      var targetOffsetTop = document.querySelector(
        `.product-scroll .scroll-con:nth-child(${index + 1})`
      ).offsetTop;
      targetOffsetTop -= 52 * window.devicePixelRatio;
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
      const STEP = 50;
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        if (this.topNavDistance < 390) {
          targetOffsetTop -= 60;
          this.noScroll = true;
        } else {
          this.noScroll = false;
        }
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    }
  }
};
</script>
