<template>
  <div class="con-wrapper">
    <div class="wap">
      <header-con />
      <div class="m-main">
        <div class="banner-single">
          <div class="banner"></div>
          <div class="banner-con">
            <h1>企业官网</h1>
            <span></span>
            <p class="tit-desc">
              全方位展示企业的产品、方案和形象，输出SEO优化和域名注册备案全套服务。
            </p>
            <div class="banner-btn">
              <router-link :to="{ path: '/wap/contact' }" class="btn-ask"
                >免费试用</router-link
              >
            </div>
          </div>
        </div>
        <div :class="topNavDistance > 390 ? 'fixed' : ''" class="scroll-nav">
          <div class="scroll-navCon">
            <ul class="main-wrap flex flex-middle">
              <li
                v-for="(item, index) in allMenuList"
                :key="item.id"
                :class="{ active: active == index }"
                @click="scrollTo(index)"
              >
                {{ item.value }}
                <span></span>
              </li>
            </ul>
          </div>
        </div>
        <div class="product-scroll">
          <product-advantageWap :advantage="advantageList" class="scroll-con" />
          <product-function :functionPro="functionList" class="scroll-con" />
        </div>
      </div>
      <div class="fooConWap">
        <footer-conTopW />
        <footer-conW />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerConWap"; // 头部
import ProductAdvantageWap from "@/components/products/productAdvantageWap"; // 产品优势
import ProductFunction from "@/components/products/productFunctionWap"; // 产品功能
import FooterConTopW from "@/components/footerConTopW"; // 底部免费试用
import FooterConW from "@/components/footerConWap"; // 底部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
export default {
  name: "proWebsite",
  components: {
    HeaderCon,
    FooterConTopW,
    FooterConW,
    ProductAdvantageWap,
    ProductFunction
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      topNavDistance: 0,
      active: 0,
      allMenuList: [
        {
          value: "产品优势",
          id: "1"
        },
        {
          value: "产品功能",
          id: "2"
        }
      ],
      //产品优势内容
      advantageList: [
        {
          id: "1",
          tit: "资深定制化设计",
          titCon: "深度调研客户行业属性，资深设计师输出客户满意的设计方案",
          src: require("../../../assets/img/funIcon/proFun_icon4.png")
        },
        {
          id: "2",
          tit: "SEO优化服务",
          titCon: "提供SEO的建站优化方案，实现网站的收录和访问提升",
          src: require("../../../assets/img/funIcon/proFun_icon5.png")
        },
        {
          id: "3",
          tit: "网站备案服务",
          titCon: "提供域名注册、网站备案一站式服务",
          src: require("../../../assets/img/funIcon/proFun_icon6.png")
        }
      ],
      // 产品功能内容
      functionList: [
        {
          id: "1",
          tit: "产品中心",
          con: "支持从产品特色、产品功能、业务场景、合作案例等多维度展示产品价值",
          src: require("../../../assets/img/funIcon1/proFunT_icon6.png")
        },
        {
          id: "2",
          tit: "方案中心",
          con: "从行业蓝图、方案优势等维度阐释方案的商业价值",
          src: require("../../../assets/img/funIcon1/proFunT_icon7.png")
        },
        {
          id: "3",
          tit: "合作案例",
          con: "展示企业的各类合作方，呈现被信赖的资本",
          src: require("../../../assets/img/funIcon1/proFunT_icon8.png")
        },
        {
          id: "4",
          tit: "企业介绍",
          con: "支持从企业介绍、发展历程、企业愿景、企业文化、企业荣誉等维度展示企业价值",
          src: require("../../../assets/img/funIcon1/proFunT_icon9.png")
        },
        {
          id: "5",
          tit: "新闻动态",
          con: "展示企业的最新资讯，支持多渠道分享传播",
          src: require("../../../assets/img/funIcon1/proFunT_icon10.png")
        }
      ],
      loading: true,
      noScroll: false
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleNavScroll);
    window.addEventListener("scroll", this.onScroll, false);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.handleNavScroll);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    handleNavScroll() {
      // 二级导航滚动到指定位置固定
      this.topNavDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    // 二级导航+内容联动滚动监听
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(".scroll-con");
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });
      // 获取当前文档流的 scrollTop
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop += 52 * window.devicePixelRatio;
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (
          scrollTop >=
          (this.noScroll ? (offsetTopArr[n] -= 60) : offsetTopArr[n])
        ) {
          navIndex = n;
        }
      }
      if (this.active == 3) {
        this.active = 3;
      } else {
        this.active = navIndex;
      }
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      if (index == 3) {
        this.active = 3;
      } else {
        this.active = index;
      }
      var targetOffsetTop = document.querySelector(
        `.product-scroll .scroll-con:nth-child(${index + 1})`
      ).offsetTop;
      targetOffsetTop -= 52 * window.devicePixelRatio;
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
      const STEP = 50;
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        if (this.topNavDistance < 390) {
          targetOffsetTop -= 60;
          this.noScroll = true;
        } else {
          this.noScroll = false;
        }
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    }
  }
};
</script>
