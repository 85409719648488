<template>
  <div class="con-wrapper">
    <div class="pc">
      <header-con />
      <div class="news">
        <div class="news-box" style="padding: 142px 0 0">
          <div class="main-wrap main-wrapNews clearfloat">
            <div class="news-det-left">
              <h1 class="news-det-tit">
                日曝光量高达130000+！这个商场如何将线上流量转化成线下客流的？
              </h1>
              <div class="news-det-source">
                <div>
                  <span class="source">悦云数字科技</span>
                  <span class="time">2018-09-07</span>
                </div>
              </div>
              <div class="news-det-con">
                <div class="news-detBox">
                  <div class="news-det-original">
                    <section>
                      <img src="../../../assets/img/newImg3.gif" />
                      <p>株洲大汉·悦中心</p>
                      悦商圈在株洲·大汉悦中心实施以来，首月单日曝光量峰值高达130000+，新注册会员数已突破10000+，为线下新增订单更是逼近5000单，为消费者带来便利的同时，也为悦中心的商家也带来了更多的客流。
                      <br />
                      <br />
                      <img src="../../../assets/img/newImg13.jpg" />
                      <p>悦商圈上线后“悦中心”微信指数 ⬆</p>
                    </section>
                    <section>
                      <img src="../../../assets/img/newImg14A.jpg" />
                      <p>悦商圈C端-悦购数据后台 ⬆</p>
                      在没有付费做推广的情况下，商场如何利用好存量数字化资产，盘活和拉活场内自有流量？看看悦商圈是怎么做的：<br />
                    </section>
                    <br />
                    <section>
                      <img src="../../../assets/img/newImg14.jpg" />
                      “悦商圈”是基于移动互联网，通过大数据共享、多平台联动、智能设备、物联网等技术手段，为商管公司实现从管理到运营全链信息化，让商管工作有迹可寻，提高工作效率，更为运营方、商家、消费者搭建一个线上的、全流程、多方位的智慧商圈大平台，重构传统实体商业人-货-场的关系，完成以实体商业为中心的一整套智慧商圈管理系统及解决方案。
                    </section>
                    <br />
                    <br />
                    <section>
                      <img src="../../../assets/img/newImg15.jpg" />
                      无论是新客、老客还是流失客，都属于商场的存量数据化资产，而想要激活这个存量市场，首先要具备的就是观测存量数据的能力。
                    </section>
                    <br />
                    <br />
                    <section>
                      <img src="../../../assets/img/newImg16.gif" />
                      存量激活，就是要盘活并拉动场内自有流量，激活有几种方式：
                      <br />
                      1.吸引新客复购，将新客转化为老客<br />
                      2.利用场内资源带动场内分发 3.通过营销活动方式召回流失客<br /><br />
                      为了更好的实现存量激活，悦商圈C端主打活动运营，为商场打造小程序入口新玩法。
                    </section>
                    <br />
                    <br />
                    <section>
                      <img src="../../../assets/img/newImg17.gif" />
                      <p>悦商圈C端-悦购前端⬆</p>
                      顾客通过悦购小程序，可以快速获取商场、商户和品牌的优惠卡券，它是赋能门店的工具，是连通品牌与商场合作的平台，无缝连接营销广告，也是数据积累与phoneID和OpenID扩充的有效渠道。
                    </section>
                    <section>
                      <img src="../../../assets/img/newImg16.jpg" />
                      对于C端顾客和B端商户来说，小程序是以最轻量化的方式，快速构成消费者与商家连接互动的方式。如今，消费者越来越怠于各种App
                      的下载和繁复的注册流程。小程序应对场景化的出现，是极易被消费者接受且用户体验优秀的小工具。
                    </section>
                    <br />
                    <br />
                    <section>
                      通过多种入口进入，如现场扫码、微信下拉菜单、附近小程序、微信分享、公众号推送、朋友圈推广等等，曝光渠道丰富且获取信息和使用快速，即用即走。
                    </section>
                    <br />
                    <br />
                    <section>
                      商户作为商场的重要组成部分，是与商场“一荣俱荣”合作共赢的关系。商场流量增加，分发能力强对于商铺的销售转化有极大价值。
                    </section>
                    <section>
                      <img src="../../../assets/img/newImg18.gif" />
                      <p>悦商圈B端-悦商惠前端展示⬆</p>
                    </section>
                    <br />
                    <br />
                    <section>
                      商户端使用的小程序。在小程序中数据赋能商户，提供商铺优惠券管理、停车券管理、优惠券核销等功能模块。
                    </section>
                    <br />
                    <br />
                    <section>
                      一方面让商户有了在商场中价值度的数据感知，促进自身的运营提升监测。
                    </section>
                    <br />
                    <br />
                    <section>
                      一方面开放了商户自主制券的通路，让商户可以针对自身的节庆促销等发布活动优惠信息，从而增加了曝光渠道，带来更多流量。
                    </section>
                    <br />
                    <br />
                    <section>
                      运营是商场成功与否的关键，而这个关键来源于各项运营数据的采集与分析形成大数据中心，支持商业运营和决策让管理者随时随地能了解到商场的经营情况。
                    </section>
                    <br />
                    <br />
                    <section>
                      <img src="../../../assets/img/newImg19.gif" />
                      <p>悦商圈M端-商管公司大数据后台⬆</p>
                      商场大数据在商业运营中大有可为，主要有如下两方面作用：<br />
                      1.了解：了解自己的产品，了解自己的用户。所谓知己知彼，百战不殆！<br />
                      2.提升：提升决策准确率和管理效率。
                    </section>
                    <br />
                    <br />
                    <section>
                      基于顾客需求的变化，及时快速做出科学的经营决策和营销方案，提供差异化的消费环境，提升顾客的购物体验，已经成为零售业营销和运营成功与否的关键。
                    </section>
                    <br />
                    <br />
                    <section>
                      株洲大汉·悦中心818JOJO街一周年活动期间，悦购小程序应用落地，配合活动上的近20家餐饮品牌，发布5-50元不等的代金券。
                    </section>
                    <section>
                      <img src="../../../assets/img/newImg15A.jpg" />
                      活动期间，小程序用户新增3302人，代金券购买量突破2000单，活动当天单店最高营业额突破8万，单店核销率高达86%，客流量翻倍。
                    </section>
                    <br />
                    <br />
                    <section>
                      <img src="../../../assets/img/newImg16A.jpg" />
                      基于数据的活动运营工具，能够更出色的刺激消费者逛店数量提升，驻场时间延长，助力商铺客流提升，场内商户分发效率增长，助商场加速顾客沉淀，降低运营成本。
                    </section>
                    <br />
                    <br />
                    <section>
                      悦商圈重新连接人、铺、场，构建新型商业形态，诞生的新型购物方式，打造周边3公里范围智慧商圈，倡导大运营理念，实现大数据、大会员、大平台的新的商业运营模式，由传统商场运营转变为大商圈智慧运营。
                    </section>
                    <br />
                    <br />
                    <section>
                      小程序与大数据的紧密结合，助力构建数字化场景流量平台，为商业流量带来全新的数字活力。
                    </section>
                  </div>
                </div>
                <div class="news-det-page">
                  <!-- <span>
                    下一篇：
                    <a>悦云科技成功跻身中国互联网公司50强</a>
                  </span> -->
                  <router-link :to="{ path: '/pc/newsDetails' }">
                    <span>
                      上一篇：
                      <a>
                        传统商业地产靠什么赢得未来？商圈大运营模式提前到来！
                      </a>
                    </span>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="news-det-right">
              <div class="rt-list">
                <h1>最新新闻</h1>
                <ul>
                  <li>
                    <router-link :to="{ path: '/pc/newsDetailsSec' }">
                      <a
                        >从“开发商售后”到国家“软基建”，大物业对数字化管理需求日渐紧迫</a
                      >
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ path: '/pc/newsDetails' }">
                      <a>传统商业地产靠什么赢得未来？商圈大运营模式提前到来！</a>
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ path: '/pc/newsDetailsThi' }">
                      <a
                        >日曝光量高达130000+！这个商场如何将线上流量转化成线下客流的？</a
                      >
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="news-d-wx">
                <dl class="clearfloat">
                  <dt>
                    <p class="wx-p01">扫码关注官方微信</p>
                    <p class="wx-p02">及时获取更多活动信息</p>
                  </dt>
                  <dd>
                    <img src="../../../assets/img/ewmA.png" />
                    <p>悦云科技</p>
                  </dd>
                </dl>
              </div>
              <div class="news-det-contact">
                <h3>
                  <a>
                    <router-link :to="{ path: '/pc/contact' }">
                      <img
                        class="img-arr01"
                        src="../../../assets/img/phone.png"
                      />联系我们
                      <img
                        class="img-arr02"
                        src="../../../assets/img/news_right.png"
                      />
                    </router-link>
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fooCon">
        <footer-conTop />
        <footer-con />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerCon"; // 头部
import FooterConTop from "@/components/footerConTop"; // 底部免费试用
import FooterCon from "@/components/footerCon"; // 底部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
export default {
  name: "news",
  components: {
    HeaderCon,
    FooterCon,
    FooterConTop
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      topNavDistance: 0,
      active: 0
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleNavScroll);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.handleNavScroll);
  },
  methods: {
    handleNavScroll() {
      // 二级导航滚动到指定位置固定
      this.topNavDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    }
  }
};
</script>
