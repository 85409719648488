<template>
  <div :class="topDistance > 70 ? 's-mask' : ''" class="header-bar">
    <div
      :style="{ background: type == '0' ? '#376BEB' : '#fff' }"
      class="main-wrap main-cont pr"
    >
      <a class="logo">
        <router-link :to="{ path: '/' }">
          <!-- <router-link :to="{ path: '/pc/home' }"> -->
          <img
            :src="
              type == '0'
                ? require('../assets/img/yueyunkeji_LOGO.png')
                : require('../assets/img/logo.png')
            "
            class="abs-lm"
            style="width:160px;height:30px;"
          />
        </router-link>
      </a>
      <!-- <a
        :href="saasUrl"
        target="_blank"
        :class="
          type == '0' ? 'btn-ask-login' : 'btn-ask-login btn-ask-login-top'
        "
        >登录</a
      > -->
      <router-link
        :to="{ path: '/pc/contact' }"
        :class="type == '0' ? 'btn-ask' : 'btn-ask btn-ask-top'"
        >免费试用</router-link
      >
      <ul :class="type == '0' ? 'nav-list' : 'nav-list nav-list-top'">
        <li :class="index == 4 ? 'nav-item selected' : 'nav-item'">
          <a>
            <router-link :to="{ path: '/' }">
              首页
            </router-link>
          </a>
        </li>
        <li
          :class="index == 0 ? 'nav-item selected' : 'nav-item'"
          @mouseover="showNav(1)"
          @mouseout="showNav(0)"
        >
          <a class="nav-drop">
            <span :class="active ? 'active' : ''">
              <span class="arrow-d">产品与服务</span>
            </span>
          </a>
        </li>
        <li
          :class="index == 1 ? 'nav-item selected' : 'nav-item'"
          @mouseover="showNavSolu(1)"
          @mouseout="showNavSolu(0)"
        >
          <a class="nav-drop">
            <span :class="activeSolu ? 'activeSolu' : ''">
              <span class="arrow-d">解决方案</span>
            </span>
          </a>
          <ul
            :class="showTagSolu == true ? 'open' : ''"
            @mouseover="showNavSolu(1)"
            @mouseout="showNavSolu(0)"
            class="abs-lt more-ul more-ulSolu"
          >
            <!-- <h2>
              <router-link :to="{ path: '/pc/solutions' }">
                企业数字化
              </router-link>
            </h2> -->
            <li>
              <a>
                <router-link :to="{ path: '/pc/solutionsProperty' }">
                  智慧物业
                </router-link>
              </a>
            </li>
            <li>
              <a>
                <router-link :to="{ path: '/pc/wisdomBusiness' }">
                  智慧商业
                </router-link>
              </a>
            </li>
            <li>
              <a>
                <router-link :to="{ path: '/pc/solutions' }">
                  企业数字化
                </router-link>
              </a>
            </li>
          </ul>
        </li>
        <li :class="index == 2 ? 'nav-item selected' : 'nav-item'">
          <a class="">
            <span>
              <div @click="toIndex">
                合作案例
              </div>
            </span>
          </a>
        </li>
        <li
          :class="index == 3 ? 'nav-item selected' : 'nav-item'"
          @mouseover="showNavAbout(1)"
          @mouseout="showNavAbout(0)"
        >
          <a>
            <span :class="activeAbout ? 'activeAbout' : ''">
              <span class="arrow-d">关于悦云</span>
            </span>
          </a>
          <ul
            :class="showTagAbout == true ? 'open' : ''"
            @mouseover="showNavAbout(1)"
            @mouseout="showNavAbout(0)"
            class="abs-lt more-ul more-ulAbout"
          >
            <!-- <h2>
              <router-link :to="{ path: '/pc/about' }">
                关于悦云
              </router-link>
            </h2> -->
            <li>
              <a>
                <router-link :to="{ path: '/pc/about' }">
                  关于悦云
                </router-link>
              </a>
            </li>
            <li>
              <router-link :to="{ path: '/pc/home' }">
                产品概述
              </router-link>
            </li>
            <li>
              <a>
                <router-link :to="{ path: '/pc/news' }">悦云动态</router-link>
              </a>
            </li>
            <li>
              <a>
                <router-link :to="{ path: '/pc/contact' }">
                  联系我们
                </router-link>
              </a>
            </li>
          </ul>
        </li>
      </ul>

      <div
        :class="showTag == true ? 'open' : ''"
        @mouseover="showNav(1)"
        @mouseout="showNav(0)"
        class="pro-more-ul"
      >
        <ul>
          <h2>智慧物业</h2>
          <li>
            <a>
              <router-link :to="{ path: '/pc/proNeighbor' }">
                悦邻家
              </router-link>
            </a>
          </li>
          <li>
            <a>
              <router-link :to="{ path: '/pc/proAdminYue' }">
                悦云管
              </router-link>
            </a>
          </li>
          <li>
            <a>
              <router-link :to="{ path: '/pc/proEasyPay' }">
                悦易付
              </router-link>
            </a>
          </li>
          <!-- <li>
            <a>
              <router-link :to="{ path: '/pc/proPropertyPlatform' }">
                悦资管物业版
              </router-link>
            </a>
          </li> -->
          <li>
            <a>
              <router-link :to="{ path: '/pc/proAssetManagement' }">
                悦资管
              </router-link>
            </a>
          </li>
          <!-- <li>
            <router-link :to="{ path: '/pc/proPricePackage' }">
              悦资管价格套餐
            </router-link>
          </li> -->
        </ul>
        <ul>
          <h2>智慧商业</h2>
          <li>
            <a>
              <router-link :to="{ path: '/pc/proBusinessDistrict' }">
                悦商圈
              </router-link>
            </a>
          </li>
          <!-- <li>
            <a>
              <router-link :to="{ path: '/pc/proCentrerYue' }">
                悦中心
              </router-link>
            </a>
          </li> -->
          <li>
            <a>
              <router-link :to="{ path: '/pc/proCommercialBenefits' }">
                悦商惠
              </router-link>
            </a>
          </li>
          <li>
            <a>
              <router-link :to="{ path: '/pc/proPouch' }">
                悦荷包
              </router-link>
            </a>
          </li>
          <li>
            <a>
              <router-link :to="{ path: '/pc/proBusPlatform' }">
                悦资管（商业版）
              </router-link>
            </a>
          </li>
        </ul>
        <ul>
          <h2>企业数字化</h2>
          <li>
            <a>
              <router-link :to="{ path: '/pc/proUnifiedPortal' }">
                统一门户
              </router-link>
            </a>
          </li>
          <li>
            <a>
              <router-link :to="{ path: '/pc/proWebsite' }">
                企业官网
              </router-link>
            </a>
          </li>
          <li>
            <a>
              <router-link :to="{ path: '/pc/proProcessCenter' }">
                流程中心
              </router-link>
            </a>
          </li>
          <li>
            <a>
              <router-link :to="{ path: '/pc/proEhr' }">
                EHR
              </router-link>
            </a>
          </li>
          <li>
            <a>
              <router-link :to="{ path: '/pc/proPlatform' }">
                钉钉集成平台
              </router-link>
            </a>
          </li>
          <li>
            <a>
              <router-link :to="{ path: '/pc/proIntegrated' }">
                业财一体化
              </router-link>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderCon",
  props: {
    index: {
      type: Number,
      default: -1
    },
    type: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      topDistance: 0,
      showTag: false,
      showTagSolu: false,
      showTagAbout: false,
      active: false,
      activeSolu: false,
      activeAbout: false,
      saasUrl: ""
    };
  },
  created() {
    var _hmt = _hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?7e9516aaf45693ed438ddcd48f73fc9d";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
    window.addEventListener("scroll", this.handleScroll);
    if (process.env.BASE_API === "https://manager-open-hw.oojoyoo.com") {
      this.saasUrl = "https://asm.oojoyoo.com/#/login";
    } else if (process.env.BASE_API === "https://joycloud-rd.top/") {
      this.saasUrl = "https://asm.oojoyoo.com/#/login";
    } else if (process.env.BASE_API === "https://sgy-lms-pre.oojoyoo.com") {
      this.saasUrl = "https://asm.oojoyoo.com/#/login";
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    toIndex() {
      if ("/" == this.$route.path) {
        this.$emit("getClickData", "cooperation");
      } else {
        this.$router.push({ path: "/", query: { flag: "cooperation" } });
      }
    },
    // 导航内容显示&隐藏
    showNav(i) {
      this.showTag = i == 1 ? true : false;
      this.active = i == 1 ? true : false;
    },
    // 导航解决方案内容显示&隐藏
    showNavSolu(i) {
      this.showTagSolu = i == 1 ? true : false;
      this.activeSolu = i == 1 ? true : false;
    },
    // 导航解决方案内容显示&隐藏
    showNavAbout(i) {
      this.showTagAbout = i == 1 ? true : false;
      this.activeAbout = i == 1 ? true : false;
    },
    handleScroll() {
      // 实现当滚动到指定位置，导航加背景
      this.topDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    }
  }
};
</script>
<style scoped lang="scss">
//
</style>
