<template>
  <div class="sect fooCon-top">
    <div class="main-wrap">
      <div class="tit-box">
        <h2>开启您的<span>数字化产业</span>之旅</h2>
        <p>
          引领企业数字化创新，助力中大型企业的数字化转型
        </p>
        <router-link :to="{ path: '/pc/contact' }">
          <a class="tryBtn">免费试用</a>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterConTop",
  props: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style scoped lang="scss">
//
</style>
