<template>
  <div class="con-wrapper">
    <div class="wap wapDel">
      <header-con style="background:#fff" />
      <div class="m-main">
        <div class="news-wrapNo">
          <div class="news-delNo">
            <img src="../../../assets/img/wap/det_no.png" />
            <p>暂无新闻动态</p>
            <span @click="toReturn">返回</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerConWap.vue"; // 头部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
export default {
  name: "news",
  components: {
    HeaderCon
  },
  metaInfo: getMetaInfo(),
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    toReturn() {
      this.$router.go(-1); // 返回上一层
    }
  }
};
</script>
<style lang="scss" scope>
.m-main {
  height: 100%;
  .news-wrapNo {
    background: #fff;
    height: 100%;
    padding: 3.08rem 0.61rem 0.4rem;
    .news-delNo {
      img {
        width: 100%;
        display: block;
        margin: 0 auto;
      }
      p{
        margin: 0.2rem 0 0.6rem;
        text-align: center;
        font-size: 0.32rem;
        color: #AAADBB;
      }
      span {
        width: 2.4rem;
        height: 0.88rem;
        line-height: 0.88rem;
        display: block;
        text-align: center;
        margin: 0 auto;
        font-size: 0.28rem;
        color: #2867FF;
        border-radius: 50px;
        border: 1px solid #2867FF;

      }
    }
  }
}
</style>
