<template>
  <div class="con-wrapper">
    <div class="wap">
      <header-con />
      <div class="m-main">
        <div class="banner-single">
          <div class="banner"></div>
          <div class="banner-con">
            <h1>悦云物业资管平台</h1>
            <span></span>
            <p class="tit-desc">
              悦云物业资管平台是物业企业管理资产与服务的专业平台，让物业资产不再难管理，让服务品质不再难监管，让数据为企业赋新能！
            </p>
            <div class="banner-btn">
              <router-link :to="{ path: '/wap/contact' }" class="btn-ask"
                >免费试用</router-link
              >
            </div>
          </div>
        </div>
        <div :class="topNavDistance > 390 ? 'fixed' : ''" class="scroll-nav">
          <div class="scroll-navCon">
            <ul class="main-wrap flex flex-middle">
              <li
                v-for="(item, index) in allMenuList"
                :key="item.id"
                :class="{ active: active == index }"
                @click="scrollTo(index)"
              >
                {{ item.value }}
                <span></span>
              </li>
            </ul>
          </div>
        </div>
        <div class="product-scroll">
          <product-advantageWap :advantage="advantageList" class="scroll-con" />
          <product-function :functionPro="functionList" class="scroll-con" />
          <!-- <div class="main-single wap-business scroll-con">
            <div>
              <div class="tit-box">
                <h2>业务场景</h2>
              </div>
              <div class="con-box">
                <div class="tab-cont">
                  <div class="w100">
                    <div class="business-con">
                      <div class="business-tit">
                        <h2>满足各类物业收费费项，每笔款项清晰可查</h2>
                        <span></span>
                      </div>
                      <div class="business-tabCon clearfloat">
                        <img
                          src="../../../assets/img/wap/product_bus_pic1.png"
                          class="fl"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="w100">
                    <div class="business-con">
                      <div class="business-tit">
                        <h2>
                          支持权责制、收付制双财务模式，助力业财一体化
                        </h2>
                        <span></span>
                      </div>
                      <div class="business-tabCon clearfloat">
                        <img
                          src="../../../assets/img/wap/product_bus_pic2.png"
                          class="fr"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="w100">
                    <div class="business-con">
                      <div class="business-tit">
                        <h2>
                          品质在线全面监管员工服务过程，有效提升员工服务品质
                        </h2>
                        <span></span>
                      </div>
                      <div class="business-tabCon clearfloat">
                        <img
                          src="../../../assets/img/wap/product_bus_pic2.png"
                          class="fr"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="w100">
                    <div class="business-con">
                      <div class="business-tit">
                        <h2>
                          为物业运营赋能，盘活业主生活圈，增加物业收入来源
                        </h2>
                        <span></span>
                      </div>
                      <div class="business-tabCon clearfloat">
                        <img
                          src="../../../assets/img/wap/product_bus_pic2.png"
                          class="fr"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <product-operate class="scroll-con" />
        </div>
      </div>
      <div class="fooConWap">
        <footer-conTopW />
        <footer-conW />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerConWap"; // 头部
import ProductAdvantageWap from "@/components/products/productAdvantageWap"; // 产品优势
import ProductFunction from "@/components/products/productFunctionWap"; // 产品功能
import ProductOperate from "@/components/products/productOperateWap"; // 合作案例
import FooterConTopW from "@/components/footerConTopW"; // 底部免费试用
import FooterConW from "@/components/footerConWap"; // 底部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
export default {
  name: "proPropertyPlatform",
  components: {
    HeaderCon,
    FooterConTopW,
    FooterConW,
    ProductAdvantageWap,
    ProductFunction,
    ProductOperate
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      topNavDistance: 0,
      active: 0,
      allMenuList: [
        {
          value: "产品优势",
          id: "1"
        },
        {
          value: "产品功能",
          id: "2"
        },
        // {
        //   value: "业务场景",
        //   id: "3"
        // },
        {
          value: "合作案例",
          id: "4"
        }
      ],
      //产品优势内容
      advantageList: [
        {
          id: "1",
          tit: "实现物业资产数字化",
          titCon: "完成商业体、住宅物业对项目、楼栋、房间等全面数字化",
          src: require("../../../assets/img/funIcon/proFun_icon43.png")
        },
        {
          id: "2",
          tit: "品质管理精细化",
          titCon: "实现对绿化保洁、报事报修、巡检安保在线颗粒化管理",
          src: require("../../../assets/img/funIcon/proFun_icon44.png")
        },
        {
          id: "3",
          tit: "收费缴费高效透明",
          titCon: "支持对物业、水电、装修、垃圾清运等多项费用的集中透明管理",
          src: require("../../../assets/img/funIcon/proFun_icon45.png")
        },
        {
          id: "4",
          tit: "费项标准灵活配置",
          titCon: "支持对仪表、费项等配置多维度收费模式",
          src: require("../../../assets/img/funIcon/proFun_icon46.png")
        },
        {
          id: "5",
          tit: "深度降本增效",
          titCon: "在线化功能重塑，实现少人化管理，提高效率减少人工成本",
          src: require("../../../assets/img/funIcon/proFun_icon47.png")
        },
        {
          id: "6",
          tit: "业务财务一体化",
          titCon: "对接财务系统，自动生成凭证，提供标准的接口服务；",
          src: require("../../../assets/img/funIcon/proFun_icon48.png")
        }
      ],
      // 产品功能内容
      functionList: [
        {
          id: "1",
          tit: "交房入伙",
          con:
            "可完成房屋交房处理，过程全记录，业主验房问题透明解决，让业主放心收房",
          src: require("../../../assets/img/funIcon1/proFunT_icon69.png")
        },
        {
          id: "2",
          tit: "装修管理",
          con: "可处理装修申请、新增装修申请、装修验收处理，",
          src: require("../../../assets/img/funIcon1/proFunT_icon70.png")
        },
        {
          id: "3",
          tit: "收费管理",
          con:
            "可设置收费标准、查询应收账单、记录收款信息，收取临时费用，账单调整审批，房屋账单可预充值，一键开具发票，生成财务凭证等，满足所有物业财务需求",
          src: require("../../../assets/img/funIcon1/proFunT_icon71.png")
        },
        {
          id: "4",
          tit: "财务管理",
          con: "可进行凭证管理，支持与其他财务系统打通，真正实现业财一体化",
          src: require("../../../assets/img/funIcon1/proFunT_icon72.png")
        },
        {
          id: "5",
          tit: "报事报修",
          con: "自由设置响应预警期限，服务流程全面在线可管，提升处理效率",
          src: require("../../../assets/img/funIcon1/proFunT_icon73.png")
        },
        {
          id: "6",
          tit: "设备管理",
          con:
            "每台设备设施都有档案信息，自由设置巡检项目及维保计划生成规则，自动生成设备维保、巡检任务，同步记录系统中的巡检、维保历史记录",
          src: require("../../../assets/img/funIcon1/proFunT_icon74.png")
        },
        {
          id: "7",
          tit: "品质管理",
          con:
            "自由设置品质巡查计划，自动派发品质巡查任务，在线查看品质巡查结果，有效管控服务品质",
          src: require("../../../assets/img/funIcon1/proFunT_icon75.png")
        },
        {
          id: "8",
          tit: "绿化保洁",
          con: "创建绿化、保洁工作计划规则，智能发布待办任务，管理所有任务进度",
          src: require("../../../assets/img/funIcon1/proFunT_icon76.png")
        },
        {
          id: "8",
          tit: "智慧巡检码",
          con:
            "每个区域点位均有独立二维码，替代传统纸质表格管理，物业员工可使用悦云管APP扫码完成日常巡检及其他待办任务",
          src: require("../../../assets/img/funIcon1/proFunT_icon77.png")
        },
        {
          id: "9",
          tit: "在线缴费",
          con: "在线查询所有应收账单，每一笔款项清晰可查",
          src: require("../../../assets/img/funIcon1/proFunT_icon78.png")
        }
      ],
      loading: true,
      noScroll: false
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleNavScroll);
    window.addEventListener("scroll", this.onScroll, false);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.handleNavScroll);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    handleNavScroll() {
      // 二级导航滚动到指定位置固定
      this.topNavDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    // 二级导航+内容联动滚动监听
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(".scroll-con");
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });
      // 获取当前文档流的 scrollTop
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop += 52 * window.devicePixelRatio;
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (
          scrollTop >=
          (this.noScroll ? (offsetTopArr[n] -= 60) : offsetTopArr[n])
        ) {
          navIndex = n;
        }
      }
      if (this.active == 3) {
        this.active = 3;
      } else {
        this.active = navIndex;
      }
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      if (index == 3) {
        this.active = 3;
      } else {
        this.active = index;
      }
      var targetOffsetTop = document.querySelector(
        `.product-scroll .scroll-con:nth-child(${index + 1})`
      ).offsetTop;
      targetOffsetTop -= 52 * window.devicePixelRatio;
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
      const STEP = 50;
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        if (this.topNavDistance < 390) {
          targetOffsetTop -= 60;
          this.noScroll = true;
        } else {
          this.noScroll = false;
        }
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    }
  }
};
</script>
