<template>
  <div class="main-single aboutWap-history">
    <div>
      <div class="tit-box">
        <h2>悦云大事记</h2>
      </div>
      <div class="con-box">
        <div class="tab-cont">
          <div class="his-top">
            <ul id="swiper" class="swiper flex">
              <li
                v-for="(years, index) in yearsTab"
                :key="index"
                @click="cur = index"
                :class="{ active: cur == index }"
                class="swiper_inner flex"
              >
                <h3 class="title">{{ years.value }}</h3>
                <div class="short_line"></div>
              </li>
            </ul>
          </div>
          <div
            v-for="(yearsCon, index) in yearsTabCon[cur]"
            :key="index"
            class="event_item"
          >
            <h2 class="font-h2">{{ yearsCon.tit }}</h2>
            <p class="font-p">
              {{ yearsCon.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
export default {
  name: "aboutHistory",
  components: {
    // swiper,
    // swiperSlide
  },
  props: {},
  data() {
    return {
      cur: 0,
      swiperOption: {
        loop: true,
        fadeEffect: {
          crossFade: true
        },
        autoplay: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: false
      },
      yearsTab: [
        {
          value: "2018",
          index: "1"
        },
        {
          value: "2019",
          index: "1"
        },
        {
          value: "2020",
          index: "2"
        }
      ],
      //大事记内容
      yearsTabCon: [
        [
          {
            tit: "2018年7月大事记",
            text: "与株洲大汉悦中心签订悦商圈SAAS服务合同"
          },
          {
            tit: "2018年1月大事记",
            text: "注册成立湖南大汉商管云服务有限公司"
          }
        ],
        [
          {
            tit: "2019年11月大事记",
            text: "悦商圈2.0版正式上线"
          },
          {
            tit: "2019年9月大事记",
            text: "以O&O大运营服务承接邵阳大汉悦中心开业活动"
          }
        ],
        [
          {
            tit: "2020年11月大事记",
            text: "荣获湖南省双软企业认证"
          },
          {
            tit: "",
            text: "荣获大汉大物业“数字化战略最佳合作伙伴”"
          },
          {
            tit: "2020年9月大事记",
            text: "荣膺国家级高新技术企业称号"
          },
          {
            tit: "2020年8月大事记",
            text: "智起未来|悦云资产管理平台上线启动"
          },
          {
            tit: "2020年7月大事记",
            text: "正式更名为湖南悦云数字科技有限公司悦云资产管理平台成功上线"
          },
          {
            tit: "2020年5月大事记",
            text: "与建设银行、阳光100达成三方共识，共建智慧社区生活平台"
          },
          {
            tit: "2020年4月大事记",
            text: "成功服务漫城星街，美莱美城市广场，湖南绿地，南宁富宁广场等20余购物中心"
          }
        ]
      ]
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiperHonor.swiper;
    }
  },
  mounted() {},
  methods: {}
};
</script>
