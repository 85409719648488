<template>
  <div class="main-single wap-operate">
    <div>
      <div class="tit-box">
        <h2>合作案例</h2>
      </div>
      <div class="con-box">
        <div class="tab-cont">
          <swiper :options="swiperOption" ref="mySwiper" style="height:100%">
            <swiper-slide>
              <ul class="clearfloat">
                <li class="operate-li">
                  <div class="operate-li-con">
                    <img src="../../assets/img/product_operate_icon1.png" />
                    <h3>绿地缤纷荟</h3>
                  </div>
                </li>
                <li class="operate-li">
                  <div class="operate-li-con">
                    <img src="../../assets/img/product_operate_icon1.png" />
                    <h3>绿地中心</h3>
                  </div>
                </li>
                <li class="operate-li">
                  <div class="operate-li-con">
                    <img src="../../assets/img/product_operate_icon1.png" />
                    <h3>株洲大汉悦中心</h3>
                  </div>
                </li>
                <li class="operate-li">
                  <div class="operate-li-con">
                    <img src="../../assets/img/product_operate_icon1.png" />
                    <h3>邵阳大汉悦中心</h3>
                  </div>
                </li>
                <li class="operate-li">
                  <div class="operate-li-con">
                    <img src="../../assets/img/product_operate_icon1.png" />
                    <h3>美莱美城市广场</h3>
                  </div>
                </li>
                <li class="operate-li">
                  <div class="operate-li-con">
                    <img src="../../assets/img/product_operate_icon1.png" />
                    <h3>漫城星街</h3>
                  </div>
                </li>
                <li class="operate-li">
                  <div class="operate-li-con">
                    <img src="../../assets/img/product_operate_icon1.png" />
                    <h3>娄底万豪城市广场</h3>
                  </div>
                </li>
                <li class="operate-li">
                  <div class="operate-li-con">
                    <img src="../../assets/img/product_operate_icon1.png" />
                    <h3>株洲财富广场</h3>
                  </div>
                </li>
              </ul>
            </swiper-slide>
            <swiper-slide>
              <ul class="clearfloat">
                <li class="operate-li">
                  <div class="operate-li-con">
                    <img src="../../assets/img/product_operate_icon1.png" />
                    <h3>湘潭中心</h3>
                  </div>
                </li>
                <li class="operate-li">
                  <div class="operate-li-con">
                    <img src="../../assets/img/product_operate_icon1.png" />
                    <h3>长沙望城步行街</h3>
                  </div>
                </li>
                <li class="operate-li">
                  <div class="operate-li-con">
                    <img src="../../assets/img/product_operate_icon1.png" />
                    <h3>龙湾广场</h3>
                  </div>
                </li>
                <li class="operate-li">
                  <div class="operate-li-con">
                    <img src="../../assets/img/product_operate_icon1.png" />
                    <h3>南宁富宁大厦</h3>
                  </div>
                </li>
              </ul>
            </swiper-slide>
            <div
              class="swiper-pagination productsWap-page"
              slot="pagination"
            ></div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
export default {
  name: "ProductOperate",
  components: {
    swiper,
    swiperSlide
  },
  props: {},
  data() {
    return {
      swiperOption: {
        loop: true,
        fadeEffect: {
          crossFade: true
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: false
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {},
  methods: {}
};
</script>
