<template>
  <div class="main-single scroll-con">
    <div>
      <div class="tit-box">
        <h2>方案优势</h2>
      </div>
      <div class="con-box">
        <div class="tab-cont">
          <ul class="ulList">
            <li v-for="(products, index) in advantage" :key="index">
              <div class="normal-box flex">
                <img :src="products.src" />
                <div class="normal-boxR">
                  <h4>
                    {{ products.tit }}
                  </h4>
                  <p>
                    {{ products.titCon }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "productAdvantageWap",
  props: {
    advantage: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
