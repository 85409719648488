import { render, staticRenderFns } from "./wisdomBusiness.vue?vue&type=template&id=474a2986&scoped=true&"
import script from "./wisdomBusiness.vue?vue&type=script&lang=js&"
export * from "./wisdomBusiness.vue?vue&type=script&lang=js&"
import style0 from "./wisdomBusiness.vue?vue&type=style&index=0&id=474a2986&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474a2986",
  null
  
)

export default component.exports