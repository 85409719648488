<template>
  <div class="product-single product-operate">
    <div class="main-wrap">
      <div class="tit-box">
        <h2>合作案例</h2>
      </div>
      <div class="con-box">
        <div class="tab-cont">
          <div class="honorCon">
            <div class="honorCon_c clearfloat">
              <transition-group
                tag="ul"
                class="slide-ul"
                name="list"
                style="border-top: none;
                border-left: none"
              >
                <ul
                  v-for="(item, index) in honorData"
                  :key="index"
                  v-show="index === currentIndex"
                  class="clearfloat uls"
                >
                  <li
                    v-for="lisList in item.list"
                    :key="lisList.id"
                    class="operate-li"
                  >
                    <div class="operate-li-con">
                      <img :src="lisList.src" />
                      <h3>{{ lisList.text }}</h3>
                    </div>
                  </li>
                </ul>
              </transition-group>

              <div class="carousel-items">
                <span
                  v-for="(item, index) in honorData.length"
                  :key="index"
                  :class="{ active: index === currentIndex }"
                  @click="change(index)"
                >
                </span>
              </div>
            </div>
          </div>
          <!-- <span>
            <swiper :options="swiperOption" ref="mySwiper">
              <swiper-slide>
                <ul class="clearfloat">
                  <li class="operate-li">
                    <div class="operate-li-con">
                      <img src="../../assets/img/product_operate_icon2.png" />
                      <h3>金桥国际</h3>
                    </div>
                  </li>
                  <li class="operate-li">
                    <div class="operate-li-con">
                      <img src="../../assets/img/product_operate_icon2.png" />
                      <h3>大汉新城</h3>
                    </div>
                  </li>
                  <li class="operate-li">
                    <div class="operate-li-con">
                      <img src="../../assets/img/product_operate_icon2.png" />
                      <h3>吉首锦绣乾城</h3>
                    </div>
                  </li>
                  <li class="operate-li">
                    <div class="operate-li-con">
                      <img src="../../assets/img/product_operate_icon2.png" />
                      <h3>大汉汉府</h3>
                    </div>
                  </li>
                  <li class="operate-li">
                    <div class="operate-li-con">
                      <img src="../../assets/img/product_operate_icon2.png" />
                      <h3>巨龙家园</h3>
                    </div>
                  </li>
                  <li class="operate-li">
                    <div class="operate-li-con">
                      <img src="../../assets/img/product_operate_icon2.png" />
                      <h3>九亿步行街</h3>
                    </div>
                  </li>
                  <li class="operate-li">
                    <div class="operate-li-con">
                      <img src="../../assets/img/product_operate_icon2.png" />
                      <h3>资江城</h3>
                    </div>
                  </li>
                  <li class="operate-li">
                    <div class="operate-li-con">
                      <img src="../../assets/img/product_operate_icon2.png" />
                      <h3>滨江雅苑</h3>
                    </div>
                  </li>
                </ul>
              </swiper-slide>
              <swiper-slide>
                <ul class="clearfloat">
                  <li class="operate-li">
                    <div class="operate-li-con">
                      <img src="../../assets/img/product_operate_icon2.png" />
                      <h3>永顺天街</h3>
                    </div>
                  </li>
                  <li class="operate-li">
                    <div class="operate-li-con">
                      <img src="../../assets/img/product_operate_icon2.png" />
                      <h3>怀化骏龙府</h3>
                    </div>
                  </li>
                  <li class="operate-li">
                    <div class="operate-li-con">
                      <img src="../../assets/img/product_operate_icon2.png" />
                      <h3>怀化旺龙府</h3>
                    </div>
                  </li>
                </ul>
              </swiper-slide>
              <div
                class="swiper-pagination products-page"
                slot="pagination"
              ></div>
            </swiper>
          </span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import "swiper/swiper-bundle.css";
export default {
  name: "ProductOperate",
  components: {},
  props: {},
  data() {
    return {
      honorData: [
        {
          id: 1,
          list: [
            {
              text: "金桥国际",
              src: require("../../assets/img/product_operate_icon2.png")
            },
            {
              text: "大汉新城",
              src: require("../../assets/img/product_operate_icon2.png")
            },
            {
              text: "吉首锦绣乾城",
              src: require("../../assets/img/product_operate_icon2.png")
            },
            {
              text: "大汉汉府",
              src: require("../../assets/img/product_operate_icon2.png")
            },
            {
              text: "巨龙家园",
              src: require("../../assets/img/product_operate_icon2.png")
            },
            {
              text: "九亿步行街",
              src: require("../../assets/img/product_operate_icon2.png")
            },
            {
              text: "资江城",
              src: require("../../assets/img/product_operate_icon2.png")
            },
            {
              text: "滨江雅苑",
              src: require("../../assets/img/product_operate_icon2.png")
            }
          ]
        },
        {
          id: 2,
          list: [
            {
              text: "永顺天街",
              src: require("../../assets/img/product_operate_icon2.png")
            },
            {
              text: "怀化骏龙府",
              src: require("../../assets/img/product_operate_icon2.png")
            },
            {
              text: "怀化旺龙府",
              src: require("../../assets/img/product_operate_icon2.png")
            }
          ]
        }
      ],
      currentIndex: 0,
      timer: ""
    };
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      this.timer = setInterval(() => {
        this.autoPlay();
      }, 5000);
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  }, // 生命周期 - 销毁之前
  methods: {
    go() {
      this.timer = setInterval(() => {
        this.autoPlay();
      }, 5000);
    },
    stop() {
      clearInterval(this.timer);
      this.timer = null;
    },
    change(index) {
      this.currentIndex = index;
    },
    autoPlay() {
      this.currentIndex++;
      if (this.currentIndex > this.honorData.length - 1) {
        this.currentIndex = 0;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.honorCon {
  width: 1200px;
  overflow: hidden;
  .honorCon_c {
    position: relative;
    width: 100%;
    max-width: 1200px;
    overflow: hidden;
    height: 200px;
  }
}
.uls {
  width: 1200px;
  height: 165px;
  position: absolute;
}
.carousel-items {
  position: absolute;
  z-index: 10;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 0;
}

.carousel-items span {
  width: 22px;
  height: 4px;
  display: inline-block;
  background: #b8cdff;
  margin-right: 8px;
  background-clip: content-box;
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -ms-transition: all 1s ease-out;
  transition: all 1s ease-out;
  border-radius: 3px;
  opacity: 1;
  cursor: pointer;
}

.carousel-items .active {
  background-color: #2c68ff;
}
.list-enter-active {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.list-leave-active {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.list-enter {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.list-leave {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
</style>
