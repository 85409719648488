<template>
  <div class="con-wrapper">
    <div class="pc">
      <header-con :index="0" class="header-barBg" />
      <div class="product-detail">
        <div class="product-single">
          <div class="f-banner floor">
            <div class="main-wrap ban-box">
              <h1 class="ban-box-h1">悦易付</h1>
              <p class="tit-desc">
                是针对线下物业收费交易场景，提供房间账单查询、移动收费、聚合支付的全流程收银服务。解决缴费率低需要上门催缴的痛点，且支持收缴后即时打印收款单，赋能物业提升收款效率。
              </p>
              <div class="banner-btn pr">
                <router-link :to="{ path: '/pc/contact' }" class="btn-ask">
                  免费试用
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="product-nav abs-lt w100">
          <div
            :class="topNavDistance > 490 ? 'fixed' : ''"
            class="anchor-box abs-lt w100"
          >
            <ul class="main-wrap clearfloat">
              <li
                v-for="(item, index) in allMenuList"
                :key="item.id"
                :class="{ active: active == index }"
                @click="scrollTo(index)"
              >
                <span class="abs-mt">{{ item.value }}</span>
                <p class="vh">{{ item.value }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="product-scroll">
          <product-advantage :advantage="advantageList" class="scroll-con" />
          <product-function :functionPro="functionList" class="scroll-con" />
          <!-- <div class="product-single product-business scroll-con">
            <div>
              <div class="tit-box pr">
                <h2>业务场景</h2>
              </div>
              <div class="con-box">
                <div class="tab-cont">
                  <span>
                    <div class="w100 bgGray">
                      <div class="business-con">
                        <div class="business-tit">
                          <h2>移动POS收费，可上门催收，解决收费难题</h2>
                          <span></span>
                        </div>
                        <div class="business-tabCon">
                          <img
                            src="../../../assets/img/product_bus_pic1.png"
                            class="img-phone"
                          />
                          <img
                            src="../../../assets/img/product_bus_pic2.png"
                            class="img-normal"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="w100 bgWhite">
                      <div class="business-con">
                        <div class="business-tit">
                          <h2>多维度灵活查询账单，明细一览无余</h2>
                          <span></span>
                        </div>
                        <div class="business-tabCon">
                          <img
                            src="../../../assets/img/product_bus_pic3.png"
                            class="img-normal"
                          />
                          <img
                            src="../../../assets/img/product_bus_pic1.png"
                            class="img-phone"
                          />
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div> -->
          <product-operate class="scroll-con" />
        </div>
      </div>
      <div class="fooCon">
        <footer-conTop />
        <footer-con />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerCon"; // 头部
import FooterConTop from "@/components/footerConTop"; // 底部免费试用
import FooterCon from "@/components/footerCon"; // 底部
import ProductAdvantage from "@/components/products/productAdvantage"; // 产品优势
import ProductFunction from "@/components/products/productFunction"; // 产品功能
import ProductOperate from "@/components/products/productOperate"; // 合作案例
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字

export default {
  name: "proEasyPay",
  components: {
    HeaderCon,
    FooterCon,
    FooterConTop,
    ProductAdvantage,
    ProductFunction,
    ProductOperate
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      // 二级导航
      allMenuList: [
        {
          value: "产品优势",
          id: "1"
        },
        {
          value: "产品功能",
          id: "2"
        },
        // {
        //   value: "业务场景",
        //   id: "3"
        // },
        {
          value: "合作案例",
          id: "3"
        }
      ],
      //产品优势内容
      advantageList: [
        {
          id: "1",
          tit: "移动POS应用",
          titCon: "基于移动POS机的软硬件解决方案",
          src: require("../../../assets/img/funIcon/proFun_icon49.png")
        },
        {
          id: "2",
          tit: "支持多业务收费模式",
          titCon: "集成商业收费、物业缴费等多种业态支付模式",
          src: require("../../../assets/img/funIcon/proFun_icon50.png")
        },
        {
          id: "3",
          tit: "灵活的支付方式",
          titCon: "可支持扫描多种支付条码，包括聚合支付、微信、支付宝等",
          src: require("../../../assets/img/funIcon/proFun_icon51.png")
        }
      ],
      // 产品功能内容
      functionList: [
        {
          id: "1",
          tit: "账单查询",
          con: "支持按项目、楼栋、房间、时间等多维度查询账单",
          src: require("../../../assets/img/funIcon1/proFunT_icon79.png")
        },
        {
          id: "2",
          tit: "移动收费",
          con: "软硬结合的移动POS机收费方案",
          src: require("../../../assets/img/funIcon1/proFunT_icon80.png")
        },
        {
          id: "3",
          tit: "聚合支付",
          con: "可支持扫描多种支付条码，包括聚合支付、微信、支付宝等",
          src: require("../../../assets/img/funIcon1/proFunT_icon81.png")
        }
      ],
      topNavDistance: 0,
      active: 0
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleNavScroll);
    window.addEventListener("scroll", this.onScroll, false);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.handleNavScroll);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    handleNavScroll() {
      // 二级导航滚动到指定位置固定
      this.topNavDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    // 二级导航+内容联动滚动监听
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(
        ".product-scroll .scroll-con"
      );
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });
      // 获取当前文档流的 scrollTop
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop += 150;
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n;
        }
      }
      this.active = navIndex;
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      var targetOffsetTop = document.querySelector(
        `.product-scroll .scroll-con:nth-child(${index + 1})`
      ).offsetTop;
      targetOffsetTop -= 150;
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
      const STEP = 50;
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    }
  }
};
</script>
