export function getMetaInfo() {
  var metaInfo = {};
  metaInfo.title = "物业管理系统-小区收费系统-物业管理APP-物业数字化管理系统-悦云科技";
  metaInfo.meta = [
    {
      name: "keywords",
      content:  "物业管理系统,小区收费系统,物业管理APP,物业数字化管理系统,智慧物业系统,智慧社区系统,报事报修系统,资产管理业财一体化系统,巡更管理系统,资产管理系统,悦云科技"
    },
    {
      name: "description",
      content: "湖南悦云数字科技有限公司致力于智慧物业系统，智慧社区系统，报事报修系统，资产管理业财一体化系统，巡更管理系统，资产管理系统等企业各类系统性的解雇方案，解决企业在数字化转型的过程中系统多维护难的问题。"
    }
  ];
  return metaInfo;
}
