<template>
  <div class="con-wrapper">
    <div class="pc">
      <header-con :index="0" class="header-barBg" />
      <div class="product-price">
        <div class="product-price-title">
          用科技智慧·助力企业数字化转型
        </div>
        <div class="product-price-package">
          <ul>
            <li>
              <p class="package-title">基础版</p>
              <div class="price">￥<span>4999</span>/年</div>
              <p class="price-year">9999元/年</p>
              <router-link :to="{ path: '/pc/contact' }">
                免费试用
              </router-link>
            </li>
            <li>
              <p class="package-title">升级版</p>
              <div class="price">￥<span>9999</span>/年</div>
              <p class="price-year">15999元/年</p>
              <router-link :to="{ path: '/pc/contact' }">
                免费试用
              </router-link>
            </li>
            <li>
              <p class="package-title">专业版</p>
              <div class="price">￥<span>19999</span>/年</div>
              <p class="price-year">49999元/年</p>
              <router-link :to="{ path: '/pc/contact' }">
                免费试用
              </router-link>
            </li>
            <li>
              <p class="package-title">旗舰版</p>
              <div class="price"></div>
              <p class="price-year package-info">定制开发</p>
              <router-link :to="{ path: '/pc/contact' }">
                获取报价
              </router-link>
            </li>
          </ul>
        </div>
        <div class="product-price-packageInfo">
          <div class="packageInfo-title">
            <h2>悦资管套餐介绍</h2>
            <p>
              可根据企业规模和需求，选择不同的产品套餐
            </p>
          </div>
          <div class="packageInfo-img">
            <img src="../../../assets/img/package-price.png" alt="" />
          </div>
        </div>
        <!-- <div class="product-scroll">
          <product-advantage :advantage="advantageList" class="scroll-con" />
          <product-function :functionPro="functionList" class="scroll-con" />
        </div> -->
      </div>
      <div class="fooCon">
        <footer-conTop />
        <footer-con />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerCon"; // 头部
import FooterConTop from "@/components/footerConTop"; // 底部免费试用
import FooterCon from "@/components/footerCon"; // 底部
// import ProductAdvantage from "@/components/products/productAdvantage"; // 产品优势
// import ProductFunction from "@/components/products/productFunction"; // 产品功能
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字

export default {
  name: "proPlatform",
  components: {
    HeaderCon,
    FooterCon,
    FooterConTop
    // ProductAdvantage,
    // ProductFunction
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      // 二级导航
      allMenuList: [
        {
          value: "产品优势",
          id: "1"
        },
        {
          value: "产品功能",
          id: "2"
        }
      ],
      //产品优势内容
      advantageList: [
        {
          id: "1",
          tit: "全方面的分析报表",
          titCon: "提供从多视角，多维度，多形式的业务及财务信息的统计分析",
          src: require("../../../assets/img/funIcon/proFun_icon16.png")
        },
        {
          id: "2",
          tit: "集成主流财务软件",
          titCon: "支持对接NCC等多种主流财务系统",
          src: require("../../../assets/img/funIcon/proFun_icon17.png")
        },
        {
          id: "3",
          tit: "完整核算管理体系",
          titCon: "支持以项目、公司为单位，建立核算管理体系了解多维度成本状态",
          src: require("../../../assets/img/funIcon/proFun_icon18.png")
        }
      ],
      // 产品功能内容
      functionList: [
        {
          id: "1",
          tit: "凭证管理",
          con: "可按会计科目、辅助项实现业务系统自动生成凭证，并同步至财务系统",
          src: require("../../../assets/img/funIcon1/proFunT_icon22.png")
        },
        {
          id: "2",
          tit: "多端对接",
          con: "可对接主流的财务系统，实现业财数据的流通",
          src: require("../../../assets/img/funIcon1/proFunT_icon23.png")
        },
        {
          id: "3",
          tit: "营收管理",
          con: "支持营业额收入、税金等多维度营收的管理",
          src: require("../../../assets/img/funIcon1/proFunT_icon24.png")
        }
      ],
      topNavDistance: 0,
      active: 0
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleNavScroll);
    window.addEventListener("scroll", this.onScroll, false);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.handleNavScroll);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    handleNavScroll() {
      // 二级导航滚动到指定位置固定
      this.topNavDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    // 二级导航+内容联动滚动监听
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(
        ".product-scroll .scroll-con"
      );
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });
      // 获取当前文档流的 scrollTop
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop += 150;
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n;
        }
      }
      this.active = navIndex;
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      var targetOffsetTop = document.querySelector(
        `.product-scroll .scroll-con:nth-child(${index + 1})`
      ).offsetTop;
      targetOffsetTop -= 150;
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
      const STEP = 50;
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    }
  }
};
</script>
