<template>
  <div class="con-wrapper">
    <div class="wap wapDel">
      <header-con style="background:#fff" />
      <div class="m-main">
        <div class="news-wrapDel">
          <div class="news-del">
            <h2>
              从“开发商售后”到国家“软基建”，大物业对数字化管理需求日渐紧迫
            </h2>
            <p>悦云数字科技<span> / 2019-03-16</span></p>
          </div>
          <div class="news-delCon">
            <img src="../../../assets/img/newImg8.jpg" />
            <p>
              2020年，物业板块经历了一波显著大涨，估值迅速上升到六七十倍，虽近期有所回落，但仍保持四十倍左右。物业成为资本市场的宠儿，除了疫情期间社区服务的重要性凸显，最根本的的原因是以保利物业为首的空头公司极大的拓展了物业的边界，将传统的物业服务延升至社区服务，甚至延伸到广阔的城市/乡镇服务中，具备了无限的增长空间。
            </p>
            <img src="../../../assets/img/newImg9.jpg" />
            <p>
              大物业理念的发展，对行业精细化管理的要求越发严苛。传统的物业从业人员普遍年龄偏大，
              素质偏低，通过业主委员会对业主提供直接或间接的服务。但是，众口难调，业主的素质同样又良莠不齐的现象，物管方和业主间始终存在着无法消失的矛盾。
            </p>
            <p>
              建立物业服务评价体系，对物业服务流程进行标准化梳理，实行品质在线化的精细管理，是解决这些基础矛盾的有效手段，也是传统物业向“大物业”发展的基石。
            </p>
            <p>
              28日，长沙网络安全，智能制造大会于长沙国际会展中心拉开帷幕，当日便迎来数万来自全国各地的参展商。本次展会“数字化”成为主角，大批数字科技公司纷纷亮相，受到来访商家的追捧和青睐。
            </p>
            <img src="../../../assets/img/newImg10.jpg" />
            <p>
              悦云科技位于e4a36号展位，迎来众多商家咨询，其中一位从事东南亚贸易的陈先生对悦云资管系列产品产生极大兴趣，并表示，不论从商家生意管理，还是资产管理，海外需求同样旺盛。
            </p>
            <img src="../../../assets/img/newImg11.jpg" />
            <p>
              在新时代的发展下，不论是大物业的精细化管理，还是企业的标准化管理，信息化正在向数字化进行转型，悦云科技年轻富有创造力，变革路上与你一路同行。
            </p>
          </div>
          <div class="news-delBtn clearfloat">
            <span @click="toReturn" class="detBtn-l fl">返回</span>
            <router-link :to="{ path: '/wap/newsDetailsSec' }">
              <span class="detBtn-r fr">下一篇</span>
            </router-link>
          </div>
        </div>
        <div class="fooConWap">
          <footer-conTopW />
          <footer-conW />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerConWap.vue"; // 头部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
import FooterConTopW from "@/components/footerConTopW"; // 底部免费试用
import FooterConW from "@/components/footerConWap"; // 底部
export default {
  name: "news",
  components: {
    HeaderCon,
    FooterConTopW,
    FooterConW
  },
  metaInfo: getMetaInfo(),
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    toReturn() {
      this.$router.go(-1); // 返回上一层
    }
  }
};
</script>
<style lang="scss" scope>
.m-main {
  height: 100%;
  .news-wrapDel {
    background: #fff;
    height: 100%;
    padding: 1.4rem 0.44rem 0 0.34rem;
    .news-del {
      h2 {
        font-size: 0.44rem;
        color: #00041b;
        text-align: left;
      }
      p {
        margin-top: 0.36rem;
        text-align: left;
        font-size: 0.24rem;
        color: #4b4f5e;
        span {
          color: #a6a7af;
          margin-left: 0.1rem;
        }
      }
    }
    .news-delCon {
      margin: 0.8rem 0 0;
      p {
        font-size: 0.28rem;
        color: #00041b;
        padding-bottom: 0.4rem;
        text-indent: 20px;
        text-align: left;
        line-height: 0.5rem;
      }
      img {
        width: 100%;
        display: block;
        margin: 0 auto 0.4rem;
      }
    }
    .news-delBtn {
      padding: 0.2rem 0 1rem;
      span {
        width: 3rem;
        height: 0.98rem;
        line-height: 0.98rem;
        display: inline-block;
        text-align: center;
        font-size: 0.32rem;
        color: #00041b;
        border: 1px solid #c9c9cb;
      }
    }
  }
}
</style>
