<template>
  <div>
    <div class="sect fooCon-center">
      <div class="main-wrap">
        <div class="fooCenter-con clearfloat">
          <div class="fooCenter-left">
            <a>
              <router-link :to="{ path: '/' }">
                <img src="../assets/img/logo_foo.png" class="logo-footer" />
              </router-link>
            </a>
            <div class="footer-ewm clearfloat">
              <div class="foo-ewmL fl">
                <img src="../assets/img/ewmA.png" class="ewm-footer" />
                <p>扫码关注<br />“悦云”公众号</p>
              </div>
              <div class="foo-ewmL fr">
                <img src="../assets/img/ewmB.png" class="ewm-footer" />
                <p>扫码添加<br />客服微信</p>
              </div>
            </div>
            <ul class="footer-ul">
              <!-- <li>
                <i><img src="../assets/img/foo_phone.png"/></i>0731-8850 8356
              </li> -->
              <li><span class="tel">tel</span>客服热线：4000-022-812</li>
              <li>
                <i><img src="../assets/img/foo_add.png"/></i
                >湖南省长沙市望城区普瑞西路金桥国际未来城3栋3楼
              </li>
            </ul>
          </div>
          <div class="fooCenter-nav" style="margin-left:250px">
            <h5>热门产品</h5>
            <ul class="fooCenter-ul">
              <li>
                <router-link :to="{ path: '/pc/proPropertyPlatform' }">
                  悦资管
                </router-link>
              </li>
              <li>
                <router-link :to="{ path: '/pc/proNeighbor' }">
                  悦邻家
                </router-link>
              </li>
              <li>
                <router-link :to="{ path: '/pc/proAdminYue' }">
                  悦云管
                </router-link>
              </li>
              <li>
                <router-link :to="{ path: '/pc/proBusinessDistrict' }">
                  悦商圈
                </router-link>
              </li>
              <!-- <li>
                <router-link :to="{ path: '/pc/proBusPlatform' }">
                  悦云商业资管平台
                </router-link>
              </li> -->
            </ul>
          </div>
          <div class="fooCenter-nav">
            <h5>解决方案</h5>
            <ul class="fooCenter-ul">
              <li>
                <a>
                  <router-link :to="{ path: '/pc/solutionsProperty' }">
                    智慧物业解决方案
                  </router-link>
                </a>
              </li>
              <li>
                <a>
                  <router-link :to="{ path: '/pc/solutionsBusiness' }">
                    智慧商业解决方案
                  </router-link>
                </a>
              </li>
              <li>
                <a>
                  <router-link :to="{ path: '/pc/solutions' }">
                    企业数字化解决方案
                  </router-link>
                </a>
              </li>
            </ul>
          </div>
          <div class="fooCenter-nav">
            <h5>关于我们</h5>
            <ul class="fooCenter-ul">
              <li>
                <a href="#">
                  <router-link :to="{ path: '/pc/about' }">
                    关于悦云
                  </router-link>
                </a>
              </li>
              <li>
                <a>
                  <router-link :to="{ path: '/pc/news' }">
                    悦云动态
                  </router-link>
                </a>
              </li>
              <li>
                <a>
                  <router-link :to="{ path: '/pc/contact' }">
                    联系我们
                  </router-link>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="sect fooCon-bottom">
      <div class="main-wrap">
        <p>
          湖南悦云数字科技有限公司版权所有<span
            @click="toRescue"
            style="cursor: pointer;"
            >湘ICP备18007989号-3</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterCon",
  props: {},
  data() {
    return {
      url: "https://beian.miit.gov.cn/"
    };
  },
  mounted() {},
  methods: {
    toRescue() {
      window.open(this.url, "_blank");
      // window.location.href = this.url
    }
  }
};
</script>
<style scoped lang="scss">
//
</style>
