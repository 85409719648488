<template>
  <div class="con-wrapper">
    <div class="pc">
      <header-con />
      <div class="news">
        <div class="news-box" style="padding: 142px 0 0">
          <div class="main-wrap main-wrapNews clearfloat">
            <div class="news-det-left">
              <h1 class="news-det-tit">
                从“开发商售后”到国家“软基建”，大物业对数字化管理需求日渐紧迫
              </h1>
              <div class="news-det-source">
                <div>
                  <span class="source">悦云数字科技</span>
                  <span class="time">2020-11-29</span>
                </div>
              </div>
              <div class="news-det-con">
                <div class="news-detBox">
                  <div class="news-det-original">
                    <section>
                      <img src="../../../assets/img/newImg8.jpg" />
                      2020年，物业板块经历了一波显著大涨，估值迅速上升到六七十倍，虽近期有所回落，但仍保持四十倍左右。物业成为资本市场的宠儿，除了疫情期间社区服务的重要性凸显，最根本的的原因是以保利物业为首的空头公司极大的拓展了物业的边界，将传统的物业服务延升至社区服务，甚至延伸到广阔的城市/乡镇服务中，具备了无限的增长空间。
                      <br />
                      <br />
                      <img src="../../../assets/img/newImg9.jpg" />
                      大物业理念的发展，对行业精细化管理的要求越发严苛。传统的物业从业人员普遍年龄偏大，
                      素质偏低，通过业主委员会对业主提供直接或间接的服务。但是，众口难调，业主的素质同样又良莠不齐的现象，物管方和业主间始终存在着无法消失的矛盾。<br /><br />
                      建立物业服务评价体系，对物业服务流程进行标准化梳理，实行品质在线化的精细管理，是解决这些基础矛盾的有效手段，也是传统物业向“大物业”发展的基石。
                    </section>
                    <section>
                      28日，长沙网络安全，智能制造大会于长沙国际会展中心拉开帷幕，当日便迎来数万来自全国各地的参展商。本次展会“数字化”成为主角，大批数字科技公司纷纷亮相，受到来访商家的追捧和青睐。<br />
                    </section>
                    <br />
                    <section>
                      <img src="../../../assets/img/newImg10.jpg" />
                      悦云科技位于e4a36号展位，迎来众多商家咨询，其中一位从事东南亚贸易的陈先生对悦云资管系列产品产生极大兴趣，并表示，不论从商家生意管理，还是资产管理，海外需求同样旺盛。
                    </section>
                    <br />
                    <br />
                    <section>
                      <img src="../../../assets/img/newImg11.jpg" />
                      在新时代的发展下，不论是大物业的精细化管理，还是企业的标准化管理，信息化正在向数字化进行转型，悦云科技年轻富有创造力，变革路上与你一路同行。
                    </section>
                  </div>
                </div>
                <div class="news-det-page">
                  <router-link :to="{ path: '/pc/newsDetails' }">
                    <span>
                      下一篇：
                      <a>传统商业地产靠什么赢得未来？商圈大运营模式提前到来！</a>
                    </span>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="news-det-right">
              <div class="rt-list">
                <h1>最新新闻</h1>
                <ul>
                  <li>
                    <router-link :to="{ path: '/pc/newsDetailsSec' }">
                      <a
                        >从“开发商售后”到国家“软基建”，大物业对数字化管理需求日渐紧迫</a
                      >
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ path: '/pc/newsDetails' }">
                      <a>传统商业地产靠什么赢得未来？商圈大运营模式提前到来！</a>
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ path: '/pc/newsDetailsThi' }">
                      <a
                        >日曝光量高达130000+！这个商场如何将线上流量转化成线下客流的？</a
                      >
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="news-d-wx">
                <dl class="clearfloat">
                  <dt>
                    <p class="wx-p01">扫码关注官方微信</p>
                    <p class="wx-p02">及时获取更多活动信息</p>
                  </dt>
                  <dd>
                    <img src="../../../assets/img/ewmA.png" />
                    <p>悦云科技</p>
                  </dd>
                </dl>
              </div>
              <div class="news-det-contact">
                <h3>
                  <a>
                    <router-link :to="{ path: '/pc/contact' }">
                      <img
                        class="img-arr01"
                        src="../../../assets/img/phone.png"
                      />联系我们
                      <img
                        class="img-arr02"
                        src="../../../assets/img/news_right.png"
                      />
                    </router-link>
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fooCon">
        <footer-conTop />
        <footer-con />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerCon"; // 头部
import FooterConTop from "@/components/footerConTop"; // 底部免费试用
import FooterCon from "@/components/footerCon"; // 底部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
export default {
  name: "news",
  components: {
    HeaderCon,
    FooterCon,
    FooterConTop
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      topNavDistance: 0,
      active: 0
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleNavScroll);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.handleNavScroll);
  },
  methods: {
    handleNavScroll() {
      // 二级导航滚动到指定位置固定
      this.topNavDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    }
  }
};
</script>
