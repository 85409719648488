<template>
  <div class="main-single wap-function">
    <div>
      <div class="tit-box">
        <h2>产品功能</h2>
      </div>
      <div class="con-box">
        <div class="tab-cont">
          <ul class="ul-list clearfloat">
            <li
              v-for="(productFun, index) in functionPro"
              :key="index"
              style="visibility: visible; "
            >
              <a href="javascript:;">
                <div class="marking_img">
                  <img :src="productFun.src" />
                </div>
                <dl class="dl_marking">
                  <dd>{{ productFun.tit }}</dd>
                  <dt>
                    {{ productFun.con }}
                  </dt>
                </dl>
              </a>
            </li>
            <div class="clear"></div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "ProductFunction",
  props: {
    functionPro: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
