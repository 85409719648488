<template>
  <div>
    <div class="sect fooCon-centerWap">
      <div class="main-wrap">
        <div class="fooCenter-con">
          <div class="fooCenter-nav">
            <h5>热门产品</h5>
            <ul class="fooCenter-ul clearfloat">
              <li style="width:26%">
                <router-link :to="{ path: '/wap/proBusinessDistrict' }">
                  悦商圈
                </router-link>
              </li>
              <li style="width:26%">
                <router-link :to="{ path: '/wap/proNeighbor' }">
                  悦邻家
                </router-link>
              </li>
              <li style="width:40%">
                <router-link :to="{ path: '/wap/proBusPlatform' }">
                  悦云商业资管平台
                </router-link>
              </li>
              <li>
                <router-link :to="{ path: '/wap/proPropertyPlatform' }">
                  悦云物业资管平台
                </router-link>
              </li>
            </ul>
          </div>
          <div class="fooCenter-nav">
            <h5>解决方案</h5>
            <ul class="fooCenter-ul clearfloat">
              <li style="width:50%">
                <a>
                  <router-link :to="{ path: '/wap/solutions' }">
                    企业数字化解决方案
                  </router-link>
                </a>
              </li>
              <li style="width:49%">
                <a>
                  <router-link :to="{ path: '/wap/solutionsBusiness' }">
                    智慧商业解决方案
                  </router-link>
                </a>
              </li>
              <li style="width:49%">
                <a>
                  <router-link :to="{ path: '/wap/solutionsProperty' }">
                    智慧物业解决方案
                  </router-link>
                </a>
              </li>
            </ul>
          </div>
          <div class="fooCenter-nav">
            <h5>关于我们</h5>
            <ul class="fooCenter-ul clearfloat" style="border-bottom:0">
              <li style="width:33%">
                <a>
                  <router-link :to="{ path: '/wap/about' }">
                    关于悦云
                  </router-link>
                </a>
              </li>
              <li style="width:33%">
                <a>
                  <router-link :to="{ path: '/wap/news' }">
                    悦云动态
                  </router-link>
                </a>
              </li>
              <li style="width:33%">
                <a>
                  <router-link :to="{ path: '/wap/contact' }">
                    联系我们
                  </router-link>
                </a>
              </li>
            </ul>
          </div>
          <div class="fooCenter-left">
            <div class="footer-ewm clearfloat">
              <div class="foo-ewmL fl">
                <img src="../assets/img/ewmA_wap.png" class="ewm-footer" />
                <p>扫码关注<br />“悦云”公众号</p>
              </div>
              <div class="foo-ewmL fr">
                <img src="../assets/img/ewmB_wap.png" class="ewm-footer" />
                <p>扫码添加<br />客服微信</p>
              </div>
            </div>
            <ul class="footer-ul">
              <li>联系电话：<span>0731-8850 8356</span></li>
              <li>
                地址:湖南省长沙市望城区普瑞西路金桥国际未来城3栋3楼
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="sect fooCon-bottom">
      <div class="main-wrap">
        <p>
          Copyright2018-2020湖南悦云科技joycloud.top版权所有<span @click="toRescue">湘ICP备18007989号-2</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterConWap",
  props: {},
  data() {
    return {
      url: 'https://beian.miit.gov.cn/',
    };
  },
  mounted() {},
  methods: {
    toRescue() {
      window.open(this.url, '_blank')
      // window.location.href = this.url
    },
  }
};
</script>
<style scoped lang="scss">
//
</style>
