<template>
  <div class="product-single">
    <div class="f-banner floor">
      <div class="main-wrap ban-box">
        <h1 class="ban-box-h1">悦邻家</h1>
        <p class="tit-desc">
          悦邻家是业主与物业之间的信息桥梁，物业工作者可以近距离了解业主的需求，业主可以近距离看到物业提供的服务，让服务“邻”距离！
        </p>
        <div class="banner-btn pr">
          <router-link :to="{ path: '/pc/contact' }" class="btn-ask"
            >免费试用</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "ProductBanner",
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
