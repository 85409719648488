import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/pc/index/index.vue"; // 首页
import home from "../views/pc/home/home.vue"; // 首页2
import about from "../views/pc/about/about.vue"; // 关于我们
import proNeighbor from "../views/pc/products/proNeighbor.vue"; // 悦邻家
import proUnifiedPortal from "../views/pc/products/proUnifiedPortal.vue"; // 统一门户
import proWebsite from "../views/pc/products/proWebsite.vue"; // 企业官网
import proProcessCenter from "../views/pc/products/proProcessCenter.vue"; // 流程中心
import proEhr from "../views/pc/products/proEhr.vue"; // EHR
import proPlatform from "../views/pc/products/proPlatform.vue"; // 钉钉集成平台
import proIntegrated from "../views/pc/products/proIntegrated.vue"; // 业财一体化
import proBusinessDistrict from "../views/pc/products/proBusinessDistrict.vue"; // 悦商圈
import proCentrerYue from "../views/pc/products/proCentrerYue.vue"; // 悦中心
import proPouch from "../views/pc/products/proPouch.vue"; // 悦荷包
import proCommercialBenefits from "../views/pc/products/proCommercialBenefits.vue"; // 悦商惠
import proBusPlatform from "../views/pc/products/proBusPlatform.vue"; // 悦云商业资管平台
import proAdminYue from "../views/pc/products/proAdminYue.vue"; // 悦云管
import proEasyPay from "../views/pc/products/proEasyPay.vue"; // 悦易付
import proPropertyPlatform from "../views/pc/products/proPropertyPlatform.vue"; // 悦云物业资管平台
import proAssetManagement from "../views/pc/products/proAssetManagement.vue"; // 悦资管
import proPricePackage from "../views/pc/products/proPricePackage.vue"; // 悦资管价格套餐
import solutions from "../views/pc/solutions/solutions.vue"; // 企业数字化解决方案
// import solutionsBusiness from "../views/pc/solutions/solutionsBusiness.vue"; // 商业解决方案
import wisdomBusiness from "../views/pc/solutions/wisdomBusiness.vue"; // 商业解决方案

import solutionsProperty from "../views/pc/solutions/solutionsProperty.vue"; // 物业解决方案
import news from "../views/pc/news/news.vue"; // 新闻
import newsDetails from "../views/pc/news/newsDetails.vue"; // 新闻详情
import newsDetailsSec from "../views/pc/news/newsDetailsSec.vue";
import newsDetailsThi from "../views/pc/news/newsDetailsThi.vue";
import contact from "../views/pc/contact/contact.vue"; // 联系我们
// wap
import indexWap from "../views/wap/index/index.vue"; // 首页
import proNeighborW from "../views/wap/products/proNeighbor.vue"; // 产品
import proUnifiedPortalW from "../views/wap/products/proUnifiedPortal.vue"; // 统一门户
import proWebsiteW from "../views/wap/products/proWebsite.vue"; // 企业官网
import proProcessCenterW from "../views/wap/products/proProcessCenter.vue"; // 流程中心
import proEhrW from "../views/wap/products/proEhr.vue"; // EHR
import proPlatformW from "../views/wap/products/proPlatform.vue"; // 钉钉集成平台
import proIntegratedW from "../views/wap/products/proIntegrated.vue"; // 业财一体化
import proBusinessDistrictW from "../views/wap/products/proBusinessDistrict.vue"; // 悦商圈
import proCentrerYueW from "../views/wap/products/proCentrerYue.vue"; // 悦中心
import proPouchW from "../views/wap/products/proPouch.vue"; // 悦荷包
import proCommercialBenefitsW from "../views/wap/products/proCommercialBenefits.vue"; // 悦商惠
import proBusPlatformW from "../views/wap/products/proBusPlatform.vue"; // 悦云商业资管平台
import proAdminYueW from "../views/wap/products/proAdminYue.vue"; // 悦云管
import proEasyPayW from "../views/wap/products/proEasyPay.vue"; // 悦易付
import proPropertyPlatformW from "../views/wap/products/proPropertyPlatform.vue"; // 悦云物业资管平台

import newsWap from "../views/wap/news/news.vue"; // 新闻
import newsDetailsWap from "../views/wap/news/newsDetails.vue"; // 新闻详情
import newsDetailsWSec from "../views/wap/news/newsDetailsSec.vue";
import newsDetailsWThi from "../views/wap/news/newsDetailsThi.vue";
import newsDetailsNo from "../views/wap/news/newsDetailsNo.vue";
import solutionsWap from "../views/wap/solutions/solutions.vue"; // 企业数字化解决方案
import solutionsBusinessWap from "../views/wap/solutions/solutionsBusiness.vue"; // 商业解决方案
import solutionsPropertyWap from "../views/wap/solutions/solutionsProperty.vue"; // 物业解决方案
import aboutWap from "../views/wap/about/about.vue"; // 关于我们
import contactWap from "../views/wap/contact/contact.vue"; // 联系我们

Vue.use(VueRouter);

const routes = [
  // 首页
  {
    path: "/",
    name: "index",
    component: index
  },
  // 首页2
  {
    path: "/pc/home",
    name: "home",
    component: home
  },
  // 悦邻家
  {
    path: "/pc/proNeighbor",
    name: "proNeighbor",
    component: proNeighbor
  },
  // 统一门户
  {
    path: "/pc/proUnifiedPortal",
    name: "proUnifiedPortal",
    component: proUnifiedPortal
  },
  // 企业官网
  {
    path: "/pc/proWebsite",
    name: "proWebsite",
    component: proWebsite
  },
  // 流程中心
  {
    path: "/pc/proProcessCenter",
    name: "proProcessCenter",
    component: proProcessCenter
  },
  // EHR
  {
    path: "/pc/proEhr",
    name: "proEhr",
    component: proEhr
  },
  // 钉钉集成平台
  {
    path: "/pc/proPlatform",
    name: "proPlatform",
    component: proPlatform
  },
  // 业财一体化
  {
    path: "/pc/proIntegrated",
    name: "proIntegrated",
    component: proIntegrated
  },
  // 悦商圈
  {
    path: "/pc/proBusinessDistrict",
    name: "proBusinessDistrict",
    component: proBusinessDistrict
  },
  // 悦中心
  {
    path: "/pc/proCentrerYue",
    name: "proCentrerYue",
    component: proCentrerYue
  },
  // 悦荷包
  {
    path: "/pc/proPouch",
    name: "proPouch",
    component: proPouch
  },
  // 悦商惠
  {
    path: "/pc/proCommercialBenefits",
    name: "proCommercialBenefits",
    component: proCommercialBenefits
  },
  // 悦云商业资管平台
  {
    path: "/pc/proBusPlatform",
    name: "proBusPlatform",
    component: proBusPlatform
  },
  // 悦云管
  {
    path: "/pc/proAdminYue",
    name: "proAdminYue",
    component: proAdminYue
  },
  // 悦易付
  {
    path: "/pc/proEasyPay",
    name: "proEasyPay",
    component: proEasyPay
  },
  // 悦云物业资管平台
  {
    path: "/pc/proPropertyPlatform",
    name: "proPropertyPlatform",
    component: proPropertyPlatform
  },
  // 悦资管
  {
    path: "/pc/proAssetManagement",
    name: "proAssetManagement",
    component: proAssetManagement
  },
  // 悦资管价格套餐
  {
    path: "/pc/proPricePackage",
    name: "proPricePackage",
    component: proPricePackage
  },
  // 企业数字化解决方案
  {
    path: "/pc/solutions",
    name: "solutions",
    component: solutions
  },
  // 商业解决方案
  {
    path: "/pc/wisdomBusiness",
    name: "wisdomBusiness",
    component: wisdomBusiness
  },
  // 物业解决方案
  {
    path: "/pc/solutionsProperty",
    name: "solutionsProperty",
    component: solutionsProperty
  },
  // 新闻
  {
    path: "/pc/news",
    name: "news",
    component: news
  },
  // 新闻详情
  {
    path: "/pc/newsDetails",
    name: "newsDetails",
    component: newsDetails
  },
  {
    path: "/pc/newsDetailsSec",
    name: "newsDetailsSec",
    component: newsDetailsSec
  },
  {
    path: "/pc/newsDetailsThi",
    name: "newsDetailsThi",
    component: newsDetailsThi
  },
  // 联系我们
  {
    path: "/pc/contact",
    name: "contact",
    component: contact
  },
  // 关于我们
  {
    path: "/pc/about",
    name: "about",
    component: about
  },
  // wap
  // 首页
  {
    path: "/wap",
    name: "index",
    component: indexWap
  },
  // 产品
  {
    path: "/wap/proNeighbor",
    name: "proNeighbor",
    component: proNeighborW
  },
  // 统一门户
  {
    path: "/wap/proUnifiedPortal",
    name: "proUnifiedPortal",
    component: proUnifiedPortalW
  },
  // 企业官网
  {
    path: "/wap/proWebsite",
    name: "proWebsite",
    component: proWebsiteW
  },
  // 流程中心
  {
    path: "/wap/proProcessCenter",
    name: "proProcessCenter",
    component: proProcessCenterW
  },
  // EHR
  {
    path: "/wap/proEhr",
    name: "proEhr",
    component: proEhrW
  },
  // 钉钉集成平台
  {
    path: "/wap/proPlatform",
    name: "proPlatform",
    component: proPlatformW
  },
  // 业财一体化
  {
    path: "/wap/proIntegrated",
    name: "proIntegrated",
    component: proIntegratedW
  },
  // 悦商圈
  {
    path: "/wap/proBusinessDistrict",
    name: "proBusinessDistrict",
    component: proBusinessDistrictW
  },
  // 悦中心
  {
    path: "/wap/proCentrerYue",
    name: "proCentrerYue",
    component: proCentrerYueW
  },
  // 悦荷包
  {
    path: "/wap/proPouch",
    name: "proPouch",
    component: proPouchW
  },
  // 悦商惠
  {
    path: "/wap/proCommercialBenefits",
    name: "proCommercialBenefits",
    component: proCommercialBenefitsW
  },
  // 悦云商业资管平台
  {
    path: "/wap/proBusPlatform",
    name: "proBusPlatform",
    component: proBusPlatformW
  },
  // 悦云管
  {
    path: "/wap/proAdminYue",
    name: "proAdminYue",
    component: proAdminYueW
  },
  // 悦易付
  {
    path: "/wap/proEasyPay",
    name: "proEasyPay",
    component: proEasyPayW
  },
  // 悦云物业资管平台
  {
    path: "/wap/proPropertyPlatform",
    name: "proPropertyPlatform",
    component: proPropertyPlatformW
  },
  // 新闻
  {
    path: "/wap/news",
    name: "news",
    component: newsWap
  },
  // 新闻详情
  {
    path: "/wap/newsDetails",
    name: "newsDetails",
    component: newsDetailsWap
  },
  {
    path: "/wap/newsDetailsSec",
    name: "newsDetailsSec",
    component: newsDetailsWSec
  },
  {
    path: "/wap/newsDetailsThi",
    name: "newsDetailsThi",
    component: newsDetailsWThi
  },
  {
    path: "/wap/newsDetailsNo",
    name: "newsDetailsNo",
    component: newsDetailsNo
  },
  // 企业数字化解决方案
  {
    path: "/wap/solutions",
    name: "solutions",
    component: solutionsWap
  },
  // 商业解决方案
  {
    path: "/wap/solutionsBusiness",
    name: "solutionsBusiness",
    component: solutionsBusinessWap
  },
  // 物业解决方案
  {
    path: "/wap/solutionsProperty",
    name: "solutionsProperty",
    component: solutionsPropertyWap
  },
  // 联系我们
  {
    path: "/wap/contact",
    name: "contact",
    component: contactWap
  },
  // 关于我们
  {
    path: "/wap/about",
    name: "about",
    component: aboutWap
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
