<template>
  <div class="con-wrapper">
    <div class="wap">
      <header-con />
      <div class="m-main">
        <div class="banner-single">
          <div class="banner"></div>
          <div class="banner-con">
            <h1>悦云商业资管平台</h1>
            <span></span>
            <p class="tit-desc">
              专注于商业资产管理和运营，以资产经营视角将复杂的资产管理全周期分为租前、租中、租后不同阶段，实现对持有型资产进行有效的经营管理和数据分析，最终实现业财一体化，帮助管理合同、租客、财务资产评估等核心业务
            </p>
            <div class="banner-btn">
              <router-link :to="{ path: '/wap/contact' }" class="btn-ask"
                >免费试用</router-link
              >
            </div>
          </div>
        </div>
        <div :class="topNavDistance > 390 ? 'fixed' : ''" class="scroll-nav">
          <div class="scroll-navCon">
            <ul class="main-wrap flex flex-middle">
              <li
                v-for="(item, index) in allMenuList"
                :key="item.id"
                :class="{ active: active == index }"
                @click="scrollTo(index)"
              >
                {{ item.value }}
                <span></span>
              </li>
            </ul>
          </div>
        </div>
        <div class="product-scroll">
          <product-advantageWap :advantage="advantageList" class="scroll-con" />
          <product-function :functionPro="functionList" class="scroll-con" />
          <!-- <div class="main-single wap-business scroll-con">
            <div>
              <div class="tit-box">
                <h2>业务场景</h2>
              </div>
              <div class="con-box">
                <div class="tab-cont">
                  <div class="w100">
                    <div class="business-con">
                      <div class="business-tit">
                        <h2>合同签订，实现合同的线上管理，快速打印及预警；</h2>
                        <span></span>
                      </div>
                      <div class="business-tabCon clearfloat">
                        <img
                          src="../../../assets/img/wap/product_bus_pic1.png"
                          class="fl"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="w100">
                    <div class="business-con">
                      <div class="business-tit">
                        <h2>
                          装修巡查，实现店铺装修和现场巡检中的全过程跟踪；
                        </h2>
                        <span></span>
                      </div>
                      <div class="business-tabCon clearfloat">
                        <img
                          src="../../../assets/img/wap/product_bus_pic2.png"
                          class="fr"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="w100">
                    <div class="business-con">
                      <div class="business-tit">
                        <h2>
                          账单缴费，支持多种支付方式和收银方式，也提供业主APP端自助缴费；
                        </h2>
                        <span></span>
                      </div>
                      <div class="business-tabCon clearfloat">
                        <img
                          src="../../../assets/img/wap/product_bus_pic1.png"
                          class="fl"
                        />
                      </div>
                    </div>
                    <div class="w100">
                      <div class="business-con">
                        <div class="business-tit">
                          <h2>
                            招商推广，定制招商策略，拓宽招商渠道，管理活动，对比分析；
                          </h2>
                          <span></span>
                        </div>
                        <div class="business-tabCon clearfloat">
                          <img
                            src="../../../assets/img/wap/product_bus_pic1.png"
                            class="fl"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="main-single wap-operate scroll-con">
            <div>
              <div class="tit-box">
                <h2>合作案例</h2>
              </div>
              <div class="con-box">
                <div class="tab-cont">
                  <ul class="clearfloat">
                    <li class="operate-li">
                      <div class="operate-li-con">
                        <img
                          src="../../../assets/img/product_operate_icon1.png"
                        />
                        <h3>株洲大汉悦中心</h3>
                      </div>
                    </li>
                    <li class="operate-li">
                      <div class="operate-li-con">
                        <img
                          src="../../../assets/img/product_operate_icon1.png"
                        />
                        <h3>邵阳大汉悦中心</h3>
                      </div>
                    </li>
                    <li class="operate-li">
                      <div class="operate-li-con">
                        <img
                          src="../../../assets/img/product_operate_icon1.png"
                        />
                        <h3>邵东宝庆里商业步行街</h3>
                      </div>
                    </li>
                    <li class="operate-li">
                      <div class="operate-li-con">
                        <img
                          src="../../../assets/img/product_operate_icon1.png"
                        />
                        <h3>桃江假日广场</h3>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fooConWap">
        <footer-conTopW />
        <footer-conW />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerConWap"; // 头部
import ProductAdvantageWap from "@/components/products/productAdvantageWap"; // 产品优势
import ProductFunction from "@/components/products/productFunctionWap"; // 产品功能
import FooterConTopW from "@/components/footerConTopW"; // 底部免费试用
import FooterConW from "@/components/footerConWap"; // 底部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
export default {
  name: "proBusPlatform",
  components: {
    HeaderCon,
    FooterConTopW,
    FooterConW,
    ProductAdvantageWap,
    ProductFunction
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      topNavDistance: 0,
      active: 0,
      allMenuList: [
        {
          value: "产品优势",
          id: "1"
        },
        {
          value: "产品功能",
          id: "2"
        },
        // {
        //   value: "业务场景",
        //   id: "3"
        // },
        {
          value: "合作案例",
          id: "3"
        }
      ],
      //产品优势内容
      advantageList: [
        {
          id: "1",
          tit: "业务操作规范化",
          titCon: "规范现有的商业的业务规范，让管理更加简便，降低业务的风险；",
          src: require("../../../assets/img/funIcon/proFun_icon31.png")
        },
        {
          id: "2",
          tit: "招商运营数字化",
          titCon:
            "广开渠道，线上、线下相结合，拓展商机来源提供招商运营相关的工具，帮助业务人员快速招商，定价；让运营人员实现现场巡检、营业额监控；",
          src: require("../../../assets/img/funIcon/proFun_icon32.png")
        },
        {
          id: "3",
          tit: "工作流程在线化",
          titCon: "提供在线审批的工作流，实现无纸化，让工作更加便捷；",
          src: require("../../../assets/img/funIcon/proFun_icon33.png")
        },
        {
          id: "4",
          tit: "订单收费透明化",
          titCon: "根据合同提供相关的收费订单，让收银变得简单，提升收缴率；",
          src: require("../../../assets/img/funIcon/proFun_icon34.png")
        },
        {
          id: "5",
          tit: "决策分析数字化",
          titCon:
            "通过业务过程数据沉淀和智能模型分析以及可视化决策平台，将各项核心指标以直观方式进行展现，为下一阶段的运营决策提供依据。",
          src: require("../../../assets/img/funIcon/proFun_icon35.png")
        },
        {
          id: "6",
          tit: "业务财务一体化",
          titCon: "对接财务系统，自动生成凭证，提供标准的接口服务；",
          src: require("../../../assets/img/funIcon/proFun_icon36.png")
        }
      ],
      // 产品功能内容
      functionList: [
        {
          id: "1",
          tit: "资产管理",
          con: "资产管理一目了然，结合全局观资源视角更好帮助客户提高项目收益；",
          src: require("../../../assets/img/funIcon1/proFunT_icon46.png")
        },
        {
          id: "2",
          tit: "招商管理",
          con:
            "提供商机跟踪，帮助招商人员快速成交转化为客户；价格策略定位，让管理人员可以快速对比合同的收益；",
          src: require("../../../assets/img/funIcon1/proFunT_icon47.png")
        },
        {
          id: "3",
          tit: "合同管理",
          con:
            "提供线上的客户管理并适用于各种合同场景，满足业务根据固定金额、抽成、保底+抽成等进行收费，并能实现合同的续租、转租、退租等操作；",
          src: require("../../../assets/img/funIcon1/proFunT_icon48.png")
        },
        {
          id: "4",
          tit: "收费管理",
          con:
            "提供全方位的应收账单、收款管理，让收费员快速收费，商业收费数据全盘在握；",
          src: require("../../../assets/img/funIcon1/proFunT_icon49.png")
        },
        {
          id: "5",
          tit: "财务管理",
          con: "自动根据业务收费情况生成相关的财务凭证，并提供相关的接口服务；",
          src: require("../../../assets/img/funIcon1/proFunT_icon50.png")
        },
        {
          id: "6",
          tit: "现场管理",
          con:
            "根据现场情况满足商业中心现场装修管理、巡检管理并快速获取客流信息和营业额信息；",
          src: require("../../../assets/img/funIcon1/proFunT_icon51.png")
        },
        {
          id: "7",
          tit: "仪表管理",
          con:
            "提供智能仪表和传统仪表的管理，设置仪表和房间的关系，让仪表管理更加灵活",
          src: require("../../../assets/img/funIcon1/proFunT_icon52.png")
        },
        {
          id: "8",
          tit: "报表管理",
          con:
            "提供日收费报表、权责收费报表等报表信息，也可以快速定制一些数据报表；",
          src: require("../../../assets/img/funIcon1/proFunT_icon53.png")
        }
      ],
      loading: true,
      noScroll: false
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleNavScroll);
    window.addEventListener("scroll", this.onScroll, false);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.handleNavScroll);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    handleNavScroll() {
      // 二级导航滚动到指定位置固定
      this.topNavDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    // 二级导航+内容联动滚动监听
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(".scroll-con");
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });
      // 获取当前文档流的 scrollTop
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop += 52 * window.devicePixelRatio;
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (
          scrollTop >=
          (this.noScroll ? (offsetTopArr[n] -= 60) : offsetTopArr[n])
        ) {
          navIndex = n;
        }
      }
      if (this.active == 3) {
        this.active = 3;
      } else {
        this.active = navIndex;
      }
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      if (index == 3) {
        this.active = 3;
      } else {
        this.active = index;
      }
      var targetOffsetTop = document.querySelector(
        `.product-scroll .scroll-con:nth-child(${index + 1})`
      ).offsetTop;
      targetOffsetTop -= 52 * window.devicePixelRatio;
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
      const STEP = 50;
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        if (this.topNavDistance < 390) {
          targetOffsetTop -= 60;
          this.noScroll = true;
        } else {
          this.noScroll = false;
        }
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    }
  }
};
</script>
