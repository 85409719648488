<template>
  <div class="con-wrapper">
    <div class="wap">
      <header-con @getClickData="getClickDataFunc" />
      <div class="m-main">
        <div class="index-wap">
          <div class="wap-banner">
            <div class="banner">
              <img
                src="../../../assets/img/index_common_slogan.png"
                class="wap-slogon"
              />
              <div class="ban-ani-wap abs-lt full">
                <img
                  src="../../../assets/img/wap/index_banner_bg_small_d.png"
                  class="ban-s-d-wap abs-lt"
                />
                <div class="main-wrap">
                  <img
                    src="../../../assets/img/wap/index_banner_bg_d.png"
                    class="ban-d-wap abs-rt"
                    style="transform: translate(39.85px, -3.4px);"
                  />
                  <img
                    src="../../../assets/img/wap/yuan.png"
                    class="ban-yuan-wap abs-rt"
                  />
                </div>
              </div>
              <div class="main-wrap flex">
                <div class="banner-main flex">
                  <div class="banner-box">
                    <div class="banner-boxs"></div>
                    <span>
                      <swiper :options="swiperOption" ref="mySwiper">
                        <swiper-slide>
                          <div class="class-a">
                            <router-link :to="{ path: '/wap/newsDetails' }">
                              <img src="../../../assets/img/wap/indexBg1.png" />
                              <div class="banner-texts">
                                <h2 class="h2-1">
                                  数字化建设，助推大物<br />业精细管理落地成型
                                </h2>
                                <!-- <h2 class="h2-2">
                                  大物业对数字化....
                                </h2> -->
                                <p class="p-1">
                                  2020年，物业板块经历了一波显著大涨，估值迅速上升到六七十倍，虽近期有所回落，但仍保持四十倍左右。物业成为资本市场的宠儿，除了…
                                </p>
                              </div>
                            </router-link>
                          </div>
                        </swiper-slide>
                        <swiper-slide>
                          <div class="class-a">
                            <router-link :to="{ path: '/wap/newsDetailsSec' }">
                              <img src="../../../assets/img/wap/indexBg2.png" />
                              <div class="banner-texts">
                                <h2 class="h2-1">
                                  存量竞争，传统商业地<br />产靠什么赢得未来？
                                </h2>
                                <!-- <h2 class="h2-2">商圈大运营模式</h2> -->
                                <p class="p-1">
                                  中国消费者的需求正在不断变化。麦肯锡2017年全球消费者信心调查显示，近六成中国消费者的消费行为在2016—2017年发生了显著变化。90求…
                                </p>
                              </div>
                            </router-link>
                          </div>
                        </swiper-slide>
                        <swiper-slide>
                          <div class="class-a">
                            <router-link :to="{ path: '/wap/newsDetailsThi' }">
                              <img src="../../../assets/img/wap/indexBg3.png" />
                              <div class="banner-texts">
                                <h2 class="h2-1">
                                  日曝光13万+的线上<br />流量转化秘籍是？
                                </h2>
                                <!-- <h2 class="h2-2">转化秘籍是？</h2> -->
                                <p class="p-1">
                                  悦商圈在株洲·大汉悦中心实施以来，首月单日曝光量峰值高达130000+，新注册会员数已突10000+，为线下新增订单更是逼近5000单为消费…
                                </p>
                              </div>
                            </router-link>
                          </div>
                        </swiper-slide>
                        <div
                          class="swiper-pagination swiper-pagination-bannerW"
                          slot="pagination"
                        ></div>
                      </swiper>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sectWap section-conW">
          <div class="main-wrap">
            <div class="tit-box pr">
              <h2>做一流的产业互联网解决方案服务商</h2>
              <p>
                专注商业综合体不动产管理，构建智慧管理生活方式
              </p>
            </div>
            <div class="cont-box">
              <div class="tab-cont">
                <span>
                  <div class="tab-cont-box">
                    <div>
                      <ul class="indexW-solu-list clearfloat">
                        <li>
                          <div class="normal-box">
                            <img src="../../../assets/img/section_pic1.png" />
                            <h4 title="企业数字化" class="pr ellips">
                              企业数字化
                            </h4>
                            <p class="ellips2">
                              数据与价值双驱动数字产业模式升级，造就覆盖全价值链的智慧新业态
                            </p>
                          </div>
                        </li>
                        <li>
                          <div class="normal-box">
                            <img src="../../../assets/img/section_pic2.png" />
                            <h4 title="智慧商业" class="pr ellips">
                              智慧商业
                            </h4>
                            <p class="ellips2">
                              全景化商业数字资产管理，实现业财融合的人、货、场消费洞察
                            </p>
                          </div>
                        </li>
                        <li>
                          <div class="normal-box">
                            <img src="../../../assets/img/section_pic3.png" />
                            <h4 title="智慧物业" class="pr ellips">
                              智慧物业
                            </h4>
                            <p class="ellips2">
                              建立在精细化管理中的智能服务，实现物业坪效增值，打造智慧小区
                            </p>
                          </div>
                        </li>
                        <li>
                          <div class="normal-box">
                            <img src="../../../assets/img/section_pic4.png" />
                            <h4 title="O&O大运营" class="pr ellips">
                              O&O大运营
                            </h4>
                            <p class="ellips2">
                              优秀的数字化转型在地服务，解决企业转型组织痛点
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="sectWap section-productW">
          <div class="main-wrap">
            <div class="cont-box">
              <div class="left-box clearfloat">
                <div class="tit-box">
                  <h2>数字化产品</h2>
                  <p>
                    悦云科技通过大数据共享、多平台联动、智能设备、物联网等技术基础，开发多种数字化产品为实体企业创造更大的价值
                  </p>
                </div>
                <div class="product-anid">
                  <div>
                    <img
                      src="../../../assets/img/wap/section_product_pic.png"
                      alt="数字化产品"
                    />
                  </div>
                </div>
              </div>
              <div class="right-box">
                <div class="product-tab-tit pr">
                  <ul class="flex">
                    <li
                      @click="productTab('0')"
                      :class="{ active: this.productValue == 0 }"
                    >
                      <div class="li-box abs-lm nowrap full flex flex-bottom">
                        <h3>企业数字化产品</h3>
                      </div>
                    </li>
                    <li
                      @click="productTab('1')"
                      :class="{ active: this.productValue == 1 }"
                    >
                      <div class="li-box abs-lm nowrap full flex flex-bottom">
                        <h3>智慧商业产品</h3>
                      </div>
                    </li>
                    <li
                      @click="productTab('2')"
                      :class="{ active: this.productValue == 2 }"
                    >
                      <div class="li-box abs-lm nowrap full flex flex-bottom">
                        <h3>智慧物业产品</h3>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="product-tab-box">
                  <div class="tab-head flex"></div>
                  <div class="tab-cont">
                    <span>
                      <div class="scroll-box">
                        <div
                          class="scroll-main nowrap"
                          style="transform:translate(0px, 0);"
                        >
                          <transition name="slide-fade">
                            <div v-if="this.productValue == '0'">
                              <ul class="item-ul">
                                <div>
                                  <div>
                                    <li>
                                      <router-link
                                        :to="{ path: '/wap/proUnifiedPortal' }"
                                      >
                                        <a class="">
                                          <h5>
                                            <i class="i-list-a"
                                              ><img
                                                src="../../../assets/img/section_product_icon1.png"/></i
                                            >统一门户
                                          </h5>
                                          <p class="ellips2">
                                            单点登录，免去多系统频繁切换烦恼，展示企业综合实力
                                          </p>
                                        </a>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{ path: '/wap/proWebsite' }"
                                      >
                                        <a class="">
                                          <h5>
                                            <i class="i-list-a"
                                              ><img
                                                src="../../../assets/img/section_product_icon1.png"/></i
                                            >企业官网
                                          </h5>
                                          <p class="ellips2">
                                            企业动态发声，团队及产品形象展示
                                          </p>
                                        </a>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{ path: '/wap/proProcessCenter' }"
                                      >
                                        <a class="">
                                          <h5>
                                            <i class="i-list-a"
                                              ><img
                                                src="../../../assets/img/section_product_icon1.png"/></i
                                            >流程中心
                                          </h5>
                                          <p class="ellips2">
                                            企业运营标准权限管理，线上高效审批
                                          </p>
                                        </a>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{ path: '/wap/proEhr' }"
                                      >
                                        <a class="">
                                          <h5>
                                            <i class="i-list-a"
                                              ><img
                                                src="../../../assets/img/section_product_icon1.png"/></i
                                            >EHR
                                          </h5>
                                          <p class="ellips2">
                                            数字化人力资源管理，搭建最全面的人力资源
                                          </p>
                                        </a>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{ path: '/wap/proPlatform' }"
                                      >
                                        <a class="">
                                          <h5>
                                            <i class="i-list-a"
                                              ><img
                                                src="../../../assets/img/section_product_icon1.png"/></i
                                            >钉钉集成平台
                                          </h5>
                                          <p class="ellips2">
                                            基于钉钉生态的移动办公整合方案
                                          </p>
                                        </a>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{ path: '/wap/proIntegrated' }"
                                      >
                                        <a class="">
                                          <h5>
                                            <i class="i-list-a"
                                              ><img
                                                src="../../../assets/img/section_product_icon1.png"/></i
                                            >业财一体化
                                          </h5>
                                          <p class="ellips2">
                                            业财融合，实现财务目标与业务目标的双重管控
                                          </p>
                                        </a>
                                      </router-link>
                                    </li>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </transition>
                          <transition name="slide-fade">
                            <div v-if="this.productValue == '1'">
                              <ul class="item-ul">
                                <div>
                                  <div>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/wap/proBusinessDistrict'
                                        }"
                                      >
                                        <a class="">
                                          <h5>
                                            <i class="i-list-c"
                                              ><img
                                                src="../../../assets/img/section_product_icon3.png"/></i
                                            >悦商圈
                                          </h5>
                                          <p class="ellips2">
                                            商业精英生活助手，吃、喝、玩、乐、智慧生活一键搞定
                                          </p>
                                        </a>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{ path: '/wap/proCentrerYue' }"
                                      >
                                        <a class="">
                                          <h5>
                                            <i class="i-list-d"
                                              ><img
                                                src="../../../assets/img/section_product_icon4.png"/></i
                                            >悦中心
                                          </h5>
                                          <p class="ellips2">
                                            基于地理位置的商圈专属交易平台，拼团、砍价、抽奖、超实惠组合玩转购物中心
                                          </p>
                                        </a>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/wap/proCommercialBenefits'
                                        }"
                                      >
                                        <a class="">
                                          <h5>
                                            <i class="i-list-c"
                                              ><img
                                                src="../../../assets/img/section_product_icon3.png"/></i
                                            >悦商惠
                                          </h5>
                                          <p class="ellips2">
                                            基于真实商圈的商家经营助手，共享购物中心
                                          </p>
                                        </a>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{ path: '/wap/proPouch' }"
                                      >
                                        <a class="">
                                          <h5>
                                            <i class="i-list-d"
                                              ><img
                                                src="../../../assets/img/section_product_icon4.png"/></i
                                            >悦荷包
                                          </h5>
                                          <p class="ellips2">
                                            购物中心支付方式解决方案，支付即积分，精准获取一手消费数据
                                          </p>
                                        </a>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{ path: '/wap/proBusPlatform' }"
                                      >
                                        <a class="">
                                          <h5>
                                            <i class="i-list-a"
                                              ><img
                                                src="../../../assets/img/section_product_icon1.png"/></i
                                            >悦云商业资管平台
                                          </h5>
                                          <p class="ellips2">
                                            专业的购物中心招商租赁管理解决方案
                                          </p>
                                        </a>
                                      </router-link>
                                    </li>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </transition>
                          <transition name="slide-fade">
                            <div v-if="this.productValue == '2'">
                              <ul class="item-ul">
                                <li>
                                  <router-link
                                    :to="{ path: '/wap/proEasyPay' }"
                                  >
                                    <a class="">
                                      <h5>
                                        <i class="i-list-a"
                                          ><img
                                            src="../../../assets/img/section_product_icon3.png"/></i
                                        >悦易付
                                      </h5>
                                      <p class="ellips2">
                                        物业缴费移动POS支付助手
                                      </p>
                                    </a>
                                  </router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ path: '/wap/proNeighbor' }"
                                  >
                                    <a class="">
                                      <h5>
                                        <i class="i-list-c"
                                          ><img
                                            src="../../../assets/img/section_product_icon3.png"/></i
                                        >悦邻家
                                      </h5>
                                      <p class="ellips2">
                                        业主智慧生活必备APP
                                      </p>
                                    </a>
                                  </router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ path: '/wap/proAdminYue' }"
                                  >
                                    <a class="">
                                      <h5>
                                        <i class="i-list-c"
                                          ><img
                                            src="../../../assets/img/section_product_icon3.png"/></i
                                        >悦云管
                                      </h5>
                                      <p class="ellips2">
                                        管理方移动办公助手
                                      </p>
                                    </a>
                                  </router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ path: '/wap/proPropertyPlatform' }"
                                  >
                                    <a class="">
                                      <h5>
                                        <i class="i-list-c"
                                          ><img
                                            src="../../../assets/img/section_product_icon1.png"/></i
                                        >悦云物业资管平台
                                      </h5>
                                      <p class="ellips2">
                                        全场景精细化物业资产管理解决方案
                                      </p>
                                    </a>
                                  </router-link>
                                </li>
                              </ul>
                            </div>
                          </transition>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sectWap section-solveW">
          <div class="main-wrap">
            <div class="tit-box pr">
              <h2>数字化解决方案</h2>
              <p>
                运用数字科技服务实体产业，助力各行业降低成本、提升效率、模式升级，和客户一起携手跨越、重塑增长
              </p>
            </div>
            <div class="con-box">
              <div class="tab-head flex">
                <ul class="flex">
                  <li @click="tab('0')" :class="{ active: this.value == 0 }">
                    企业数字化<i class="li-active"></i>
                  </li>
                  <li @click="tab('1')" :class="{ active: this.value == 1 }">
                    智慧商业<i class="li-active"></i>
                  </li>
                  <li @click="tab('2')" :class="{ active: this.value == 2 }">
                    智慧物业<i></i>
                  </li>
                </ul>
              </div>
              <div class="tab-cont">
                <span>
                  <div class="scroll-box">
                    <div
                      class="scroll-main nowrap"
                      style="transform:translate(0px, 0);"
                    >
                      <transition name="slide-fade">
                        <div v-if="this.value == '0'">
                          <div class="scroll-main-con">
                            <img
                              src="../../../assets/img/wap/section_solve_pic2.png"
                            />
                          </div>
                        </div>
                      </transition>
                      <transition name="slide-fade">
                        <div v-if="this.value == '1'">
                          <div class="scroll-main-con">
                            <img
                              src="../../../assets/img/wap/section_solve_pic.png"
                            />
                          </div>
                        </div>
                      </transition>
                      <transition name="slide-fade">
                        <div v-if="this.value == '2'">
                          <div class="scroll-main-con">
                            <img
                              src="../../../assets/img/wap/section_solve_pic1.png"
                            />
                          </div>
                        </div>
                      </transition>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="sectWap section-solveW section-operateW">
          <div class="main-wrap">
            <div class="tit-box">
              <h2>O&O大运营</h2>
              <p>
                商业数字资产守护者，专业的精细化数字运营团队，助力商业管理数字化转型
              </p>
            </div>
            <div class="con-box">
              <div class="tab-cont">
                <span>
                  <ul class="clearfloat">
                    <li class="operate-li">
                      <div class="operate-li-left">
                        <h2>全托管运营</h2>
                        <p>B端C端运营双管齐下，为购物中心生产最全面数字资产</p>
                      </div>
                      <div class="operate-li-right">
                        <img
                          src="../../../assets/img/section_operate_icon1.png"
                        />
                      </div>
                    </li>
                    <li class="operate-li">
                      <div class="operate-li-left">
                        <h2>半托管运营</h2>
                        <p>
                          C端运营保用户活跃，定制化培训为商业管理线上运营赋能
                        </p>
                      </div>
                      <div class="operate-li-right">
                        <img
                          src="../../../assets/img/section_operate_icon2.png"
                        />
                      </div>
                    </li>
                    <li class="operate-li">
                      <div class="operate-li-left">
                        <h2>O&O活动运营</h2>
                        <p>以数据为导向的定制化运营服务</p>
                      </div>
                      <div class="operate-li-right">
                        <img
                          src="../../../assets/img/section_operate_icon3.png"
                        />
                      </div>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div ref="cooperation" class="sectWap section-cooperationW">
          <img src="../../../assets/img/index_coo_bg.png" class="coo-bgL" />
          <img src="../../../assets/img/index_coo_bg1.png" class="coo-bgR" />
          <div class="main-wrap">
            <div class="tit-box pr">
              <h2>合作案例</h2>
            </div>
            <div class="con-box">
              <div class="tab-cont">
                <span>
                  <swiper
                    :options="swiperOptionOp"
                    ref="opSwiper"
                    style="height:100%"
                  >
                    <swiper-slide>
                      <ul>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic1.png"
                              />
                              <h4>绿地中心</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic2.png"
                              />
                              <h4>南宁富宁大厦</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic3.png"
                              />
                              <h4>美莱美Vpark城市广场</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic4.png"
                              />
                              <h4>湘潭中心</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic5.png"
                              />
                              <h4>长沙大汉金桥国际</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic6.png"
                              />
                              <h4>株洲大汉悦中心</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic7.png"
                              />
                              <h4>长沙华美达广场酒店</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic8.png"
                              />
                              <h4>邵阳大汉悦中心</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic9.png"
                              />
                              <h4>绿地缤纷荟</h4>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </swiper-slide>
                    <swiper-slide>
                      <ul>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic10.png"
                              />
                              <h4>长沙大汉月亮河畔</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic11.png"
                              />
                              <h4>沅陵大汉新城</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic12.png"
                              />
                              <h4>新化大汉龙城</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic13.png"
                              />
                              <h4>吉首大汉锦绣乾城</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic14.png"
                              />
                              <h4>大汉资江城</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic15.png"
                              />
                              <h4>邵东宝庆里商业街</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic16.png"
                              />
                              <h4>长沙华美达广场酒店</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic17.png"
                              />
                              <h4>岳阳大汉新城</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="img-box pr">
                              <img
                                src="../../../assets/img/section_cooperation_pic18.png"
                              />
                              <h4>宜章大汉龙城</h4>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </swiper-slide>
                    <div
                      class="swiper-pagination indexWap-page"
                      slot="pagination"
                    ></div>
                  </swiper>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="sectWap section-serveW">
          <div class="main-wrap">
            <div class="tit-box pr">
              <h2>一站式服务</h2>
              <p>
                从售前评估、系统部署到系统培训的使用，都提供全方位的技术支持
              </p>
            </div>
            <div class="con-box">
              <div class="tab-cont">
                <span>
                  <ul class="clearfloat">
                    <li>
                      <div class="normal-box">
                        <img src="../../../assets/img/section_serve_pic1.png" />
                        <h4 title="售前评估">
                          售前评估
                        </h4>
                        <p>
                          为您定制专属的解决方案
                        </p>
                      </div>
                    </li>
                    <li>
                      <div class="normal-box">
                        <img src="../../../assets/img/section_serve_pic2.png" />
                        <h4 title="快速部署">
                          快速部署
                        </h4>
                        <p>
                          无需购买单独服务器，注册即可快速部署
                        </p>
                      </div>
                    </li>
                    <li>
                      <div class="normal-box">
                        <img src="../../../assets/img/section_serve_pic3.png" />
                        <h4 title="帮助文档">
                          帮助文档
                        </h4>
                        <p>
                          完善的文档体系快速帮助您答疑解惑
                        </p>
                      </div>
                    </li>
                    <li>
                      <div class="normal-box">
                        <img src="../../../assets/img/section_serve_pic4.png" />
                        <h4 title="客服支持">
                          客服支持
                        </h4>
                        <p>
                          提供在线指导、电话支持，核心技术团队为您提供技术支持
                        </p>
                      </div>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fooConWap">
        <footer-conTopW />
        <footer-conW />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerConWap.vue"; // 头部
import FooterConTopW from "@/components/footerConTopW"; // 底部免费试用
import FooterConW from "@/components/footerConWap"; // 底部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
import "@/assets/css/indexWap.scss";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
export default {
  name: "index",
  components: {
    HeaderCon,
    FooterConTopW,
    FooterConW,
    swiper,
    swiperSlide
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      value: "0",
      productValue: "0",
      swiperOption: {
        loop: true,
        effect: "fade",
        fadeEffect: {
          crossFade: true
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: false
      },
      swiperOptionOp: {
        loop: true,
        fadeEffect: {
          crossFade: true
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: false
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {
    if (this.$route.query.flag == "cooperation") {
      this.smoothDown();
    }
  },
  methods: {
    getClickDataFunc(data) {
      if (data == "cooperation") {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        var targetOffsetTop = this.$refs.cooperation.offsetTop;
        if (scrollTop > targetOffsetTop) {
          // 往上滑
          this.smoothUp();
        } else {
          // 往下滑
          this.smoothDown();
        }
      }
    },
    // 页面下滑
    smoothDown() {
      // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
      const STEP = 50;
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      var targetOffsetTop = this.$refs.cooperation.offsetTop - 25;
      if (scrollTop < targetOffsetTop) {
        // 如果和目标相差距离大于等于 STEP 就跳 STEP
        // 否则直接跳到目标点，目标是为了防止跳过了。
        if (targetOffsetTop - scrollTop >= STEP) {
          scrollTop += STEP;
        } else {
          scrollTop = targetOffsetTop;
        }
        document.body.scrollTop = scrollTop;
        document.documentElement.scrollTop = scrollTop;
        // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
        requestAnimationFrame(this.smoothDown.bind(this));
      }
    },
    // 定义往上滑函数
    smoothUp() {
      const STEP = 50;
      var scrollTop =
        (document.documentElement.scrollTop || document.body.scrollTop) - 25;
      var targetOffsetTop = this.$refs.cooperation.offsetTop - 25;
      if (scrollTop > targetOffsetTop) {
        if (scrollTop - targetOffsetTop >= STEP) {
          scrollTop -= STEP;
        } else {
          scrollTop = targetOffsetTop;
        }
        document.body.scrollTop = scrollTop;
        document.documentElement.scrollTop = scrollTop;
        requestAnimationFrame(this.smoothUp.bind(this));
      }
    },
    tab(v) {
      this.value = v;
    },
    productTab(v) {
      this.productValue = v;
    }
  }
};
</script>
