<template>
  <div class="main-single aboutWap-honor">
    <div>
      <div class="tit-box">
        <h2>企业荣誉</h2>
      </div>
      <div class="con-box">
        <div class="tab-cont">
          <swiper
            :options="swiperOption"
            ref="mySwiperHonor"
            style="height:100%"
          >
            <swiper-slide>
              <ul class="clearfloat">
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honorA_img1.jpg" />
                    <h3>湖南省数字经济促进会会员单位</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honorA_img2.jpg" />
                    <h3>高新技术企业证</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img1.jpg" />
                    <h3>悦商圈商户IOS软著证</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img2.jpg" />
                    <h3>悦商圈CRM软著证</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img3.jpg" />
                    <h3>悦商圈客户安卓软著证</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img4.jpg" />
                    <h3>悦商圈客户IOS软著证</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img5.jpg" />
                    <h3>悦商圈商户安卓软著证</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img6.jpg" />
                    <h3>悦云管IOS软著证</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img7.jpg" />
                    <h3>悦商惠小程序软著证</h3>
                  </div>
                </li>
              </ul>
            </swiper-slide>
            <swiper-slide>
              <ul class="clearfloat">
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img8.jpg" />
                    <h3>悦邻家安卓软著证</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img9.jpg" />
                    <h3>悦商管小程序软著证</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img10.jpg" />
                    <h3>悦商管员工小程序软著证</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img11.jpg" />
                    <h3>悦荷包小程序软著证</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img12.jpg" />
                    <h3>悦购小程序软著证</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img13.jpg" />
                    <h3>统一认证平台软著证</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img14.jpg" />
                    <h3>大数据分析平台软著证</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img15.jpg" />
                    <h3>数据中心平台软著证</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img16.jpg" />
                    <h3>用户画像营销系统软著证</h3>
                  </div>
                </li>
              </ul>
            </swiper-slide>
            <swiper-slide>
              <ul class="clearfloat">
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img17.jpg" />
                    <h3>阳光一百IOS软著证</h3>
                  </div>
                </li>
                <li class="about-li">
                  <div class="about-li-con">
                    <img src="../../assets/img/honor_img18.jpg" />
                    <h3>阳光一百安卓软著证</h3>
                  </div>
                </li>
              </ul>
            </swiper-slide>
            <div
              class="swiper-pagination aboutWap-page"
              slot="pagination"
            ></div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
export default {
  name: "AboutHonor",
  components: {
    swiper,
    swiperSlide
  },
  props: {},
  data() {
    return {
      swiperOption: {
        loop: true,
        fadeEffect: {
          crossFade: true
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: false
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiperHonor.swiper;
    }
  },
  mounted() {},
  methods: {}
};
</script>
