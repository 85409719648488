<template>
  <div class="con-wrapper">
    <div class="wap">
      <header-con />
      <div class="m-main">
        <div class="banner-single">
          <div class=" bannerAbout"></div>
          <div class="banner-con">
            <img src="../../../assets/img/about_logo.png" />
            <span></span>
          </div>
        </div>
        <div :class="topNavDistance > 200 ? 'fixed' : ''" class="scroll-nav">
          <div class="scroll-navCon">
            <ul class="main-wrap flex flex-middle">
              <li
                v-for="(item, index) in allMenuList"
                :key="item.id"
                :class="{ active: active == index }"
                @click="scrollTo(index)"
              >
                {{ item.value }}
                <span></span>
              </li>
            </ul>
          </div>
        </div>
        <div class="product-scroll">
          <div class="main-single wap-about scroll-con">
            <div>
              <div class="tit-box">
                <h2>公司介绍</h2>
              </div>
              <div class="con-box">
                <div class="tab-cont">
                  <div class="about-text">
                    <p>
                      湖南悦云数字科技有限公司（以下简称悦云科技）是一家致力于企业数字化管理的科技公司，公司围绕数字变革，从企业需求出发，用科技智慧为企业提供系统性的解决方案。
                    </p>
                    <br />
                    <p>
                      目前，公司设计拥有三条主产品线，分别为企业数字管理方案、智慧物业管理方案，以及智慧商业管理方案。
                    </p>
                    <br />
                    <p>
                      企业数字管理方案在为企业提供数据治理服务的基础上，以人员主数据平台、单点登录，以及流程中心等方案来为企业打造统一门户，高效解决企业在数字化转型的过程中系统多维护难的问题。
                    </p>
                    <br />
                    <p>
                      智慧物业管理方案是为集团性企业进行资产管理的解决方案。系统通过费效管理，业财一体等数字化手段帮助集团远程监控物业资产，真正实现人员在线、组织在线、业务在线，为集团降本增效。
                    </p>
                    <br />
                    <p>
                      智慧商业管理方案是行业内功能全，服务广的商业解决方案，涵盖crm系统、导视系统、客流系统、停车系统、wifi系统、收银系统等商业管理所需全套系统，并以集成的方式为客户提供高效及流畅的使用体验。
                    </p>
                    <br />
                    <p>
                      2018年成立至今，悦云科技已拥有16项软件著作权，被评为国家级高新技术企业。悦云科技正以迅猛的发展势头，努力成为一流的产业互联网解决方案提供商，用科技智慧帮助企业完成数字化转型，为中国成为数字化大国，实现弯道超车贡献自己的力量。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <about-history class="scroll-con" />
          <about-honor class="scroll-con" />
        </div>
      </div>
      <div class="fooConWap">
        <footer-conTopW />
        <footer-conW />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerConWap"; // 头部
import AboutHistory from "@/components/about/aboutHistoryWap"; // 公司荣誉
import AboutHonor from "@/components/about/aboutHonorWap"; // 公司荣誉
import FooterConTopW from "@/components/footerConTopW"; // 底部免费试用
import FooterConW from "@/components/footerConWap"; // 底部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
export default {
  name: "about",
  components: {
    HeaderCon,
    FooterConTopW,
    FooterConW,
    AboutHonor,
    AboutHistory
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      topNavDistance: 0,
      active: 0,
      allMenuList: [
        {
          value: "公司介绍",
          id: "1"
        },
        {
          value: "悦云大事记",
          id: "2"
        },
        {
          value: "企业荣誉",
          id: "3"
        }
      ],
      noScroll: false
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleNavScroll);
    window.addEventListener("scroll", this.onScroll, false);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.handleNavScroll);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    handleNavScroll() {
      // 二级导航滚动到指定位置固定
      this.topNavDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    // 二级导航+内容联动滚动监听
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(".scroll-con");
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });
      // 获取当前文档流的 scrollTop
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop += 52 * window.devicePixelRatio;
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (
          scrollTop >=
          (this.noScroll ? (offsetTopArr[n] -= 60) : offsetTopArr[n])
        ) {
          navIndex = n;
        }
      }
      this.active = navIndex;
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      var targetOffsetTop = document.querySelector(
        `.product-scroll .scroll-con:nth-child(${index + 1})`
      ).offsetTop;
      targetOffsetTop -= 52 * window.devicePixelRatio;
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
      const STEP = 50;
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        if (this.topNavDistance < 200) {
          targetOffsetTop -= 60;
          this.noScroll = true;
        } else {
          this.noScroll = false;
        }
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    }
  }
};
</script>
