<template>
  <div class="con-wrapper">
    <div class="pc">
      <header-con :index="3" class="header-barBg" />
      <div class="about-bg">
        <img src="../../../assets/img/aboutBg.png" class="about-img" />
      </div>
      <div class="about-detail">
        <div class="about-single">
          <div class="about-banner floor">
            <div class="main-wrap ban-box">
              <img
                src="../../../assets/img/about_logo.png"
                class="about-logo"
              />
            </div>
          </div>
        </div>
        <div class="product-nav abs-lt w100">
          <div
            :class="topNavDistance > 460 ? 'fixed' : ''"
            class="anchor-box w100"
          >
            <ul class="main-wrap clearfloat">
              <li
                v-for="(item, index) in allMenuList"
                :key="item.id"
                :class="{ active: active == index }"
                @click="scrollTo(index)"
              >
                <span class="abs-mt">{{ item.value }}</span>
                <p class="vh">{{ item.value }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="product-scroll">
          <div
            class="product-single product-business scroll-con"
            style="padding: 60px 0 100px"
          >
            <div>
              <div class="tit-box pr">
                <h2>公司介绍</h2>
              </div>
              <div class="con-box">
                <div class="tab-cont">
                  <span>
                    <div class="about-introduce clearfloat">
                      <div class="about-in-l">
                        <p>
                          湖南悦云数字科技有限公司（以下简称悦云科技）是一家致力于企业数字化管理的科技公司，公司围绕数字变革，从企业需求出发，用科技智慧为企业提供系统性的解决方案。
                        </p>
                        <p>
                          目前，公司设计拥有三条主产品线，分别为企业数字管理方案、智慧物业管理方案，以及智慧商业管理方案。
                        </p>
                        <p>
                          企业数字管理方案在为企业提供数据治理服务的基础上，以人员主数据平台、单点登录，以及流程中心等方案来为企业打造统一门户，高效解决企业在数字化转型的过程中系统多维护难的问题。
                        </p>
                        <p>
                          智慧物业管理方案是为集团性企业进行资产管理的解决方案。系统通过费效管理，业财一体等数字化手段帮助集团远程监控物业资产，真正实现人员在线、组织在线、业务在线，为集团降本增效。
                        </p>
                        <p>
                          智慧商业管理方案是行业内功能全，服务广的商业解决方案，涵盖crm系统、导视系统、客流系统、停车系统、wifi系统、收银系统等商业管理所需全套系统，并以集成的方式为客户提供高效及流畅的使用体验。
                        </p>
                        <p>
                          2018年成立至今，悦云科技已拥有17项软件著作权，被评为国家级高新技术企业，湖南省双软认证企业，是湖南省数字经济促进会会员单位。悦云科技正以迅猛的发展势头，努力成为一流的产业互联网解决方案提供商，用科技智慧帮助企业完成数字化转型，为中国成为数字化大国，实现弯道超车贡献自己的力量。
                        </p>
                      </div>
                      <div class="about-in-r">
                        <img src="../../../assets/img/about_inBg.png" />
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="about-history scroll-con">
            <div class="about-his-con">
              <div class="about-slide">
                <div
                  :style="{
                    transform: `translate3d(${swiperConWrap}, 0px, 0px)`
                  }"
                  class="swiper-top clearfloat"
                >
                  <ul
                    v-for="(item, index) in swiperCon"
                    :key="index"
                    :class="item.class"
                    class="his-list"
                    @click="index != swiperIndex ? swiperChange(index) : ''"
                  >
                    <li v-for="(liItem, index) in item.liList" :key="index">
                      <span></span>
                      <p>{{ liItem.text }}</p>
                    </li>
                  </ul>
                </div>
                <div
                  class="swiper-button-prev"
                  slot="button-prev"
                  @click="swiperIndex > 0 ? swiperChange(-1) : ''"
                ></div>
                <div
                  class="swiper-button-next"
                  slot="button-next"
                  @click="
                    swiperIndex < swiperYears.length - 1 ? swiperChange(-2) : ''
                  "
                ></div>
              </div>
              <div class="history-year fixed">
                <div class="leftbox">
                  <div id="swiperWrap" class="swiper-wrap">
                    <div
                      v-for="(item, index) in swiperYears"
                      :key="item.id"
                      :class="item.class"
                    >
                      <a
                        href="javascript:void(0);"
                        class=" number"
                        @click="swiperChange(index)"
                      >
                        <i></i>{{ item.id }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="about-vision scroll-con">
            <div class="tit-box pr">
              <h2>公司愿景</h2>
            </div>
            <div class="con-box">
              <div class="tab-cont">
                <span>
                  <div>
                    <img
                      src="../../../assets/img/aboutBg2.png"
                      class="vision_img"
                    />
                    <p class="vision_text">
                      做一流的产业互联网方案提供商，用科技智慧帮助企业完成数字化转型
                    </p>
                  </div>
                </span>
              </div>
            </div>
            <about-honor />
          </div>
          <div class="about-vision scroll-con">
            <div class="tit-box pr">
              <h2>领导风采</h2>
            </div>
            <div class="con-box">
              <div class="tab-cont">
                <span>
                  <div>
                    <img
                      src="../../../assets/img/hlmConcise.png"
                      class="vision_img"
                    />
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fooCon">
        <footer-conTop />
        <footer-con />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerCon"; // 头部
import FooterConTop from "@/components/footerConTop"; // 底部免费试用
import FooterCon from "@/components/footerCon"; // 底部
import AboutHonor from "@/components/about/aboutHonor"; // 公司荣誉
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
import "swiper/swiper-bundle.css";
export default {
  name: "about",
  components: {
    HeaderCon,
    FooterCon,
    FooterConTop,
    AboutHonor
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      topNavDistance: 0,
      active: 0,
      // 二级导航
      allMenuList: [
        {
          value: "公司介绍",
          id: "1"
        },
        {
          value: "悦云大事记",
          id: "2"
        },
        {
          value: "企业荣誉",
          id: "3"
        },
        {
          value: "领导风采",
          id: "4"
        }
      ],
      swiperIndex: 2, //默认年份 0第一个,1第二个,2第三个......以此类推
      swiperConIndex: 2, //默认事件内容 0第一个,1第二个,2第三个.....以此类推
      swiperCircleFlag: false,
      swiperWrap: "0",
      swiperConWrap: "-10px",
      // 年份
      // 添加形式
      // {
      //     id: "num", 加到哪年就填哪年,最后一个是最大年份
      //     class: "", 这里的年份默认最后一个显示,填swiper-slide-active,前1个prev_nth2Mode,前第2个prev_nth3Mode,后1个next_nth2Mode,后第2个next_nth3Mode
      //   }
      swiperYears: [
        {
          id: "2018",
          class: "prev_nth3Mode"
        },
        {
          id: "2019",
          class: "prev_nth2Mode"
        },
        {
          id: "2020",
          class: "swiper-slide-active"
        }
      ],
      // 大记事内容
      // 添加形式
      // {
      //     id: "num", 加到第几个就填几
      //     class: "", 这里的内容默认最后一个显示,填swiperCon-active,前1个prev_nth2Con,前第2个prev_nth3Con,后1个next_nth2Con,后第2个next_nth3Con
      //     liList: [
      //       {
      //         text: ""  填需要的内容,有几条就要加几个不能写在一起
      //       }
      //     ]
      //   }
      swiperCon: [
        {
          id: "1",
          class: "prev_nth3Con",
          liList: [
            {
              text: "7月 与株洲大汉悦中心签订悦商圈SAAS服务合同"
            },
            {
              text: "1月 注册成立湖南大汉商管云服务有限公司"
            }
          ]
        },
        {
          id: "2",
          class: "prev_nth2Con",
          liList: [
            {
              text: "11月 悦商圈2.0版正式上线"
            },
            {
              text: "9月 以O&O大运营服务承接邵阳大汉悦中心开业活动"
            }
          ]
        },
        {
          id: "3",
          class: "swiperCon-active",
          liList: [
            {
              text: "11月 荣获湖南省双软企业认证"
            },
            {
              text: "11月 荣获大汉大物业“数字化战略最佳合作伙伴"
            },
            {
              text: "9月 荣膺国家级高新技术企业称号"
            },
            {
              text: "8月 智起未来|悦云资产管理平台上线启动"
            },
            {
              text:
                "7月 正式更名为湖南悦云数字科技有限公司，悦云资产管理平台成功上线"
            },
            {
              text: "5月 与建设银行、阳光100达成三方共识，共建智慧社区生活平台"
            },
            {
              text:
                "4月 成功服务漫城星街，美莱美城市广场，湖南绿地，南宁富宁广场等20余购物中心"
            }
          ]
        }
      ]
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleNavScroll);
    window.addEventListener("scroll", this.onScroll, false);
    this.swiperChange(2);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.handleNavScroll);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    // 大记事年份和内容滑动效果
    swiperChange(i) {
      if (i == -1) {
        //prev swiper上一个滑块
        this.swiperIndex -= 1;
      } else if (i == -2) {
        // next swiper下一个滑块
        this.swiperIndex += 1;
      } else {
        this.swiperIndex = i;
      }
      this.swiperGetClass();
      this.swiperTopGetClass();
    },
    swiperTopGetClass() {
      for (var item of this.swiperCon) {
        item.class = "";
      }
      this.swiperConWrap = 0 - this.swiperIndex + 1 + "px";
      this.swiperCon[this.swiperIndex].class = "swiperCon-active";
      if (this.swiperIndex - 1 >= 0) {
        this.swiperCon[this.swiperIndex - 1].class = "prev_nth2Con";
      }
      if (this.swiperIndex - 2 >= 0) {
        this.swiperCon[this.swiperIndex - 2].class = "prev_nth3Con";
      }
      if (this.swiperIndex + 1 < this.swiperCon.length) {
        this.swiperCon[this.swiperIndex + 1].class = "next_nth2Con";
      }
      if (this.swiperIndex + 2 < this.swiperCon.length) {
        this.swiperCon[this.swiperIndex + 2].class = "next_nth3Con";
      }
    },
    swiperGetClass() {
      for (var item of this.swiperYears) {
        item.class = "";
      }
      this.swiperWrap = (0 - this.swiperIndex) * 110 + 210 + "px";
      document
        .getElementById("swiperWrap")
        .setAttribute(
          "style",
          "transform:translate(0px," + this.swiperWrap + ")"
        );
      this.swiperYears[this.swiperIndex].class = "swiper-slide-active";
      if (this.swiperIndex - 1 >= 0) {
        this.swiperYears[this.swiperIndex - 1].class = "prev_nth2Mode";
      }
      if (this.swiperIndex - 2 >= 0) {
        this.swiperYears[this.swiperIndex - 2].class = "prev_nth3Mode";
      }

      if (this.swiperIndex + 1 < this.swiperYears.length) {
        this.swiperYears[this.swiperIndex + 1].class = "next_nth2Mode";
      }
      if (this.swiperIndex + 2 < this.swiperYears.length) {
        this.swiperYears[this.swiperIndex + 2].class = "next_nth3Mode";
      }
    },
    // 二级导航滚动到指定位置固定
    handleNavScroll() {
      this.topNavDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    // 二级导航+内容联动滚动监听
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(
        ".product-scroll .scroll-con"
      );
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });
      // 获取当前文档流的 scrollTop
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop += 150;
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n;
        }
      }
      this.active = navIndex;
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      var targetOffsetTop = document.querySelector(
        `.product-scroll .scroll-con:nth-child(${index + 1})`
      ).offsetTop;
      targetOffsetTop -= 150;
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
      const STEP = 50;
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    }
  }
};
</script>
<style lang="scss" scope>
// 关于悦云
.pc .about-detail {
  min-width: 1200px;
  min-height: 1200px;
  overflow-x: hidden;
}
.about-single {
  position: relative;
  z-index: 2;
  &:first-of-type {
    z-index: 1;
    margin-bottom: 0;
  }
  &:nth-of-type(2) {
    .anchor-top {
      top: 0;
    }
  }
  .floor {
    position: relative;
    margin-bottom: 50px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.about-banner {
  height: 368px;
  box-sizing: border-box;
  .ban-box {
    .about-logo {
      width: 452px;
      text-align: left;
      display: block;
      margin-top: 185px;
    }
  }
}
.about-bg {
  width: 1399px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 90;
  img {
    width: 100%;
  }
}
.about-introduce {
  width: 1200px;
  margin: 0 auto;
  .about-in-l {
    float: left;
    width: 540px;
    padding: 40px 0 0;
    p {
      font-size: 16px;
      text-indent: 26px;
      line-height: 28px;
      text-align: left;
    }
  }
  .about-in-r {
    float: right;
    width: 580px;
    img {
      width: 100%;
    }
  }
}
.about-history {
  width: 100%;
  position: relative;
  background: url(../../../assets/img/aboutBg1.png) no-repeat top;
  background-size: 100% 100%;
  padding: 108px 0 0;
  .about-his-con {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    .about-slide {
      position: relative;
      height: 330px;
      .swiper-top {
        width: 100%;
        height: 100%;
        position: relative;
        .his-list {
          width: 680px;
          float: left;
          position: absolute;
        }
      }
      .swiper-button-prev {
        top: auto !important;
        bottom: -115px !important;
        left: 419px;
        color: #fff;
        z-index: 999;
        width: 23px;
        height: 44px;
        background: url(../../../assets/img/prevIcon.png) no-repeat;
        background-size: 100% 100%;
      }
      .swiper-button-next {
        top: auto !important;
        bottom: -115px !important;
        right: 435px;
        color: #fff;
        // z-index: 999;
        width: 23px;
        height: 44px;
        background: url(../../../assets/img/nextIcon.png) no-repeat;
        background-size: 100% 100%;
      }
      .swiper-button-next:focus,
      .swiper-button-prev:focus {
        outline: none;
      }
      .my-button-disabled {
        color: #eee;
      }
      .swiper-slide-active {
        width: 680px !important;
        margin: 0 auto;
      }
    }
    .his-list {
      width: 680px;
      height: 250px;
      background: #fff;
      box-shadow: 0px 4px 20px 0px #0d6bff;
      border-radius: 16px;
      padding: 40px 0 24px;
      overflow: hidden;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -ms-transition: all 0.3s;
      li {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        margin-bottom: 16px;
        text-align: left;
        padding: 0 30px 0 40px;
        position: relative;
        span {
          display: inline-block;
          width: 3px;
          height: 3px;
          line-height: 22px;
          background: #333;
          vertical-align: middle;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          margin-top: -2px;
        }
        p {
          display: inline-block;
          vertical-align: middle;
          width: 595px;
          margin-left: 10px;
        }
      }
    }
    .history-year {
      position: relative;
      background: url(../../../assets/img/his_bg.png) no-repeat 0 bottom;
      width: 700px;
      height: 360px;
      margin: 0 auto;
      padding-top: 35px;
      overflow: hidden;
    }
    .history-year {
      .leftbox {
        width: 300px;
        height: 688px;
        -webkit-transform: rotate(-90deg) translateX(146px);
        -moz-transform: rotate(-90deg) translateX(146px);
        -ms-transform: rotate(-90deg) translateX(146px);
        transform: rotate(-90deg) translateX(146px);
        margin: 0 auto;
        padding-right: 150px;
        .swiper-wrap {
          width: 100%;
          height: 100%;
          // transform: translate3d(0px, -48px, 0px);
          transition-duration: 0ms;
          .swiper-slide-active {
            width: 100%;
            height: 170px;
            position: relative;
          }
        }
        a {
          i {
            width: 23px;
            height: 23px;
            display: block;
            display: none;
            position: absolute;
            top: 98px;
            left: 50%;
            margin-left: -8px;
          }
          em {
            display: none;
            position: absolute;
            top: -15px;
            left: 0;
            color: #e3254c;
            width: 100%;
            text-align: center;
            line-height: 30px;
            font-family: "Arial";
            text-transform: uppercase;
            font-weight: bold;
          }
        }

        .swiper-slide-active {
          i {
            display: block;
          }
        }
      }
      a {
        color: #c1c1c1;
        display: block;
        margin: auto;
        width: 100%;
        height: 100%;
        line-height: 110px;
        text-align: center;
        position: relative;
        opacity: 0;
        transform-origin: 50% 50%;
      }
    }
  }
}
.history-year .leftbox .swiper-slide-active a {
  font-size: 72px;
  opacity: 1;
  color: #fff;
}
.history-year .leftbox .swiper-slide-active a {
  -webkit-transform: rotate(90deg) translateX(42px) translateY(-187px);
  -moz-transform: rotate(90deg) translateX(42px) translateY(-187px);
  -ms-transform: rotate(90deg) translateX(42px) translateY(-187px);
  transform: rotate(90deg) translateX(42px) translateY(-187px);
}
.history-year .leftbox .swiper-slide-active a {
  font-size: 72px;
  opacity: 1;
}
.history-year .leftbox a {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.about-history .about-his-con .about-slide .swiper-top .prev_nth3Con {
  width: 240px;
  height: 126px;
  padding: 0;
  left: -260px;
  margin-top: 70px;
  padding: 26px 0;
  li {
    white-space: nowrap;
    color: #999999;
    span {
      background: #999999;
    }
  }
}
.about-history .about-his-con .about-slide .swiper-top .prev_nth2Con {
  width: 240px;
  height: 126px;
  padding: 0;
  left: 0;
  margin-top: 70px;
  padding: 26px 0;
  li {
    white-space: nowrap;
    color: #999999;
    span {
      background: #999999;
    }
  }
}
.about-history .about-his-con .about-slide .swiper-top .swiperCon-active {
  width: 680px;
  height: 250px;
  padding: 40px 0;
  top: 0;
  left: 50%;
  margin-left: -340px;
}
.about-history .about-his-con .about-slide .swiper-top .next_nth2Con {
  width: 240px;
  height: 126px;
  padding: 0;
  right: 0;
  margin-top: 70px;
  padding: 26px 0;
  z-index: 990;
  z-index: 19\9;
  li {
    white-space: nowrap;
    color: #999999;
    span {
      background: #999999;
    }
  }
}
.about-history .about-his-con .about-slide .swiper-top .next_nth3Con {
  width: 240px;
  height: 126px;
  padding: 0;
  right: -260px;
  margin-top: 70px;
  padding: 26px 0;
  z-index: 98;
  li {
    white-space: nowrap;
    color: #999999;
    span {
      background: #999999;
    }
  }
}
.history-year .leftbox .prev_nth2Mode a {
  -webkit-transform: rotate(62deg) translateX(56px) translateY(-81px);
  -moz-transform: rotate(62deg) translateX(56px) translateY(-81px);
  -ms-transform: rotate(62deg) translateX(56px) translateY(-81px);
  transform: rotate(62deg) translateX(56px) translateY(-81px);
  opacity: 1;
}

.history-year .leftbox .next_nth2Mode a {
  -webkit-transform: rotate(131deg) translateX(4px) translateY(-129px);
  -moz-transform: rotate(131deg) translateX(4px) translateY(-129px);
  -ms-transform: rotate(131deg) translateX(4px) translateY(-129px);
  transform: rotate(131deg) translateX(4px) translateY(-129px);
  opacity: 1;
}

.history-year .leftbox .prev_nth3Mode a {
  -webkit-transform: rotate(33deg) translateX(-33px) translateY(18px);
  -moz-transform: rotate(33deg) translateX(-33px) translateY(18px);
  -ms-transform: rotate(33deg) translateX(-33px) translateY(18px);
  transform: rotate(33deg) translateX(-33px) translateY(18px);
  opacity: 1;
}

.history-year .leftbox .next_nth3Mode a {
  -webkit-transform: rotate(152deg) translateX(77px) translateY(-75px);
  -moz-transform: rotate(152deg) translateX(77px) translateY(-75px);
  -ms-transform: rotate(152deg) translateX(77px) translateY(-75px);
  transform: rotate(152deg) translateX(77px) translateY(-75px);
  opacity: 1;
}

.history-year .leftbox .prev_nth4Mode a {
  -webkit-transform: rotate(-80deg) translateX(-126px) translateY(-143px);
  -moz-transform: rotate(-80deg) translateX(-126px) translateY(-143px);
  -ms-transform: rotate(-80deg) translateX(-126px) translateY(-143px);
  transform: rotate(-80deg) translateX(-126px) translateY(-143px);
}

.history-year .leftbox .next_nth4Mode a {
  -webkit-transform: rotate(80deg) translateX(-214px) translateY(162px);
  -moz-transform: rotate(80deg) translateX(-214px) translateY(162px);
  -ms-transform: rotate(80deg) translateX(-214px) translateY(162px);
  transform: rotate(80deg) translateX(-214px) translateY(162px);
}

.history-year .leftbox .prev_nth5Mode a {
  -webkit-transform: rotate(-25deg) translateX(-65px);
  -moz-transform: rotate(-25deg) translateX(-65px);
  -ms-transform: rotate(-25deg) translateX(-65px);
  transform: rotate(-25deg) translateX(-65px);
  opacity: 1;
}

.history-year .leftbox .next_nth5Mode a {
  -webkit-transform: rotate(25deg) translateX(-65px) translateY(20px);
  -moz-transform: rotate(25deg) translateX(-65px) translateY(20px);
  -ms-transform: rotate(25deg) translateX(-65px) translateY(20px);
  transform: rotate(25deg) translateX(-65px) translateY(20px);
  opacity: 1;
}

.history-year .leftbox .prev_nth6Mode a {
  -webkit-transform: rotate(-30deg) translateX(-99px);
  -moz-transform: rotate(-30deg) translateX(-99px);
  -ms-transform: rotate(-30deg) translateX(-99px);
  transform: rotate(-30deg) translateX(-99px);
  opacity: 1;
}

.history-year .leftbox .next_nth6Mode a {
  -webkit-transform: rotate(30deg) translateX(-99px) translateY(20px);
  -moz-transform: rotate(30deg) translateX(-99px) translateY(20px);
  -ms-transform: rotate(30deg) translateX(-99px) translateY(20px);
  transform: rotate(30deg) translateX(-99px) translateY(20px);
  opacity: 1;
}
.about-vision {
  padding: 88px 0 20px;
  .tit-box {
    text-align: center;
    h2 {
      font-size: 36px;
      line-height: 50px;
      font-weight: 500;
      color: #00041b;
    }
  }
  .con-box {
    width: 1200px;
    margin: 0 auto;
    .tab-cont {
      img.vision_img {
        width: 1200px;
        margin: 30px 0 34px;
      }
      p.vision_text {
        width: 920px;
        margin: 0 auto;
        font-size: 24px;
        color: #4b4f5e;
        line-height: 36px;
      }
    }
  }
}

.about-honor {
  padding: 80px 0 70px;
  .tit-box {
    text-align: center;
    h2 {
      font-size: 36px;
      line-height: 50px;
      font-weight: 500;
      color: #00041b;
      text-align: center;
    }
  }
  .con-box {
    .tab-cont {
      height: 660px;
      .swiper-container {
        height: 100%;
      }
      ul {
        // border-top: 1px solid #F8F8F8;
        // border-left: 1px solid #F8F8F8;
        li {
          float: left;
          padding: 25px 0;
          width: 298px;
          height: 250px;
          background: #fff;
          border-bottom: 1px solid #f8f8f8;
          border-right: 1px solid #f8f8f8;
          &:nth-child(4n) {
            border-right: none;
          }
          .about-li-con {
            img {
              width: 145px;
              height: 198px;
              margin: 0 auto;
              display: block;
            }
            h3 {
              width: 250px;
              display: block;
              font-size: 18px;
              color: #4b4f5e;
              margin: 20px auto 0;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
.about-page {
  width: 100%;
  bottom: -10px !important;
  left: 0 !important;
  height: 45px;
  .swiper-pagination-bullet {
    width: 22px;
    height: 4px;
    display: inline-block;
    background: #b8cdff !important;
    margin-right: 8px;
    background-clip: content-box !important;
    transition: all 1s ease-out;
    border-radius: 3px !important;
    opacity: 1 !important;
  }
  .swiper-pagination-bullet-active {
    background-color: #2c68ff !important;
  }
}
// .about-history .about-his-con .swiper-slide-next {
//   width: 240px !important;
//   height: 180px;
//   .his-list {
//     width: 100%;
//     height: 100%;
//     overflow: hidden;
//   }
// }
// .about-history .about-his-con .swiper-slide-prev {
//   width: 240px !important;
//   height: 180px;
//   .his-list {
//     width: 100%;
//     height: 100%;
//     overflow: hidden;
//   }
// }
</style>
