<template>
  <div class="con-wrapper">
    <div class="wap">
      <header-con />
      <div class="m-main">
        <div class="banner-single">
          <div class="banner"></div>
          <div class="banner-con">
            <h1>企业数字化</h1>
            <span></span>
            <p class="tit-desc">
              数据与价值双驱动数字产业模式升级，造就覆盖全价值链的智慧新业态
            </p>
            <div class="banner-btn">
              <router-link :to="{ path: '/wap/contact' }" class="btn-ask"
                >免费试用</router-link
              >
            </div>
          </div>
        </div>
        <div :class="topNavDistance > 390 ? 'fixed' : ''" class="scroll-nav">
          <div class="scroll-navCon">
            <ul class="main-wrap flex flex-middle">
              <li
                v-for="(item, index) in allMenuList"
                :key="item.id"
                :class="{ active: active == index }"
                @click="scrollTo(index)"
              >
                {{ item.value }}
                <span></span>
              </li>
            </ul>
          </div>
        </div>
        <div class="product-scroll">
          <div class="main-single scroll-con">
            <div>
              <div class="con-box">
                <div class="tab-cont">
                  <div class="tab-tit">
                    <h2>打造数字赋能、数据驱动的智慧企业</h2>
                    <p>
                      面向企业各种业务场景，提供一站式数字化解决方案，让一切业务数据化，一切数据业务化，实现数字驱动型增长
                    </p>
                  </div>
                  <div class="tab-main">
                    <img
                      src="../../../assets/img/wap/section_solve_pic2.png"
                      class="img-solutionA"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="main-single scroll-con" style="padding-bottom:1.2rem">
            <div>
              <div class="tit-box">
                <h2>方案优势</h2>
              </div>
              <div class="con-box">
                <div class="tab-cont">
                  <ul class="ulList">
                    <li>
                      <div class="normal-box flex">
                        <img
                          src="../../../assets/img/product_advantage_icon1.png"
                        />
                        <div class="normal-boxR">
                          <h4 title="集团级战略咨询">
                            集团级战略咨询
                          </h4>
                          <p>
                            深度切入产业数字化转型，为各类型产业转型提供战略方向分析和结果推演
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="normal-box flex">
                        <img
                          src="../../../assets/img/product_advantage_icon2.png"
                        />
                        <div class="normal-boxR">
                          <h4 title="独特转型创新方法">
                            独特转型创新方法
                          </h4>
                          <p>
                            支持独具特色的最小产品包，用创新的技术构建新的企业级解决方案
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="normal-box flex">
                        <img
                          src="../../../assets/img/product_advantage_icon3.png"
                        />
                        <div class="normal-boxR">
                          <h4 title="灵活可靠的设计思维">
                            灵活可靠的设计思维
                          </h4>
                          <p>
                            依据深度实践的设计方法论开展合作、协调团队和解决用户问题，同时还能够按照现代企业的要求快速大规模地改善客户体验
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fooConWap">
        <footer-conTopW />
        <footer-conW />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCon from "@/components/headerConWap"; // 头部
import FooterConTopW from "@/components/footerConTopW"; // 底部免费试用
import FooterConW from "@/components/footerConWap"; // 底部
import { getMetaInfo } from "@/utils/utils"; // 添加SEO关键字
export default {
  name: "solutions",
  components: {
    HeaderCon,
    FooterConW,
    FooterConTopW
  },
  metaInfo: getMetaInfo(),
  data() {
    return {
      topNavDistance: 0,
      active: 0,
      allMenuList: [
        {
          value: "方案构架",
          id: "1"
        },
        {
          value: "方案优势",
          id: "2"
        }
      ],
      noScroll: false
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleNavScroll);
    window.addEventListener("scroll", this.onScroll, false);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.handleNavScroll);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    handleNavScroll() {
      // 二级导航滚动到指定位置固定
      this.topNavDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    // 二级导航+内容联动滚动监听
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(".scroll-con");
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });
      // 获取当前文档流的 scrollTop
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop += 52 * window.devicePixelRatio;
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (
          scrollTop >=
          (this.noScroll ? (offsetTopArr[n] -= 60) : offsetTopArr[n])
        ) {
          navIndex = n;
        }
      }
      this.active = navIndex;
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      var targetOffsetTop = document.querySelector(
        `.product-scroll .scroll-con:nth-child(${index + 1})`
      ).offsetTop;
      targetOffsetTop -= 52 * window.devicePixelRatio;
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
      const STEP = 50;
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        if (this.topNavDistance < 390) {
          targetOffsetTop -= 60;
          this.noScroll = true;
        } else {
          this.noScroll = false;
        }
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    }
  }
};
</script>
