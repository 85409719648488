import axios from "axios";

// create an axios instance
console.log(process.env);
const service = axios.create({
  baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 2 * 5000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // var token = getToken();
    // Do something before request is sent
    // if (token) {
    //   // 让每个请求携带token-- ['token']为自定义key 请根据实际情况自行修改
    //   config.headers['token'] = token
    // }
    // config.headers['X-App-ID'] = 1
    // config.headers['X-App-Version'] = '2.0'
    // if (store.getters.tradingAreaShow && store.getters.tradingArea.id) { // 商圈栏显示并且选中具体的某个商圈时，统一走请求头传输
    //   config.headers['X-DATA-ID'] = store.getters.tradingArea.id
    // }
    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  // response => response,
  /**
   * 下面的注释为通过在response里，自定义code来标示请求状态
   * 当code返回如下情况则说明权限有问题，登出并返回到登录页
   * 如想通过 xmlhttprequest 来状态码标识 逻辑可写在下面error中
   * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
   */
  response => {
    const res = response.data;
    if (res.code !== "0") {
      if (Number(res.code) > 10000) {
        alert(res.msg || res.message);
        return response;
      } else {
        alert("网络繁忙");
      }
      // return response
      var obj = {};
      obj.msg = res.message || res.msg;
      obj.code = res.code;
      // store.dispatch("setErrMsg", obj);
    } else {
      return response;
    }
  },
  error => {
    // Notification({
    //   title: "网络繁忙",
    //   customClass: "erp-notify",
    //   type: "error"
    // });
    var obj = {};
    obj.msg = "网络繁忙";
    obj.code = -1;
    // store.dispatch("setErrMsg", obj);
    return Promise.reject(error);
  }
);

export default service;
